import { memo, useCallback, useMemo } from 'react';

import { Box, Divider, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';

import { CalendarIcon, PencilIcon, TrashIcon, colors } from 'assets';
import { Paper, UserIdentifier } from 'components';
import { useLocationTimezone } from 'hooks/useLocationTimezone/useLocationTimezone';
import { USADateTimeFormatAmPm } from 'utils';

import { useStyles } from './styles';
import { CommentCardProps } from './types';

export const CommentCard = memo(({ comment, timeZone, onEdit, onDelete }: CommentCardProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { utcToZone } = useLocationTimezone(timeZone);

  const commentDate = useMemo(
    () => (
      <>
        <CalendarIcon color={colors.grey80} className={styles.calendarIcon} />

        <Box color={colors.grey120} ml={1.5}>
          <Typography variant="body1" color="inherit">
            {comment.updated.datetime !== '' ? utcToZone(comment.updated.datetime, USADateTimeFormatAmPm) : 'Recently'}
          </Typography>
        </Box>
      </>
    ),
    [comment.updated.datetime, styles.calendarIcon, utcToZone],
  );

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      onDelete();
    },
    [onDelete],
  );

  const handleEdit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      onEdit();
    },
    [onEdit],
  );

  return (
    <Paper roundingValue={4} elevation={16}>
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="nowrap" gridGap={16}>
          <Box flex={{ xs: 'initial', sm: 1.5 }}>
            <UserIdentifier name={comment.created.name} imageUrl={comment.created.imageUrl} size="large" />
          </Box>

          {!isMobile && (
            <Box flex={{ xs: 'initial', sm: 1.5 }} display="flex" alignItems="center" justifyContent="center">
              {commentDate}
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex={{ xs: 'initial', sm: 1 }}
            gridGap={16}
          >
            {comment.canBeEdited && (
              <Box m={-1.5}>
                <IconButton onClick={handleEdit}>
                  <PencilIcon fontSize={18} />
                </IconButton>
              </Box>
            )}

            {comment.canBeDeleted && (
              <Box m={-1.5}>
                <IconButton onClick={handleDelete}>
                  <TrashIcon fontSize={20} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>

        {isMobile && (
          <Box mt={2} display="flex" alignItems="center">
            {commentDate}
          </Box>
        )}

        <Box my={2}>
          <Divider />
        </Box>

        <Box color={colors.grey190}>
          <Typography variant="body1" color="inherit" className={styles.comment}>
            <span
              dangerouslySetInnerHTML={{
                __html: comment.comment,
              }}
            />
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
});
