import { makeStyles, Theme, createStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listTypography: {
      lineHeight: '25px',
      fontSize: '18px',
    },
    title: {
      color: colors.grey120,
      fontWeight: 'bold',
    },
    price: {
      color: '#000000',
    },
  }),
);
