export const disablePageScroll = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.setProperty('position', 'fixed', 'important');
};

export const enablePageScrol = () => {
  const scrollY = document.body.style.top;

  document.body.style.position = '';
  document.body.style.top = '';

  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};
