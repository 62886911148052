// TODO

import { memo, useMemo } from 'react';

import { Box, Divider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';

import { EstimateStatuses, JobStatuses, appointmentCategories } from 'entities/appointments';
import { TaskRelationAppointment } from 'entities/tasks';
import { USADateFormat } from 'utils';

export const AppointmentCard = memo(
  ({ appointment, separator = true }: { appointment: TaskRelationAppointment; separator?: boolean }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
      noSsr: true,
    });

    const isDisplayTargetDate = useMemo(
      () =>
        appointment.type === 'EST' &&
        appointment?.targetDate &&
        moment(appointment?.targetDate).isAfter(moment({ year: 2000, month: 0, day: 1 })),
      [appointment.type, appointment?.targetDate],
    );

    return (
      <>
        {separator && (
          <Box my={2}>
            <Divider />
          </Box>
        )}

        <div
          style={{
            display: 'grid',
            gap: isMobile ? 12 : 16,
            gridTemplateColumns: isMobile
              ? 'repeat(2, 1fr)'
              : isDisplayTargetDate
              ? '1.63fr 2.55fr 1fr 3.5fr 2.4fr 2.14fr'
              : '2fr 4fr 3fr 5fr 2.65fr',
          }}
        >
          <div>
            <Typography component="div" variant="subtitle1" color="secondary">
              ID
            </Typography>
            <Typography component="div" color="primary">
              {/* <Link to={routePaths.jobsDetails(appointment.id)} style={{ color: 'inherit' }}>
                {appointment.id}
              </Link> */}
            </Typography>
          </div>

          <div>
            <Typography component="div" variant="subtitle1" color="secondary">
              Category
            </Typography>

            <Typography component="div">{appointmentCategories[appointment.category.id].name}</Typography>
          </div>

          <div>
            <Typography component="div" variant="subtitle1" color="secondary">
              Type
            </Typography>

            <Typography component="div">{appointment.type}</Typography>
          </div>

          <div>
            <Typography component="div" variant="subtitle1" color="secondary">
              Status
            </Typography>

            <Typography component="div">
              {appointment.type === 'EST'
                ? EstimateStatuses[appointment.estStatus.id]
                : JobStatuses[appointment.status.id]}
            </Typography>
          </div>

          <div>
            <Typography component="div" variant="subtitle1" color="secondary">
              {appointment.type === 'EST' ? 'Estimate' : 'Service'} date
            </Typography>

            <Typography component="div">{moment(appointment.startDate).format(USADateFormat)}</Typography>
          </div>

          {isDisplayTargetDate && (
            <div>
              <Typography component="div" variant="subtitle1" color="secondary">
                Target date
              </Typography>

              <Typography component="div">{moment(appointment.targetDate).format(USADateFormat)}</Typography>
            </div>
          )}
        </div>
      </>
    );
  },
);
