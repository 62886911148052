import { makeStyles, Theme } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles((theme: Theme) => ({
  paginationBox: {
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  paginationItem: {
    color: colors.secondary.main,
    fontWeight: 700,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },

    '&.Mui-selected': {
      backgroundColor: colors.primary.main,
    },

    '& .MuiPaginationItem-icon': {
      color: colors.secondary.main,
    },
  },
  skeletonRoot: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      marginRight: 16,
    },
  },
}));
