import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  calendarIcon: {
    fontSize: 18,
    display: 'block',
  },
  comment: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));
