import { forwardRef } from 'react';

import { AlertProps } from '@material-ui/lab/Alert';

import { AlertStyled } from './Alert.styles';

export const Alert = forwardRef((props: AlertProps, ref) => {
  return (
    <AlertStyled
      onClose={() => {}}
      ref={ref}
      elevation={6}
      variant="filled"
      icon={false}
      {...props}
      data-testid="alert"
    >
      {props.children}
    </AlertStyled>
  );
});
