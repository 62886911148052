import React, { forwardRef, useCallback, useState, KeyboardEvent } from 'react';

import { Button } from '../button/Button';
import { Input } from '../input/Input';

import { useStyles } from './LockableInput.styles';
import { LockableInputProps } from './LockableInput.types';

export const LockableInput = forwardRef<HTMLInputElement, LockableInputProps>(
  (
    {
      mask,
      variant,
      label,
      onBlur,
      initialIsEdit,
      name,
      placeholder,
      onEdit,
      onSave,
      size,
      onChange,
      disableButton,
      hideButton,
      value,
      inputRef,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();
    const [isEdit, setIsEdit] = useState(initialIsEdit);

    const toggleEditMode = useCallback(() => {
      setIsEdit(prev => {
        if (prev) {
          onSave && onSave(value);
        } else {
          onEdit && onEdit();
        }

        return !prev;
      });
    }, [onEdit, onSave, value]);

    const handleKeyPress = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          onSave && onSave(value);
          setIsEdit(false);
        }
      },
      [value, onSave, setIsEdit],
    );

    return (
      <div className={classes.inputWrapper} data-testid={props['data-testid']}>
        <Input
          name={name}
          inputRef={inputRef}
          className={classes.input}
          onKeyPress={handleKeyPress}
          fullWidth
          label={label}
          value={value}
          onChange={e => onChange(e.target.value)}
          mask={mask}
          ref={ref}
          disabled={!isEdit}
          variant="outlined"
          onBlur={onBlur}
          size={size}
        />
        {!hideButton && (
          <Button
            disabled={disableButton}
            buttonType="text"
            color="secondary"
            onClick={toggleEditMode}
            className={classes.button}
          >
            {isEdit ? 'Save' : 'Edit'}
          </Button>
        )}
      </div>
    );
  },
);
