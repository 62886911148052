import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Calendar } from '@material-ui/pickers';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import { colors } from 'assets/theme/palette';
import { NoahFont, RiftFont } from 'assets/theme/typography';

export const CalendarPickerStyled = withStyles(() => createStyles({}))(Calendar);

export const CalendarPickerOverrides: MuiPickersOverrides = {
  MuiPickersDay: {
    day: {
      fontSize: '14px',
      color: colors.grey180,
      height: '36px',
      width: '36px',

      '& p': {
        fontWeight: 'bold',
      },
    },
    daySelected: {
      backgroundColor: colors.secondary.main,

      '&:hover': {
        backgroundColor: colors.secondary.main,
      },
    },
    current: {
      color: colors.secondary.main,
    },
    hidden: {
      color: colors.grey60,
      opacity: 1,
    },
  },
  MuiPickersCalendarHeader: {
    transitionContainer: {
      '& p': {
        fontFamily: RiftFont,
        fontSize: '16px',
        letterSpacing: '0.12px',
        fontWeight: 'bold',
        color: colors.secondary.main,
      },
    },
    dayLabel: {
      fontFamily: NoahFont,
      fontSize: '10px !important',
      fontWeight: 'bold !important' as 'bold',
      letterSpacing: '1.5px',
      color: `${colors.secondary.main} !important`,
      textTransform: 'uppercase',
      width: '36px',
      margin: '0 2px !important',
    },
    iconButton: {
      '& path:first-child': {
        stroke: colors.primary.main,
        strokeWidth: 2,
      },
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      minHeight: 216,
    },
  },
  MuiPickersDatePickerRoot: {},
};

type Props = {
  hideArrows?: boolean;
  hideSelectedDay?: boolean;
  isMonthNameClickable?: boolean;
  isWeekDayClickable?: boolean;
};

export const useStyles = makeStyles<Theme, Props>({
  calendarPicker: {
    width: 'fit-content',
    margin: 'auto',
    overflow: 'hidden',
    '& .MuiPickersCalendarHeader-switchHeader': {
      marginBottom: ({ hideArrows }: Props) => (hideArrows ? 22 : 8),
      justifyContent: ({ hideArrows }: Props) => (hideArrows ? 'center' : 'space-between'),
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      display: ({ hideArrows }: Props) => (hideArrows ? 'none' : 'initial'),
    },
    '& .MuiPickersCalendarHeader-transitionContainer': {
      width: ({ hideArrows }: Props) => (hideArrows ? 'fit-content' : '100%'),
      cursor: ({ isMonthNameClickable }: Props) => (isMonthNameClickable ? 'pointer' : 'initial'),
      userSelect: ({ isMonthNameClickable }: Props) => (isMonthNameClickable ? 'none' : 'initial'),
      '-ms-user-select': ({ isMonthNameClickable }: Props) => (isMonthNameClickable ? 'none' : 'initial'),
      '-moz-user-select': ({ isMonthNameClickable }: Props) => (isMonthNameClickable ? 'none' : 'initial'),
      '-webkit-user-select': ({ isMonthNameClickable }: Props) => (isMonthNameClickable ? 'none' : 'initial'),

      '& > p': {
        position: ({ hideArrows }: Props) => (hideArrows ? 'initial!important' : 'absolute'),

        '&:hover': {
          color: ({ isMonthNameClickable }: Props) => (isMonthNameClickable ? colors.grey140 : colors.secondary.main),
        },
      },
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      cursor: ({ isWeekDayClickable }: Props) => (isWeekDayClickable ? 'pointer' : 'initial'),
      userSelect: ({ isWeekDayClickable }: Props) => (isWeekDayClickable ? 'none' : 'initial'),
      '-ms-user-select': ({ isWeekDayClickable }: Props) => (isWeekDayClickable ? 'none' : 'initial'),
      '-moz-user-select': ({ isWeekDayClickable }: Props) => (isWeekDayClickable ? 'none' : 'initial'),
      '-webkit-user-select': ({ isWeekDayClickable }: Props) => (isWeekDayClickable ? 'none' : 'initial'),

      '&:hover': {
        color: ({ isWeekDayClickable }: Props) =>
          isWeekDayClickable ? `${colors.grey140}!important` : colors.secondary.main,
      },
    },
    '& .MuiPickersDay-daySelected': {
      color: ({ hideSelectedDay }: Props) => (hideSelectedDay ? 'initial' : colors.white),
      backgroundColor: ({ hideSelectedDay }: Props) => (hideSelectedDay ? 'initial' : colors.secondary.main),

      '&:hover': {
        backgroundColor: ({ hideSelectedDay }: Props) =>
          hideSelectedDay ? 'rgba(0, 0, 0, 0.04)' : colors.secondary.main,
      },
    },
    '&.weekSelection': {
      '& .MuiPickersCalendar-week': {
        borderRadius: '16px',
        '& .MuiPickersDay-day:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiPickersCalendar-week:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& .MuiPickersCalendar-weekSelected': {
        background: colors.secondary.main,
        '&:hover': {
          backgroundColor: colors.secondary.main,
        },
        '& .MuiPickersDay-day': {
          color: colors.basic.white,
        },
        '& .MuiPickersDay-hidden': {
          color: colors.grey60,
        },
      },
    },
    '& *': {
      transition: 'none',
    },
  },
});
