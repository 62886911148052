import { makeStyles } from '@material-ui/core';

export const useFormCheckboxGroupStyles = makeStyles(() => ({
  formGroup: {
    margin: '0px !important',
  },
  formControlLabel: {
    margin: '0px !important',
  },
}));

export const useFormCheckboxStyles = makeStyles(() => ({
  formControl: {
    verticalAlign: 'middle',
  },
}));
