import { useCallback, useMemo, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import { TaskComment, TaskRelationTypesEnum } from 'entities/tasks';
import { useLocationTimezone } from 'hooks/useLocationTimezone/useLocationTimezone';
import { USADateTimeFormat } from 'utils';

import { TaskViewCommentsProps } from './model';
import { CommentAddButton, CommentCard, CommentEditor, ViewSection } from './ui';

export const TaskViewComments = ({
  isClaim,
  isDone,
  isVisible,
  user,
  users,
  timeZone,
  setIsVisible,
  setValue,
  watch,
}: TaskViewCommentsProps) => {
  const { utcToZone } = useLocationTimezone(timeZone);

  const comments = watch('comments');
  const relations = watch('relations');

  const showAccountCheckxox = useMemo(
    () => relations.some(relation => relation.type.value === TaskRelationTypesEnum.Account),
    [relations],
  );
  const showAppointmentCheckxox = useMemo(
    () => relations.some(relation => relation.type.value === TaskRelationTypesEnum.Appointment),
    [relations],
  );

  const [mentionedUsersIdForNewComment, setMentionedUsersIdForNewComment] = useState<number[]>([]);

  const sortedComments = useMemo(() => comments.filter(({ isDeleted }) => !isDeleted).reverse(), [comments]);

  const usersForMention = useMemo(
    () =>
      users.map(item => ({
        id: item.id,
        fullName: [item.firstName, item.lastName].join(' '),
        imageUrl: item.imageApproved?.url,
      })),
    [users],
  );

  const onMention = useCallback(
    (mentionedId: number, commentId?: number) => {
      if (commentId) {
        setValue(
          'comments',
          comments.map(comment => {
            const isAlreadyMentioned = comment.mentionesId?.includes(mentionedId);

            return comment.id === commentId
              ? {
                  ...comment,
                  mentionesId: isAlreadyMentioned ? comment.mentionesId : [...(comment.mentionesId ?? []), mentionedId],
                }
              : comment;
          }),
          {
            shouldDirty: true,
          },
        );

        return;
      }

      const isAlreadyMentioned = mentionedUsersIdForNewComment.includes(mentionedId);
      if (!isAlreadyMentioned) {
        setMentionedUsersIdForNewComment(prev => [...prev, mentionedId]);
      }
    },
    [comments, mentionedUsersIdForNewComment],
  );

  const onRemoveMention = useCallback(
    (mentionId: number, commentId?: number) => {
      if (commentId) {
        setValue(
          'comments',
          comments.map(comment =>
            comment.id === commentId
              ? {
                  ...comment,
                  mentionesId: comment.mentionesId?.filter(item => item !== mentionId),
                }
              : comment,
          ),
          {
            shouldDirty: true,
          },
        );

        return;
      }

      setMentionedUsersIdForNewComment(prev => prev.filter(item => item !== mentionId));
    },
    [comments],
  );

  const addNewComment = useCallback(
    ({
      text,
      showInAccountPage,
      showInJobPage,
    }: {
      text: string;
      showInAccountPage?: boolean;
      showInJobPage?: boolean;
    }) => {
      if (user) {
        const comment: TaskComment & { mentionesId: number[] } = {
          canBeDeleted: true,
          canBeEdited: true,
          comment: text,
          showInAccountPage,
          showInJobPage,
          created: {
            datetime: '',
            name: `${user.firstName} ${user.lastName}`,
            id: user.id,
            imageUrl: user.imageApproved.url,
          },
          updated: {
            datetime: '',
            id: user.id,
          },
          mentionesId: mentionedUsersIdForNewComment,
          id: new Date().getTime(),
          isDeleted: false,
          type: {
            id: 1,
            name: 'Internal Note',
          },
        };

        setValue('comments', [...comments, comment], {
          shouldDirty: true,
        });
        setMentionedUsersIdForNewComment([]);

        if (isClaim && !isDone) {
          const due = utcToZone(moment().utc().add(72, 'hours').format(USADateTimeFormat));

          setValue('dueDate', due);
          setValue('dueTime', due);
        }
      }
    },
    [comments, mentionedUsersIdForNewComment, isClaim, isDone, setMentionedUsersIdForNewComment, user],
  );

  const editComment = useCallback(
    (
      id: number,
      {
        text,
        showInAccountPage,
        showInJobPage,
      }: { text: string; showInAccountPage?: boolean; showInJobPage?: boolean },
    ) => {
      setValue(
        'comments',
        comments.map(comment =>
          comment.id === id ? { ...comment, comment: text, showInAccountPage, showInJobPage } : comment,
        ),
        {
          shouldDirty: true,
        },
      );
    },
    [comments],
  );

  const deleteComment = useCallback(
    (id: number) => {
      setValue(
        'comments',
        comments.map(comment => (comment.id === id ? { ...comment, mentionesId: [], isDeleted: true } : comment)),
        {
          shouldDirty: true,
        },
      );
    },
    [comments],
  );

  return (
    <ViewSection
      title="Comments"
      button={{
        title: isVisible ? 'Close comment' : 'Add comment',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onMouseDown: (e: any) => {
          e.preventDefault();
          e.stopPropagation();

          if (setIsVisible) {
            setIsVisible(!isVisible);
          }
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CommentEditor
            Component={({ onClick }) => (
              <CommentAddButton
                userImage={user.imageApproved.url}
                userName={`${user.firstName} ${user.lastName}`}
                onClick={onClick}
              />
            )}
            mentionProps={{
              users: usersForMention,
              add: onMention,
              remove: onRemoveMention,
            }}
            onSubmit={addNewComment}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            showAccountCheckxox={showAccountCheckxox}
            showAppointmentCheckxox={showAppointmentCheckxox}
          />
        </Grid>

        {sortedComments.length ? (
          sortedComments.map(comment => (
            <Grid key={comment.id} item xs={12}>
              <CommentEditor
                comment={comment.comment}
                showInAccountPage={comment.showInAccountPage}
                showInJobPage={comment.showInJobPage}
                showAccountCheckxox={showAccountCheckxox}
                showAppointmentCheckxox={showAppointmentCheckxox}
                Component={({ onClick }) => (
                  <CommentCard
                    comment={comment}
                    timeZone={timeZone}
                    onEdit={onClick}
                    onDelete={() => deleteComment(comment.id)}
                  />
                )}
                mentionProps={{
                  users: usersForMention,
                  mentionesId: comment.mentionesId,
                  add: mentionId => onMention(mentionId, comment.id),
                  remove: mentionId => onRemoveMention(mentionId, comment.id),
                }}
                onSubmit={data => editComment(comment.id, data)}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>No comment found</Typography>
          </Grid>
        )}
      </Grid>
    </ViewSection>
  );
};
