import { FC, forwardRef, memo } from 'react';

import { MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { Select } from 'components/select/Select';

import { useStyles } from './ColorfulSelect.styles';
import { ColorfulSelectProps } from './ColorfulSelect.types';

export const ColorfulSelect: FC<ColorfulSelectProps> = memo(
  forwardRef(
    (
      {
        value,
        options,
        onChange,
        onClick,
        palette,
        customPallete,
        disabled,
        fullWidth,
        fitContent,
        label,
        classes,
        className,
        size = 'small',
      },
      ref,
    ) => {
      const styles = useStyles({ fullWidth, fitContent, customPallete });

      return (
        <Select
          variant="pill"
          size={size}
          ref={ref}
          value={value}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
          fullWidth={fullWidth}
          label={label}
          className={clsx(
            styles.select,
            !customPallete ? styles[palette || 'empty'] : styles.customPallete,
            styles[size],
            className,
          )}
          classes={classes}
        >
          {options?.map(option => (
            <MenuItem key={option.id} value={option.id} disabled={option.disabled}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      );
    },
  ),
);
