import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const CheckboxIcon = ({
  className,
  checked,
  size = 'medium',
}: {
  className?: string;
  checked?: boolean;
  size?: 'medium' | 'small';
}) => {
  return (
    <SvgIcon
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      fontSize={size === 'small' ? 'small' : 'default'}
    >
      <rect x="1" y="1" width="22" height="22" stroke={colors.primary.main} strokeWidth="2" fill="none" />
      {checked && (
        <path
          d="M 6.357 13.292 C 5.881 12.816 5.881 12.034 6.357 11.558 C 6.833 11.082 7.615 11.082 8.091 11.558 L 9.994 13.462 L 15.909 7.547 C 16.385 7.071 17.167 7.071 17.643 7.547 C 18.119 8.023 18.119 8.805 17.643 9.28 L 10.844 16.079 C 10.674 16.249 10.436 16.385 10.198 16.419 C 10.13 16.419 10.028 16.453 9.96 16.453 C 9.654 16.453 9.314 16.317 9.076 16.079 L 6.357 13.292Z"
          fill={colors.secondary.main}
        />
      )}
    </SvgIcon>
  );
};
