import { memo, useMemo } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

import { TaskCardDesktop } from './desktop/view';
import { TaskCardMobile } from './mobile/view';
import { TaskCardProps } from './types';

export const UserTaskCard = memo(({ task, isMobile: isMobileProp, onClick }: TaskCardProps) => {
  const theme = useTheme();
  const isMobileTheme = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const isMobile = useMemo(() => isMobileProp ?? isMobileTheme, [isMobileProp, isMobileTheme]);

  const View = useMemo(() => (isMobile ? TaskCardMobile : TaskCardDesktop), [isMobile]);

  return <View task={task} onClick={onClick} />;
});
