import { FC, memo } from 'react';

import { FormControl } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { IncrementInput } from 'components/incrementInput/IncrementInput';

import { FormIncrementInputProps } from './FormItems.types';

export const FormIncrementInput: FC<Omit<FormIncrementInputProps, 'onChange' | 'value'>> = memo(
  ({ control, name, ...rest }) => {
    const {
      field: { value, onChange },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    return (
      <FormControl error={!!error} fullWidth>
        <IncrementInput {...rest} value={value} onChange={onChange} />
      </FormControl>
    );
  },
);
