import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 'initial',

    '& .MuiTooltip-tooltip': {
      maxWidth: 'initial',
    },
  },
  label: {
    marginBottom: 16,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  tooltipTitle: {
    marginBottom: 16,
  },
  icon: {
    width: 16,
    height: 16,
  },
}));
