import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { Input } from '../input/Input';

import { ConfirmationAdornment } from './confirmationAdornment/ConfirmationAdornment';
import { ConfirmationInputTypes, InputUserDecision } from './ConfirmationInput.types';

export const ConfirmationInput = ({
  onChange,
  value,
  mask,
  size,
  className,
  label,
  onBlur,
  inputRef,
  name,
  error,
  checkAdornment,
  hideConfirmationAdortment,
  onConfirmation,
}: ConfirmationInputTypes) => {
  const [hasUserDecided, setUserDecide] = useState<InputUserDecision>(InputUserDecision.Untouched);

  const isAnyAdornmentActionClicked = useMemo(
    () => (hideConfirmationAdortment ? true : hasUserDecided !== InputUserDecision.Untouched),
    [hideConfirmationAdortment, hasUserDecided],
  );

  useEffect(() => {
    if (!isAnyAdornmentActionClicked) {
      return;
    }

    onConfirmation(isAnyAdornmentActionClicked);
  }, [onConfirmation, isAnyAdornmentActionClicked]);

  const handleChange = useCallback(
    (value: string) => {
      setUserDecide(InputUserDecision.Confirmed);
      onChange(value);
    },
    [onChange],
  );

  const handleAdornmentReject = useCallback(() => {
    setUserDecide(InputUserDecision.Rejected);
    onChange('');
  }, [onChange]);

  return (
    <Input
      name={name}
      inputRef={inputRef}
      className={className}
      fullWidth
      label={label}
      value={value}
      onChange={e => handleChange(e.target.value)}
      mask={mask}
      error={error}
      variant="outlined"
      onBlur={onBlur}
      size={size}
      checkAdornment={checkAdornment && isAnyAdornmentActionClicked}
      endAdornment={
        !isAnyAdornmentActionClicked && (
          <ConfirmationAdornment
            onConfirm={() => setUserDecide(InputUserDecision.Confirmed)}
            onReject={handleAdornmentReject}
          />
        )
      }
    />
  );
};
