import { memo, useCallback, useMemo } from 'react';

import { Box, Grid } from '@material-ui/core';

import { Button, DrawerSidebar, FormTextArea, FormTypographyInput, Loader } from 'components';
import { TaskRelationAccount, TaskRelationAppointment, TaskRelationLead, TaskRelationTypesEnum } from 'entities/tasks';

import { TaskViewAccount } from './account';
import { TaskViewActivities } from './activities';
import { TaskViewAppointments } from './appointments';
import { useTaskViewStyles } from './assets';
import { TaskViewAttachments } from './attachments';
import { TaskViewComments } from './comments';
import { TaskViewContacts } from './contacts';
import { TaskViewGeneral } from './general';
import { TaskViewLeads } from './leads';
import { TaskViewProps, useViewForm } from './model';
import { TaskRelations } from './relations';
import { NavSelect, UpdateClaimButton } from './ui';

export const TaskView = memo(
  ({
    loading,
    onSubmit,
    onDelete,
    onClose,
    user,
    priorities,
    relationTypes,
    swimlanes,
    task,
    users,
    categories,
    lostReasons,
    visibility,
    isDone,
    permissions,
    settings,
  }: TaskViewProps) => {
    const styles = useTaskViewStyles();

    const {
      containerRef,
      control,
      dueDate,
      dueTime,
      formState,
      hasUnsavedChanges,
      isClaim,
      isEstimate,
      isCommentFormVisible,
      scrollNavOptions,
      scrollToId,
      handleSubmit,
      setCommentFormVisibility,
      setRef,
      setScrollToId,
      setValue,
      setError,
      watch,
      trigger,
      clearErrors,
    } = useViewForm({
      task,
      loading,
    });

    const relations = watch('relations');

    const [accounts, appointments, leads] = useMemo(() => {
      const sorted = relations.sort((a, b) => (a.id || 0) - (b.id || 0));

      return [
        sorted.reduce<TaskRelationAccount[]>(
          (list, current) =>
            current.type.value === TaskRelationTypesEnum.Account && current.account ? [...list, current.account] : list,
          [],
        ),
        sorted.reduce<TaskRelationAppointment[]>(
          (list, current) =>
            current.type.value === TaskRelationTypesEnum.Appointment && current.appointment
              ? [...list, current.appointment]
              : list,
          [],
        ),
        sorted.reduce<TaskRelationLead[]>(
          (list, current) =>
            current.type.value === TaskRelationTypesEnum.Lead && current.lead ? [...list, current.lead] : list,
          [],
        ),
      ];
    }, [relations]);

    const showUpdateClaimsSection = useMemo(
      () => !permissions?.updateClaimSection?.denied && isClaim && !isDone,
      [permissions?.updateClaimSection?.denied, isClaim, isDone],
    );

    const showContactInformationSection = useMemo(
      () => !permissions?.contactInformationSection?.denied,
      [permissions?.contactInformationSection?.denied],
    );

    const showAppointmentInformationSection = useMemo(
      () => !permissions?.appointmentInformationSection?.denied && !!appointments?.length,
      [permissions?.appointmentInformationSection?.denied, appointments.length],
    );

    const showLeadInformationSection = useMemo(
      () => !permissions?.leadInformationSection?.denied && !!leads?.length,
      [permissions?.leadInformationSection?.denied, leads.length],
    );

    const showGeneralSection = useMemo(
      () => !permissions?.generalSection?.denied,
      [permissions?.generalSection?.denied],
    );

    const showRelationsSection = useMemo(
      () => !permissions?.relationsSection.denied,
      [permissions?.relationsSection.denied],
    );

    const onHandleSubmit = useCallback(
      async values => {
        await onSubmit(values, hasUnsavedChanges);
      },
      [hasUnsavedChanges, onSubmit],
    );

    const handleClose = useCallback(() => onClose && onClose(hasUnsavedChanges), [hasUnsavedChanges, onClose]);

    return (
      <DrawerSidebar
        visibility={visibility}
        ModalProps={{
          onBackdropClick: handleClose,
          closeAfterTransition: true,
        }}
      >
        {loading ? (
          <div className={styles.container}>
            <Loader />
          </div>
        ) : (
          <div ref={containerRef} className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit(onHandleSubmit)}>
              <Box p={{ xs: 3, sm: 4 }} flex={1}>
                <Box mb={1}>
                  <Grid alignItems="center" container justify="space-between" spacing={2}>
                    <Grid item xs={8} sm={9}>
                      <Box display="flex" alignItems="center">
                        <FormTypographyInput
                          control={control}
                          disabled={loading || isDone}
                          name="summary"
                          placeholder="Task summary"
                          variant="h4"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <Box display="flex" justifyContent="flex-end">
                        <NavSelect
                          value={scrollToId}
                          items={scrollNavOptions}
                          classes={{ title: styles.scrollNavTitle }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextArea
                      name="description"
                      label="Description"
                      control={control}
                      rows={3}
                      disabled={loading || isDone}
                    />
                  </Grid>

                  {showUpdateClaimsSection && (
                    <Grid item xs={12}>
                      <UpdateClaimButton
                        dueDate={dueDate}
                        dueTime={dueTime}
                        onClick={() => {
                          setCommentFormVisibility(true);
                          setScrollToId('Comments');
                        }}
                      />
                    </Grid>
                  )}

                  {showContactInformationSection && (
                    <Grid item xs={12}>
                      {!!accounts?.length ? (
                        <TaskViewContacts accounts={accounts} />
                      ) : (
                        <TaskViewAccount setValue={setValue} watch={watch} useNewAccountPage={user.useNewAccountPage} />
                      )}
                    </Grid>
                  )}

                  {showAppointmentInformationSection && (
                    <Grid item xs={12}>
                      <TaskViewAppointments appointments={appointments} />
                    </Grid>
                  )}

                  {showLeadInformationSection && (
                    <Grid item xs={12}>
                      <TaskViewLeads
                        leads={leads}
                        categories={categories}
                        lostReasons={lostReasons}
                        setValue={setValue}
                        watch={watch}
                      />
                    </Grid>
                  )}

                  {showGeneralSection && (
                    <Grid item xs={12} ref={setRef('General')}>
                      <TaskViewGeneral
                        control={control}
                        isClaim={isClaim}
                        isEstimate={isEstimate}
                        isDone={isDone}
                        loading={loading}
                        priorities={priorities}
                        setValue={setValue}
                        swimlanes={swimlanes}
                        user={user}
                        users={users}
                        watch={watch}
                        onDelete={onDelete}
                        initialAssignees={task?.assignees}
                        permissions={permissions?.generalSection}
                      />
                    </Grid>
                  )}

                  {showRelationsSection && (
                    <Grid item ref={setRef('Relations')} xs={12}>
                      <TaskRelations
                        isClaim={isClaim}
                        isDone={isDone}
                        relationTypes={relationTypes}
                        settings={settings.relations}
                        permissions={permissions?.relationsSection}
                        setValue={setValue}
                        watch={watch}
                      />
                    </Grid>
                  )}

                  <Grid item ref={setRef('Comments')} xs={12}>
                    <TaskViewComments
                      isClaim={isClaim}
                      users={users}
                      isDone={isDone}
                      user={user}
                      isVisible={isCommentFormVisible}
                      timeZone={settings.timeZone}
                      setIsVisible={setCommentFormVisibility}
                      setValue={setValue}
                      watch={watch}
                    />
                  </Grid>

                  <Grid item ref={setRef('Attachments')} xs={12}>
                    <TaskViewAttachments
                      loading={loading}
                      submiting={formState.isSubmitting}
                      isDone={isDone}
                      user={user}
                      settings={settings.attachments}
                      watch={watch}
                      trigger={trigger}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </Grid>

                  <Grid item ref={setRef('Activity')} xs={12}>
                    <TaskViewActivities timeZone={settings.timeZone} watch={watch} />
                  </Grid>
                </Grid>
              </Box>

              <Box className={styles.bottomBar}>
                <Grid container spacing={1} justify="flex-end">
                  <Grid item>
                    <Button color="primary" onClick={handleClose}>
                      {!hasUnsavedChanges ? 'Close' : 'Cancel'}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Box width={136}>
                      <Button
                        type="submit"
                        buttonType="twoTone"
                        fullWidth
                        disabled={!formState.isValid || loading}
                        isLoading={formState.isSubmitting}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </div>
        )}
      </DrawerSidebar>
    );
  },
);
