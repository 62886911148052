import { memo, useCallback } from 'react';

import { Box, Typography } from '@material-ui/core';
import moment from 'moment';

import { colors } from 'assets';
import { Paper, StatusBadge } from 'components';
import { USADateFormat, USATimeFormatAmPm } from 'utils';

export const UpdateClaimButton = memo(
  ({ dueDate, dueTime, onClick }: { dueDate: string; dueTime: string; onClick: () => void }) => {
    const handleClick = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
      },
      [onClick],
    );

    return (
      <Paper variant="outlined" style={{ cursor: 'pointer' }} onClick={handleClick}>
        <Box p={2} display={{ sm: 'flex' }} alignItems="center" justifyContent="space-between">
          <Box mr={1} flex={1}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Box flex={{ xs: 1, sm: 'initial' }}>
                <Typography variant="subtitle1" style={{ fontSize: 18 }} component="span">
                  You must update by {moment(dueTime).format(USATimeFormatAmPm)}{' '}
                  {moment(dueDate, USADateFormat).format(USADateFormat)}
                </Typography>
              </Box>

              <Box ml={{ xs: 3, sm: 6 }}>
                <StatusBadge text="Awaiting Reply" type="error" size="small" />
              </Box>

              <Box width="100%">
                <Typography component="span">You can use the comment section to update the claim.</Typography>
              </Box>
            </Box>
          </Box>

          <Box
            color={{ xs: 'white', sm: colors.primary.main }}
            bgcolor={{ sm: 'initial', xs: colors.primary.main }}
            mt={{ xs: 2, sm: 0 }}
            p={{ xs: 1, sm: 0 }}
            textAlign="center"
            borderRadius={4}
          >
            <Typography variant="h6" color="inherit" component="span">
              Update
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  },
);
