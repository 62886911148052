export type CompletedAction = {
  id: number;
  location: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  user: {
    id: number;
    name: string;
  };
  date: string;
};

export enum CompletedActionsJobEnum {
  Created = 18,
  WelcomeCallCompleted = 1,
  PrePaymentCollected = 2,
  StartTravelETA = 3,
  EndTravel = 4,
  PreJobInspection = 5,
  StartWork = 6,
  EndWork = 7,
  PostJobInspection = 8,
  FinalizeBilling = 9,
  Payment = 10,
  AddProducts = 12,
  CompleteJob = 13,
  ConfirmationCallCompleted = 14,
  StartBreak = 16,
  EndBreak = 17,
  ETACallCompleted = 23,
  FinalizeValuation = 24,
  FinalizePreExistingDamage = 25,
  FinalizeLiabilityWaiver = 26,
  FinalizeAddendum = 27,
}

export enum CompletedActionsEstimateEnum {
  WelcomeCallCompleted = 11,
  ConfirmationCallCompleted = 15,
  Created = 18,
  StartTravel = 19,
  EndTravel = 20,
  StartWork = 21,
  EndWork = 22,
}
