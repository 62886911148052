import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  button: {
    borderRadius: 4,
    color: colors.basic.white,
    padding: 8,
    marginRight: 8,

    backgroundColor: colors.functionals.success,

    '&:hover': {
      backgroundColor: colors.functionals.successLight,
    },
  },
  warningBtn: {
    backgroundColor: colors.functionals.alert,

    '&:hover': {
      backgroundColor: colors.functionals.alertLight,
    },
  },
});
