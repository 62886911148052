import { memo } from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme';

import { SvgIconProps } from './SvgIcon.types';

export const VisibilityIcon = memo(({ className, color = colors.primary.main, fontSize = 22 }: SvgIconProps) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="22" height="14" viewBox="0 0 22 14" fill="none">
      <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9988 1.451C7.68435 1.39509 4.25646 3.71132 2.10139 6.08346C1.87518 6.3346 1.75 6.66061 1.75 6.99861C1.75 7.3366 1.87518 7.66262 2.10139 7.91375C4.20959 10.2357 7.62761 12.6054 10.9988 12.5487C14.3699 12.6054 17.7888 10.2357 19.8986 7.91375C20.1248 7.66262 20.25 7.3366 20.25 6.99861C20.25 6.66061 20.1248 6.3346 19.8986 6.08346C17.7411 3.71132 14.3132 1.39509 10.9988 1.451Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0823 7.0003C14.0821 7.6101 13.9011 8.20616 13.5622 8.71311C13.2233 9.22006 12.7417 9.61513 12.1783 9.84838C11.6149 10.0816 10.9949 10.1426 10.3969 10.0235C9.7988 9.90444 9.24947 9.61071 8.81834 9.17946C8.3872 8.74821 8.09362 8.1988 7.97471 7.60071C7.85581 7.00262 7.91692 6.3827 8.15031 5.81933C8.38371 5.25597 8.77892 4.77446 9.28596 4.43569C9.793 4.09692 10.3891 3.91611 10.9989 3.91611C11.4039 3.916 11.805 3.99571 12.1792 4.15067C12.5534 4.30564 12.8934 4.53283 13.1797 4.81926C13.4661 5.10568 13.6932 5.44573 13.848 5.81997C14.0029 6.1942 14.0825 6.59529 14.0823 7.0003Z"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
});
