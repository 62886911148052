import { useCallback, useMemo, useState } from 'react';

import { Collapse } from '@material-ui/core';

import { TaskViewContactsProps } from './model';
import { AccountCard, ViewSection } from './ui';

export const TaskViewContacts = ({ accounts }: TaskViewContactsProps) => {
  const [visibility, setVisibility] = useState(false);
  const toggleList = useCallback(() => setVisibility(!visibility), [visibility]);

  const [first, ...rest] = useMemo(() => accounts || [], [accounts]);

  const action = useMemo(
    () =>
      rest.length
        ? {
            title: visibility ? 'View only primary' : 'View all',
            onClick: toggleList,
          }
        : undefined,
    [rest.length, toggleList, visibility],
  );

  return (
    <ViewSection title="Contact information" button={action}>
      <AccountCard account={first} separator={false} />

      <Collapse in={visibility}>
        {accounts.slice(1).map(account => (
          <AccountCard key={account.id} account={account} />
        ))}
      </Collapse>
    </ViewSection>
  );
};
