import { TaskViewSectionProps } from '../../model';

export const accountTypes = [
  {
    id: 3,
    name: 'Commercial',
  },
  {
    id: 5,
    name: 'Residential',
  },
];

export type TaskAccountFormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  type: {
    label: string;
    value: number;
  };
  source: {
    label: string;
    value: number;
  };
};

export type TaskAccountFormProps = Pick<TaskViewSectionProps, 'loading'> & {
  findAccounts: (payload: { firstName?: string; lastName?: string; phone?: string; email?: string }) => Promise<void>;
  createAndRelate: (payload: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    type: number;
    source: number;
  }) => Promise<void>;
  isSearching?: boolean;
  referralSources: {
    value: number;
    label: string;
  }[];
};
