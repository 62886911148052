import { FC, memo, useMemo } from 'react';

import { FormControl } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { ColorfulSelect } from 'components/colorfulSelect/ColorfulSelect';
import { HelperTxt } from 'components/helperTxt/HelperTxt';

import { FormColorfulSelectProps } from './FormItems.types';

export const FormColorfulSelect: FC<FormColorfulSelectProps> = memo(
  ({ control, name, palette, size = 'medium', variant = 'outlined', options, required, ...rest }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    return (
      <FormControl error={!!error} fullWidth>
        <ColorfulSelect
          {...rest}
          {...field}
          required={required}
          size={size}
          variant={variant}
          error={!!error}
          options={options}
          palette={palette}
        />

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
