import React from 'react';

import { SnackbarProvider, SnackbarProviderProps } from 'notistack';

import { AlertContextController } from 'context/alert/AlertContextController';

export const AlertProvider = (props: SnackbarProviderProps) => {
  const { children, ...restProps } = props;

  return (
    <SnackbarProvider {...restProps}>
      <AlertContextController>{children}</AlertContextController>
    </SnackbarProvider>
  );
};
