import { DataItem } from 'types';

export type TaskBoard = DataItem & { color: string; type: DataItem };

export enum TaskBoardTypesEnum {
  GENERAL = 1,
  CLAIMS = 2,
  LEAD = 3,
  APPOINTMENT = 4,
  SLC = 5,
  ESTIMATE = 6,
}
