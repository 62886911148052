import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { TextMaskProps } from './TextMaskProps.types';

export const DollarAmountMask = ({ inputRef, ...other }: TextMaskProps) => {
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={createNumberMask({
        prefix: '$ ',
        suffix: '',
        allowDecimal: true,
        decimalLimit: 2,
        includeThousandsSeparator: false,
        allowLeadingZeroes: true,
      })}
    />
  );
};
