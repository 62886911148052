import { createEffect, createStore } from "effector";

import { Task } from "../types";
import { getTask, updateTask } from "../api";

export const $task = createStore<{ task: Task | null; loading: boolean }>({
  task: null,
  loading: true,
});

export const getTaskFx = createEffect(getTask);
$task
  .on(getTaskFx.doneData, (store, response) => ({
    ...store,
    task: response.data.tasks[0] ?? null,
  }))
  .on(getTaskFx.fail, (store) => ({
    ...store,
    task: null,
  }))
  .on(getTaskFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

export const updateTaskFx = createEffect(updateTask);
$task.on(updateTaskFx.doneData, (store, response) => ({
  ...store,
  task: response.data.tasks?.[0] ?? store.task,
}));
