import { createElement, memo } from 'react';

import { IconButton, Typography } from '@material-ui/core';

import { CloseIcon, colors } from 'assets';
import { getFileIcon } from 'utils';

import { useStyles } from './AttachmentItem.styles';
import { AttachmentItemProps } from './AttachmentItem.types';

export const AttachmentItem = memo(({ name, description, onPreview, onDelete }: AttachmentItemProps) => {
  const styles = useStyles();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        onClick={onPreview}
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          marginRight: 16,
          cursor: !!onPreview ? 'pointer' : 'initial',
        }}
      >
        <div style={{ display: 'flex', marginRight: 16 }}>
          {createElement(getFileIcon({ fileName: name }), { className: styles.fileIcon })}
        </div>
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="body1"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: '140%' }}
          >
            {name}
          </Typography>
          {description && (
            <Typography
              variant="body1"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                lineHeight: '140%',
                color: colors.grey140,
              }}
            >
              {description}
            </Typography>
          )}
        </div>
      </div>
      <div style={{ margin: -6 }}>
        <IconButton className={styles.removeIconButton} onClick={onDelete}>
          <CloseIcon color={colors.grey80} fontSize={12} />
        </IconButton>
      </div>
    </div>
  );
});
