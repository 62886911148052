// TODO

import { memo, useCallback, useMemo } from 'react';

import { Box, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { PlusIcon, colors } from 'assets';
import { Button } from 'components';
import { applyPhoneMask } from 'utils';

import { useAccountListStyles } from './styles';
import { TaskAccountListProps } from './types';

export const TaskAccountList = memo(({ accounts, foundBy, relateAccount, useNewAccountPage }: TaskAccountListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const foundByEntries = useMemo(() => Object.entries(foundBy).filter(item => item[1]), [foundBy]);

  const styles = useAccountListStyles({
    cellCount: 2 + foundByEntries.length,
  });

  const getAccountUrl = useCallback(
    (id: number) => '',
    // useNewAccountPage
    //   ? routePaths.accountDetails(id)
    //   : `${hunkwareAppURL}/hunkware/bookingflow/clientupdate?Client=${id}`,
    [useNewAccountPage],
  );

  const label = useMemo(() => {
    let text = 'Account ';

    foundByEntries.forEach(([key, value]) => {
      text += `with ${key} ${value} `;
    });

    text += 'has been found.';

    return text;
  }, [foundByEntries]);

  return (
    <Box my={2}>
      <Typography color="error">
        <b>{label}</b>
      </Typography>

      <ul className={styles.list}>
        {accounts.map(account => (
          <li key={account.id} className={styles.item}>
            <div className={styles.row}>
              <Link
                href={getAccountUrl(account.id)}
                target="_blank"
                underline="always"
                className={clsx(styles.col, account.billingAddress.address && styles.name)}
                color="primary"
                variant="subtitle1"
                title={account.firstName + ' ' + account.lastName}
              >
                {account.firstName} {account.lastName}
              </Link>

              <Typography
                component="div"
                className={clsx(styles.col, styles.address)}
                title={account.billingAddress.address}
              >
                {account.billingAddress.address}
              </Typography>

              {!!foundBy.phone && (
                <Typography
                  component="div"
                  className={clsx(styles.col, styles.phone)}
                  title={applyPhoneMask(account.phone)}
                  style={{
                    fontWeight: foundBy.phone && !foundBy.email ? 'bold' : undefined,
                  }}
                >
                  {applyPhoneMask(account.phone)}
                </Typography>
              )}

              {!!foundBy.email && (
                <Typography
                  component="div"
                  className={clsx(styles.col, styles.email)}
                  title={account.email}
                  style={{ fontWeight: foundBy.email ? 'bold' : undefined }}
                >
                  {account.email}
                </Typography>
              )}
            </div>

            <Box width={{ xs: 40, sm: foundByEntries.length === 2 ? 64 : 98 }}>
              {isMobile ? (
                <IconButton color="primary" onClick={() => relateAccount(account)}>
                  <PlusIcon color={colors.primary.main} />
                </IconButton>
              ) : (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => relateAccount(account)}
                  fullWidth
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {foundByEntries.length === 2 ? 'Relate' : 'Relate account'}
                </Button>
              )}
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
});
