import { memo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Button } from '../button/Button';

import { useStyles } from './ConfirmationDialog.styles';
import { ConfirmationDialogProps } from './ConfirmationDialog.types';

export const ConfirmationDialog = memo(
  ({
    onConfirm,
    onClose,
    message,
    confirmButtonText,
    cancelButtonText,
    confirmationButtonType = 'twoTone',
    cancelButtonType = 'text',
    confirmationButtonColor,
    cancelButtonColor = 'primary',
    isLoading = false,
    hideConfirmButton,
    hideCancelButton,
    maxWidth = 456,
  }: ConfirmationDialogProps) => {
    const styles = useStyles({ maxWidth });

    return (
      <Grid container className={styles.container}>
        <div className={styles.wrapper}>
          <div>
            <Typography variant="body1" component="div">
              {message ?? 'Are you sure you want to do this?'}
            </Typography>
          </div>

          <Grid className={styles.buttonsContainer} container justify="flex-end" spacing={1}>
            {!hideCancelButton && (
              <Grid item>
                <Button buttonType={cancelButtonType} color={cancelButtonColor} onClick={onClose}>
                  {cancelButtonText ?? 'Cancel'}
                </Button>
              </Grid>
            )}

            {!hideConfirmButton && (
              <Grid item>
                <Button
                  buttonType={confirmationButtonType}
                  color={confirmationButtonColor}
                  isLoading={isLoading}
                  className={clsx(confirmationButtonType !== 'text' && styles.confirmButton)}
                  onClick={() => onConfirm()}
                >
                  {confirmButtonText ?? 'Confirm'}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    );
  },
);
