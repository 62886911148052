import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from '../theme/palette';

import { SvgIconProps } from './SvgIcon.types';

export const KebabMenuIcon = ({ className, color = colors.primary.main, fontSize = 24 }: SvgIconProps) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize }}
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      fontSize="inherit"
    >
      <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill={color} />
        <circle cx="2" cy="8" r="2" fill={color} />
        <circle cx="2" cy="14" r="2" fill={color} />
      </svg>
    </SvgIcon>
  );
};
