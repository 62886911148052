import { createEffect, createStore } from "effector";

import { getPermissions } from "./api";
import { Permissions } from "./types";

export const getPermissionsFx = createEffect(getPermissions);

export const $permissionsStore = createStore<{
  permissions: Permissions | null;
  error: Error | null;
  loading: boolean;
}>({
  permissions: null,
  error: null,
  loading: true,
});

$permissionsStore
  .on(getPermissionsFx.doneData, (store, response) => ({
    ...store,
    permissions: response.list,
  }))
  .on(getPermissionsFx.failData, (store, error) => ({
    ...store,
    error,
  }))
  .on(getPermissionsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
