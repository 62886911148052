import { makeStyles, Theme } from '@material-ui/core';

import { colors } from 'assets';

type Props = {
  disabled?: boolean;
};

export const useStyles = makeStyles<Theme, Props>({
  customButton: {
    cursor: ({ disabled }) => (disabled ? 'initial' : 'pointer'),
    backgroundColor: ({ disabled }) => (disabled ? colors.grey20 : colors.white),

    '&:hover': {
      backgroundColor: ({ disabled }: Props) => (disabled ? colors.grey20 : colors.grey10),
    },
  },
});
