import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme';

export const useStyles = makeStyles({
  menuWrapper: {
    minWidth: 210,
  },
  list: {
    padding: 0,
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    fontWeight: 600,
    color: colors.grey190,
    fontSize: 16,
    lineHeight: '24px',
    '&:hover': {
      background: colors.secondary.main,
      color: colors.basic.white,
      '& $icon': {
        '& path': {
          fill: colors.basic.white,
        },
      },
    },
  },
  icon: {
    width: '8px',
    height: '13px',
    justifySelf: 'flex-end',
    alignItems: 'flex-end',
  },
});
