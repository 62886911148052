import { memo, useCallback, useState } from 'react';

import { Badge, Drawer, Grid, IconButton, List, ListItem, useMediaQuery, useTheme } from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { ChatIcon, HunkWareIcon } from 'assets';

import { LocationSelect } from './locationSelect';
import { useStyles } from './Navigation.styles';
import { NavigationProps } from './Navigation.types';
import { listItemProps } from './Navigation.utils';
import { NavigationItem } from './navigationItem/NavigationItem';
import { UserProfile } from './userProfile/UserProfile';

export const Navigation = memo(
  ({ home, menuItems, user, appLocation, message, userProfileMenuItems }: NavigationProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isSubmenuOpen, setSubmenuOpen] = useState(false);
    const [openedMenuItem, setOpenedMenuItem] = useState('');

    const handleToggle = useCallback(() => {
      setSubmenuOpen(isSubmenuOpen => !isSubmenuOpen);
    }, [setSubmenuOpen]);

    const handleToggleClose = useCallback(() => {
      setSubmenuOpen(false);
      setOpenedMenuItem('');
    }, [setSubmenuOpen]);

    const handleMenuOpen = useCallback(
      (menuName: string) => {
        if (!isMobile) {
          setOpenedMenuItem('');

          return;
        }
        setOpenedMenuItem(item => (!item || item !== menuName ? menuName : ''));
      },
      [isMobile],
    );

    return (
      <div className={styles.toolbar}>
        <Grid container alignItems="center" wrap="nowrap" spacing={0}>
          {isMobile && (
            <Grid item>
              <div className={styles.closeWrapper}>
                <IconButton onClick={handleToggle}>
                  {!isSubmenuOpen ? <MenuIcon color="primary" /> : <CloseIcon color="primary" />}
                </IconButton>
              </div>
            </Grid>
          )}

          <Grid item>
            <div className={styles.hunkWareWrapper}>
              <ListItem {...(home ? listItemProps({ menuItem: home }) : {})}>
                <HunkWareIcon className={styles.hunkWareIcon} />
              </ListItem>
            </div>
          </Grid>

          {!isMobile && (
            <Grid item>
              <div className={styles.navigationItemsContainer}>
                {menuItems.map((menuItem, index) => (
                  <div key={index}>
                    <NavigationItem menuItem={menuItem} user={user} />
                  </div>
                ))}
              </div>
            </Grid>
          )}

          {isMobile && (
            <Drawer
              style={{ zIndex: 1000 }}
              anchor="left"
              open={isSubmenuOpen}
              onClose={handleToggleClose}
              classes={{
                root: clsx(styles.drawer, isSubmenuOpen ? styles.drawerOpen : styles.drawerClose),
                paper: styles.drawerPaper,
              }}
            >
              <List>
                {menuItems.map((menuItem, index) => (
                  <NavigationItem
                    key={index}
                    isSubmenuOpen={menuItem.name === openedMenuItem}
                    menuItem={menuItem}
                    user={user}
                    handleSubmenuItemOpen={handleMenuOpen}
                    handleCloseDrawer={handleToggleClose}
                  />
                ))}
              </List>
            </Drawer>
          )}

          <Grid className={styles.userProfileWrapper}>
            <div className={styles.userProfileContent}>
              <div className={styles.locationSelectWrapper}>
                <LocationSelect appLocation={appLocation} />
              </div>

              <div className={styles.messageWrapper}>
                <IconButton className={styles.messageIconButton} onClick={message.onClick}>
                  <Badge
                    color="primary"
                    variant="dot"
                    invisible={!message.isThereUnread}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <ChatIcon />
                  </Badge>
                </IconButton>
              </div>

              <UserProfile user={user} userProfileMenuItems={userProfileMenuItems} />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  },
);
