import { FC, memo, useMemo } from 'react';

import { FormControl } from '@material-ui/core';
import clsx from 'clsx';
import { useController } from 'react-hook-form';

import { Checkbox } from 'components/checkbox/Checkbox';
import { HelperTxt } from 'components/helperTxt/HelperTxt';

import { useFormCheckboxStyles } from './FormItems.styles';
import { FormCheckboxProps } from './FormItems.types';

export const FormCheckbox: FC<FormCheckboxProps> = memo(
  ({ control, name, size = 'medium', variant = 'outline', required, ...rest }) => {
    const styles = useFormCheckboxStyles();

    const {
      field: { value, ...field },
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    return (
      <FormControl error={!!error} className={styles.formControl}>
        <Checkbox
          {...rest}
          {...field}
          className={clsx(rest.className, 'checkbox')}
          checked={!!value}
          size={size}
          variant={variant}
          required={required}
        />

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
