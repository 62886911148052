import { DataItem } from 'types/common';

import { TaskActivity } from './activity';
import { TaskBoard } from './board';
import { TaskPriority } from './priority';
import { TaskRelation } from './relation';
import { TaskSwimlane } from './swimlane';

export type Task = {
  id: number;
  summary: string;
  description: string;
  board: TaskBoard;
  swimlane: TaskSwimlane;
  isOverdue: boolean;
  due: {
    datetime: string;
  };
  flag: { active: boolean; reason: string };
  relations: TaskRelation[];
  accounts?: Omit<TaskAccount, 'tasks'>[];
  appointments?: TaskAppointment[];
  activities: TaskActivity[];
  priority: TaskPriority;
};

type TaskAccount = DataItem;

type TaskAppointment = DataItem;

export const resolutionTypes = {
  'Repair/fix': 'Repair/fix',
  Reimburse: 'Reimburse',
  Refund: 'Refund',
  Replace: 'Replace',
  Denied: 'Denied',
};
