import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  inputWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginLeft: 24,
  },
  input: {
    width: '100%',
    color: colors.secondary.main,
  },
}));
