import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const PhoneIcon = ({ className, color = colors.primary.main }: { className?: string; color?: string }) => {
  return (
    <SvgIcon className={className} width="22" height="23" viewBox="0 0 22 23" fill="none">
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.61404 1.40215C3.60779 0.532618 5.0076 0.532618 6.00135 1.40215C6.01677 1.41564 6.03163 1.42976 6.0459 1.44447L8.85429 4.34063C9.45923 4.83118 9.54575 5.56869 9.54575 6.0547C9.54575 6.67375 9.30523 7.31685 8.88171 7.74037C8.5525 8.06959 8.5525 8.66118 8.88171 8.99039L13.5031 13.6118C13.6128 13.7215 13.8584 13.8364 14.1281 13.8364C14.3978 13.8364 14.6434 13.7215 14.7531 13.6118C15.6681 12.6968 17.2094 12.6968 18.1244 13.6118L21.0572 16.5445C21.9722 17.4595 21.9722 19.0009 21.0572 19.9159L20.3462 20.6269C18.4911 22.482 15.5245 22.779 13.2811 21.3514C13.277 21.3488 13.273 21.3462 13.269 21.3435C13.2649 21.3408 13.2608 21.3381 13.2568 21.3353C8.56088 18.0842 4.4067 13.9304 1.15442 9.14229L1.15077 9.13691L1.1508 9.1369C-0.289949 6.97578 0.0142335 4.01018 1.94367 2.15821L2.54349 1.4727C2.56541 1.44764 2.58898 1.42407 2.61404 1.40215ZM3.63571 2.50234L3.05025 3.17144C3.03484 3.18905 3.01861 3.20593 3.00162 3.22202C1.57824 4.57049 1.35797 6.74073 2.39715 8.30227C5.54054 12.9293 9.55745 16.9475 14.0982 20.0934C15.7637 21.1455 17.9445 20.9073 19.2856 19.5662L19.9966 18.8552C20.3258 18.526 20.3258 17.9344 19.9966 17.6052L17.0638 14.6724C16.7346 14.3432 16.143 14.3432 15.8138 14.6724C15.3902 15.0959 14.7471 15.3364 14.1281 15.3364C13.509 15.3364 12.8659 15.0959 12.4424 14.6724L7.82105 10.0511C6.90605 9.13605 6.90605 7.59471 7.82105 6.67971C7.93077 6.57 8.04575 6.32438 8.04575 6.0547C8.04575 5.87586 8.02694 5.74227 7.99536 5.64755C7.96686 5.56203 7.93383 5.5237 7.90138 5.49936C7.86991 5.47576 7.84035 5.44972 7.81296 5.42147L4.99285 2.51324C4.57889 2.16593 4.05203 2.16229 3.63571 2.50234Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
