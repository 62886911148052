import * as yup from 'yup';

import { accountTypes } from './types';

export const accountFormShema = yup.object({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  phone: yup
    .string()
    .trim()
    .transform((_, originvalValue) => {
      return originvalValue.replace(/\D/g, '');
    })
    .when((phone, schema) => {
      if (String(phone).length > 0) {
        return schema.min(10, 'Must be a valid phone');
      }

      return schema;
    })
    .required('Phone is required'),
  email: yup.string().trim().email('Must be a valid email').required('Email is required'),
  type: yup
    .object({ label: yup.string().required(), value: yup.number().required() })
    .nullable()
    .required('Account type is required'),
  source: yup
    .object({ label: yup.string().required(), value: yup.string().required() })
    .nullable()
    .required('Source is required'),
});

export const typeOptions = accountTypes.map(({ id, name }) => ({
  value: id,
  label: name,
}));
