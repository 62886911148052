import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  clickable: {
    cursor: 'pointer',
  },
  taskIdSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  summary: {
    color: colors.grey120,
  },
  boardSwimlaneSection: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
  },
  swimlane: {
    color: colors.grey140,
    fontWeight: 'bold',
  },
  dueDateTime: {
    color: colors.grey140,
  },
  flagItem: {
    width: 18,
  },
}));
