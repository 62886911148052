import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    maxWidth: 400,
    width: '100vw',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  audioPlayerWrapper: {
    marginTop: 24,
    marginBottom: 32,
  },
  nativeAudioPlayer: {
    width: '100%',
  },
}));
