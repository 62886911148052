import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from '../theme/palette';

import { SvgIconProps } from './SvgIcon.types';

export const TrashIcon = ({ className, color = colors.primary.main, fontSize = 24 }: SvgIconProps) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.1543 4.85712H22.1543"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.249 2H9.96324C9.20133 2 8.53467 2.66667 8.53467 3.42857V4.85714H15.6775V3.33333C15.6775 2.57143 15.0109 2 14.249 2Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.96289 16.9525V9.80969"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.249 16.9525V9.80969"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.63 20.6666C18.5347 21.4285 17.9633 22 17.2014 22H7.01093C6.24902 22 5.67759 21.4285 5.58236 20.6666L4.24902 4.85712H19.9633L18.63 20.6666Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
