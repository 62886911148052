import { createEffect, createStore } from "effector";

import { Task } from "../types";
import { getUserTasks } from "../api";

import { updateTaskFx } from "./task";

export const $userTasks = createStore<{
  tasks: Task[];
  loadCount: number;
  loading: boolean;
}>({
  tasks: [],
  loadCount: 0,
  loading: true,
});

export const getUserTasksFx = createEffect(getUserTasks);
$userTasks
  .on(getUserTasksFx.doneData, (store, response) => ({
    ...store,
    tasks: response.data.tasks,
    loadCount: store.loadCount + 1,
  }))
  .on(getUserTasksFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$userTasks.on(updateTaskFx.doneData, (store, response) => {
  const updatedTask = response.data.tasks[0];

  return {
    ...store,
    tasks: store.tasks.map((task) =>
      updatedTask && updatedTask.id === task.id ? updatedTask : task,
    ),
  };
});
