import { useCallback, useEffect, useMemo } from "react";
import { useStore } from "effector-react";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import moment from "moment";

import {
  $auth,
  $authorizedUser,
  authApi,
  loginUserFx,
  resetAuthorizedUser,
} from "../store";
import { routePaths } from "shared/utils";
import { removeAllSettingStatuses, resetSettingsEv } from "features/settings";
import { isLoggedInUserType } from "../utils";
import { ExpiredUser } from "../types";
import { requestResetPasswordLink } from "../api";

export const useAuth = () => {
  const navigate = useNavigate();

  const authState = useStore($auth);
  const { user: authorizedUser } = useStore($authorizedUser);

  const isPasswordExpired = useMemo(() => {
    if (authorizedUser?.passwordExpiresDatetime) {
      return moment(authorizedUser.passwordExpiresDatetime).isBefore(
        moment().utc(),
      );
    }

    return false;
  }, [authorizedUser?.passwordExpiresDatetime]);

  const logout = useCallback(
    (options?: {
      skipRedirect?: boolean;
      beforeLogoutCallback?: () => void;
      afterLogoutCallback?: () => void;
    }) => {
      if (options?.beforeLogoutCallback) {
        options.beforeLogoutCallback();
      }

      localStorage.removeItem("crew/auth/accessToken");
      localStorage.removeItem("crew/auth/locationId");

      removeAllSettingStatuses();
      resetSettingsEv();

      authApi.deleteToken();
      resetAuthorizedUser();

      if (options?.afterLogoutCallback) {
        options.afterLogoutCallback();
      }

      if (!options?.skipRedirect) {
        navigate(routePaths.login());
      }
    },
    [navigate],
  );

  const passwordExpired = useCallback(
    async (username: string, errorCallback?: () => void) => {
      try {
        const requestResetPasswordLinkPayload = {
          username: username,
          method: "email",
          expired: true,
        };

        await requestResetPasswordLink({
          payload: requestResetPasswordLinkPayload,
        });

        localStorage.setItem(
          "crew/resetLink",
          JSON.stringify(requestResetPasswordLinkPayload),
        );

        logout({
          skipRedirect: true,
          beforeLogoutCallback: () => {
            navigate(
              {
                pathname: routePaths.resetPasswordLinkSuccess(),
                search: createSearchParams({ type: "expired" }).toString(),
              },
              {
                state: {
                  resendProfileData: requestResetPasswordLinkPayload.username,
                  method: requestResetPasswordLinkPayload.method,
                },
              },
            );
          },
        });
      } catch (error) {
        if (!!errorCallback) {
          errorCallback();

          return;
        }

        throw error;
      }
    },
    [logout, navigate],
  );

  const authorize = useCallback(
    async ({
      username,
      password,
      navigateTo,
    }: {
      username: string;
      password: string;
      navigateTo?: string;
    }) => {
      if (authState.isAuthorized) {
        logout({ skipRedirect: true });
      }

      try {
        await loginUserFx({
          payload: {
            userName: username,
            password,
            passwordUnprotected: password,
          },
        });

        localStorage.removeItem("crew/resetLink");

        navigate(navigateTo ?? routePaths.dashboard());
      } catch (error: any) {
        const errorResponseUser: ExpiredUser | undefined =
          error?.response?.data?.user;
        const isPasswordExprired =
          errorResponseUser && !isLoggedInUserType(errorResponseUser);

        if (errorResponseUser && isPasswordExprired) {
          await passwordExpired(errorResponseUser.user_name);
        }

        throw error;
      }
    },
    [authState.isAuthorized, logout, passwordExpired, navigate],
  );

  // Checking localStorage if there is a token
  useEffect(() => {
    const token = localStorage.getItem("crew/auth/accessToken");
    const locationID = localStorage.getItem("crew/auth/locationId");

    if (token && locationID) {
      authApi.setToken({ token, locationId: Number(locationID) });
    } else {
      authApi.setAuthorizing(false);
    }
  }, []);

  // Sync localStorage with app state store
  useEffect(() => {
    if (authState.token && authState.locationId) {
      localStorage.setItem("crew/auth/accessToken", authState.token);
      localStorage.setItem(
        "crew/auth/locationId",
        authState.locationId.toString(),
      );
    }
  }, [authState.locationId, authState.token]);

  useEffect(() => {
    if (isPasswordExpired) {
      passwordExpired(authorizedUser?.userName ?? "", () => logout());
    }
  }, [isPasswordExpired]);

  useEffect(() => {
    if (authState.isAuthorizationFailed) {
      logout();
    }
  }, [authState.isAuthorizationFailed]);

  return {
    ...authState,
    isPasswordExpired,
    passwordExpired,
    authorize,
    logout,
  };
};
