import { makeStyles } from '@material-ui/core';

import { boldPhraseLevel } from './BoldPhrase.types';

export const useStyles = (boldLevel: boldPhraseLevel) => {
  return makeStyles({
    bold: {
      fontWeight: boldLevel,
    },
  })();
};
