import React, { useState, useLayoutEffect, useCallback, useEffect, useMemo, memo } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';

import { TabsListStyled, TabStyled, TabPanelStyled, useStyles } from './Tabs.styles';
import { TabsProps } from './Tabs.types';
import { useTabsScrollStyles } from './useTabsScrollStyles';

export const Tabs = memo(({ tabs, activeTabIndex = 0, tabsPanelWrapper, disable, classes, onChange }: TabsProps) => {
  const { isShowLeftGradient, isShowRightGradient, tabsListRef, tabsWrapperRef, onScrollTabsWrapper } =
    useTabsScrollStyles();
  const styles = useStyles({ isShowLeftGradient, isShowRightGradient });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState(String(activeTabIndex));

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleChange = useCallback(
    (_: React.ChangeEvent<any>, newValue: string) => {
      setValue(newValue);

      if (onChange) {
        onChange(Number(newValue));
      }
    },
    [onChange],
  );

  useEffect(() => {
    setValue(String(activeTabIndex));
  }, [activeTabIndex]);

  useLayoutEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  });

  const tabsContent = useMemo(
    () => (
      <>
        {tabs.map(
          (tab, index) =>
            tab.content && (
              <TabPanelStyled key={index} value={String(index)} className={classes?.tabPanel}>
                {tab.content}
              </TabPanelStyled>
            ),
        )}
      </>
    ),
    [tabs, classes?.tabPanel],
  );

  return (
    <TabContext value={value}>
      <div className={styles.tabsContainer}>
        <div ref={tabsWrapperRef} onScroll={onScrollTabsWrapper} className={styles.tabsWrapper}>
          <div ref={tabsListRef} className={styles.tabsList}>
            <TabsListStyled
              onChange={handleChange}
              variant={isMobile ? 'fullWidth' : 'scrollable'}
              scrollButtons="off"
              className={classes?.tabsList}
            >
              {tabs.map((tab, index) => (
                <TabStyled
                  key={index}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {tab.title}
                      {tab.badge && <div style={{ marginLeft: 8 }}>{tab.badge}</div>}
                    </div>
                  }
                  value={String(index)}
                  disabled={disable || tab.disable}
                  className={classes?.tab}
                  onClick={tab.onClick}
                />
              ))}
            </TabsListStyled>
          </div>
        </div>
      </div>

      {tabsPanelWrapper
        ? React.cloneElement(tabsPanelWrapper, {
            children: tabsContent,
          })
        : tabsContent}
    </TabContext>
  );
});
