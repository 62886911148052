import { memo } from 'react';

import { Scrollbars as CustomScrolls, ScrollbarProps } from 'react-custom-scrollbars';

import { useStyles } from './Scrollbars.styles';

export type { CustomScrolls };

export const Scrollbars = memo(
  ({
    children,
    getInstance,
    key,
    ...props
  }: ScrollbarProps & { key?: string | number; getInstance?: (instance: CustomScrolls) => void }) => {
    const styles = useStyles();

    return (
      <CustomScrolls
        key={key}
        autoHideTimeout={800}
        autoHideDuration={200}
        renderTrackHorizontal={props => <div {...props} className={styles.trackHorizontal} />}
        renderTrackVertical={props => <div {...props} className={styles.trackVertical} />}
        renderThumbHorizontal={props => <div {...props} className={styles.thumbHorizontal} />}
        renderThumbVertical={props => <div {...props} className={styles.thumbVertical} />}
        {...props}
        ref={getInstance}
      >
        {children}
      </CustomScrolls>
    );
  },
);
