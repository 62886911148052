import { useDialogState } from "@chhjpackages/components";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { ScrollToTopProps } from "./types";

export const ScrollToTop = memo(
  ({ ignorePaths, ignoreBetweenPaths }: ScrollToTopProps) => {
    const { pathname } = useLocation();

    const { open } = useDialogState();

    const openRef = useRef(open);

    const [prevPathname, setPrevPathname] = useState("");

    useEffect(() => {
      setPrevPathname(pathname);
    }, [pathname]);

    const isIgnorePaths = useMemo(
      () =>
        !!ignorePaths?.find((ignorePath) => !!matchPath(ignorePath, pathname)),
      [ignorePaths, pathname],
    );

    const isIgnorePathsBetween = useMemo(
      () =>
        !!ignoreBetweenPaths?.find(
          (ignorePathsInner) =>
            ignorePathsInner.filter(
              (ignorePath) =>
                !!matchPath(ignorePath, prevPathname) ||
                !!matchPath(ignorePath, pathname),
            ).length > 1,
        ),
      [ignoreBetweenPaths, prevPathname, pathname],
    );

    const isIgnore = useMemo(
      () => isIgnorePaths || isIgnorePathsBetween,
      [isIgnorePaths, isIgnorePathsBetween],
    );

    useEffect(() => {
      if (openRef.current) {
        setTimeout(() => {
          openRef.current = open;
        }, 1);
      } else {
        openRef.current = open;
      }
    }, [open]);

    useEffect(() => {
      setTimeout(() => {
        if (!openRef.current && !isIgnore) {
          window.scrollTo(0, 0);
        }
      }, 0);
    }, [pathname]);

    return null;
  },
);
