import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const CalendarIconMain = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.3631 3.09091H11.8177V2H10.7267V3.09091H5.27219V2H4.18129V3.09091H3.63583C3.03583 3.09091 2.54492 3.58182 2.54492 4.18182V12.9091C2.54492 13.5091 3.03583 14 3.63583 14H12.3631C12.9631 14 13.454 13.5091 13.454 12.9091V4.18182C13.454 3.58182 12.9631 3.09091 12.3631 3.09091ZM12.3631 12.9091H3.63583V5.81818H12.3631V12.9091Z"
        fill="#E86825"
      />
    </SvgIcon>
  );
};
