import { cloneElement, useCallback, useEffect, useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';
import { onlyText } from 'react-children-utilities';
import { useResizeDetector } from 'react-resize-detector';

import { Tooltip } from 'components/tooltip/Tooltip';

export const useEllipsis = ({ text }: { text: JSX.Element | string }) => {
  const { width, ref } = useResizeDetector();

  const [content, setContent] = useState<JSX.Element | string>();

  const isEllipsis = useMemo(
    () => ref.current && ref.current.offsetWidth < ref.current.scrollWidth,
    [ref, width, content],
  );

  useEffect(() => {
    setContent(typeof text === 'string' ? text : onlyText(text.props.children));
  }, [text]);

  const renderEllipsisText = useCallback(
    () => (
      <Tooltip
        isClick={!isEllipsis}
        isArrow
        interactive
        placement="top"
        zIndex={1300}
        enterTouchDelay={0}
        title={<Typography variant="body1">{typeof text === 'string' ? text : text.props.children}</Typography>}
      >
        {typeof text === 'string' ? (
          <Typography variant="body1" noWrap ref={ref}>
            {text}
          </Typography>
        ) : (
          cloneElement(text, {
            style: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            ref,
          })
        )}
      </Tooltip>
    ),
    [text, ref, isEllipsis],
  );

  return { isEllipsis, renderEllipsisText };
};
