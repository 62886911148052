import { ChangeEvent, forwardRef, useCallback } from 'react';

export type ImagePluginInputProps = {
  handleChange: (data: FileList) => void;
};

export const ImagePluginInput = forwardRef<HTMLInputElement, ImagePluginInputProps>(({ handleChange }, ref) => {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        handleChange(e.target.files);
        e.target.value = '';
      }
    },
    [handleChange],
  );

  return (
    <div>
      <input
        type="file"
        id="images_upload"
        accept="image/*"
        multiple
        className="files-text files-input"
        ref={ref}
        onChange={onChange}
        style={{ opacity: 0, display: 'none' }}
      />
    </div>
  );
});
