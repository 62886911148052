import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Collapse, FormControl, Grid, Typography } from '@material-ui/core';

import { colors } from 'assets';
import { Input, NewSelect } from 'components';
import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { TaskRelationTypesEnum } from 'entities/tasks';
import { SelectableOption } from 'types/common';

import { TaskViewRelationsProps, TaskViewRelationFormValues } from './model';
import { ViewSection, RelationCard } from './ui';

export const TaskRelations = ({
  isClaim,
  relationTypes,
  isDone,
  settings,
  permissions,
  setValue,
  watch,
}: TaskViewRelationsProps) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isFormVisible, setFormVisibility] = useState(false);

  const relations = watch('relations');
  const accounts = watch('accounts');
  const appointments = watch('appointments');

  const showAppointments = useMemo(() => !permissions?.appointments?.denied, [permissions?.appointments?.denied]);

  const showAccounts = useMemo(() => !permissions?.accounts?.denied, [permissions?.accounts?.denied]);

  const showLeads = useMemo(() => !permissions?.leads?.denied, [permissions?.leads?.denied]);

  const showClaims = useMemo(() => !permissions?.claims?.denied, [permissions?.claims?.denied]);

  const canAdd = useMemo(() => !permissions?.addDenied, [permissions?.addDenied]);

  const canDelete = useMemo(() => !permissions?.deleteDenied, [permissions?.deleteDenied]);

  const typeOptions = useMemo(
    () =>
      relationTypes
        .filter(item => item.showInDropdown)
        .map(item => ({
          label: item.name,
          value: item.id,
        })),
    [relationTypes],
  );

  const filteredRelations = useMemo(
    () =>
      relations.filter(item => {
        if (item.isDeleted) {
          return false;
        }

        const isAppointment = item.type.value === TaskRelationTypesEnum.Appointment;
        const isAccount = item.type.value === TaskRelationTypesEnum.Account;
        const isLead = item.type.value === TaskRelationTypesEnum.Lead;
        const isClaim = item.type.value === TaskRelationTypesEnum.Claim;

        if (isAppointment && !showAppointments) {
          return false;
        }

        if (isAccount && !showAccounts) {
          return false;
        }

        if (isLead && !showLeads) {
          return false;
        }

        if (isClaim && !showClaims) {
          return false;
        }

        return true;
      }),
    [relations, showAppointments, showAccounts, showLeads, showClaims],
  );

  const [relationValue, setRelationValue] = useState<TaskViewRelationFormValues['value'] | ''>('');
  const [relationType, setRelationType] = useState<TaskViewRelationFormValues['type'] | null>(null);

  const hasError = useMemo(
    () => isSubmitted && (relationValue === '' || Number(relationValue) === 0),
    [isSubmitted, relationValue],
  );

  const resetForm = useCallback(() => {
    setRelationValue('');
    setRelationType(null);
    setIsSubmitted(false);
  }, []);

  const toggleForm = useCallback(() => {
    resetForm();
    setFormVisibility(!isFormVisible);
  }, [isFormVisible]);

  const getLink = useCallback((relation: TaskViewRelationFormValues) => {
    if (settings?.getLink) {
      return settings.getLink(relation.value, relation.type.value);
    }

    return '';
  }, []);

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);

    if (!hasError) {
      if (relationValue && relationType) {
        let relationIndex = -1;

        const list = relations.map((item, index) => {
          if (Number(item.value) === Number(relationValue) && Number(item.type.value) === Number(relationType.value)) {
            relationIndex = index;

            return { ...item, isDeleted: false };
          }

          return item;
        });

        if (relationIndex !== -1) {
          setValue('relations', list, {
            shouldDirty: true,
          });
        } else {
          setValue('relations', [...relations, { value: relationValue, type: relationType }], {
            shouldDirty: true,
          });
        }

        toggleForm();
      }
    }
  }, [hasError, relationType, relationValue, relations]);

  const onValueChange = useCallback(e => setRelationValue(e.target.value), []);

  const onDeleteRelation = useCallback(
    (relation: TaskViewRelationFormValues) => {
      if (accounts?.length && relation.type.value === TaskRelationTypesEnum.Account) {
        setValue(
          'accounts',
          accounts.filter(account => account.id !== relation.value),
        );
      }

      if (appointments?.length && relation.type.value === TaskRelationTypesEnum.Appointment) {
        setValue(
          'appointments',
          appointments.filter(appointment => appointment.id !== relation.value),
        );
      }

      setValue(
        'relations',
        relations.map(item =>
          Number(item.value) === Number(relation.value) && Number(item.type.value) === Number(relation.type.value)
            ? { ...item, isDeleted: true }
            : item,
        ),
        {
          shouldDirty: true,
        },
      );
    },
    [accounts, relations],
  );

  useEffect(() => {
    return () => {
      setIsSubmitted(false);
    };
  }, []);

  return (
    <ViewSection
      title="Relations"
      button={
        canAdd
          ? {
              title: isFormVisible ? 'Close relation' : 'Add relation',
              onClick: toggleForm,
              disabled: isDone,
            }
          : undefined
      }
    >
      <Collapse in={isFormVisible}>
        <Box p={2} mb={2} bgcolor={'#F2F2F2'} border={`1px solid ${colors.grey30}`} borderRadius={4}>
          <Grid container direction="column" justify="space-between" spacing={2}>
            <Grid item>
              <NewSelect
                error={false}
                name="relationType"
                label="Relation type"
                value={relationType as SelectableOption<number>}
                options={typeOptions}
                onBlur={onSubmit}
                onChange={setRelationType}
                isDisabled={isDone}
              />
            </Grid>

            <Grid item>
              <FormControl fullWidth error={hasError}>
                <Input
                  mask="positive-integer"
                  onBlur={onSubmit}
                  onChange={onValueChange}
                  label="Relation type specific ID"
                  size="medium"
                  value={relationValue}
                  variant="outlined"
                  error={hasError}
                  disabled={isDone}
                />

                <HelperTxt>{hasError ? 'Valid relation ID required' : '*required'}</HelperTxt>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      {filteredRelations.length ? (
        filteredRelations.map((item, index) => (
          <RelationCard
            key={index + ':' + item.type.label + ':' + item.type.value}
            disabled={(isClaim && item.type.value === TaskRelationTypesEnum.Claim) || isDone}
            disabledLink={item.type.value === TaskRelationTypesEnum.Lead}
            relation={item}
            getLink={getLink}
            onDelete={canDelete ? () => onDeleteRelation(item) : undefined}
          />
        ))
      ) : (
        <Typography>No relation found</Typography>
      )}
    </ViewSection>
  );
};
