import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const MinusIcon = ({
  className,
  size = 'small',
  color = colors.basic.black,
}: {
  className?: string;
  size?: 'medium' | 'small';
  color?: string;
}) => {
  return (
    <SvgIcon
      className={className}
      width="17"
      height="4"
      viewBox="0 0 17 4"
      fill="none"
      fontSize={size === 'small' ? 'small' : 'default'}
    >
      <svg width="17" height="4" viewBox="0 0 17 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8301 2.00005C16.8301 2.66279 16.2928 3.20005 15.6301 3.20005H2.03008C1.36734 3.20005 0.830078 2.66279 0.830078 2.00005C0.830078 1.33731 1.36734 0.800049 2.03008 0.800049H15.6301C16.2928 0.800049 16.8301 1.33731 16.8301 2.00005Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
