import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Paper } from '../paper/Paper';

import { useStyles } from './AttachmentCard.styles';

export const AttachmentCardSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <Paper elevation={24}>
      <Box p={2}>
        <Box className={styles.wrapper}>
          <Box className={styles.info}>
            <Box mr={2}>
              <Skeleton variant="rect" animation="wave" classes={{ root: styles.fileIcon }} />
            </Box>

            <Box>
              <Typography variant="subtitle1" className={styles.primaryText}>
                <Skeleton variant="text" animation="wave" width={250} height={20} />
              </Typography>
              <Typography variant="body1" className={styles.secondaryText}>
                <Skeleton variant="text" animation="wave" width={210} height={14} />
              </Typography>
            </Box>
          </Box>

          <Box className={styles.actions}>
            <Box mx={1.5}>
              <Skeleton variant="circle" animation="wave" className={styles.actionButton} />
            </Box>
            <Box mx={1.5}>
              <Skeleton variant="circle" animation="wave" className={styles.actionButton} />
            </Box>
            <Box mx={1.5}>
              <Skeleton variant="circle" animation="wave" className={styles.actionButton} />
            </Box>
          </Box>

          <Box mt={2.25} className={styles.description}>
            <Typography variant="body1" className={styles.secondaryText}>
              <Skeleton variant="text" animation="wave" height={14} />
              <Skeleton variant="text" animation="wave" height={14} />
              <Skeleton variant="text" animation="wave" width="45%" height={14} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
