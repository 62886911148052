import { memo, useCallback, useState } from 'react';

import { Avatar, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { DropdownMenu } from '../dropdownMenu/DropdownMenu';

import { useStyles } from './UserProfile.styles';
import { UserProfileProps } from './UserProfile.types';

const hashCode = (text: string) => text.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export const UserProfile = memo(({ user, userProfileMenuItems }: UserProfileProps) => {
  const [userProfileMenuRef, setUserProfileMenuRef] = useState<null | HTMLElement>(null);
  const handleUserProfileMenuOpen = useCallback(
    (event: { currentTarget: HTMLElement }) => {
      setUserProfileMenuRef(event.currentTarget);
    },
    [setUserProfileMenuRef],
  );
  const handleUserProfileMenuClose = useCallback(() => setUserProfileMenuRef(null), [setUserProfileMenuRef]);

  const hashForLetter = hashCode(user.first_name || '');
  const styles = useStyles({ hashForLetter });

  return (
    <div
      id="navigation-user"
      className={clsx(styles.root, { [styles.hover]: !!userProfileMenuRef })}
      onMouseEnter={handleUserProfileMenuOpen}
      onMouseLeave={handleUserProfileMenuClose}
      onTouchEnd={handleUserProfileMenuOpen}
    >
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item>
          <Avatar
            classes={{ colorDefault: styles.colorDefault }}
            className={styles.avatar}
            alt={user.first_name || ''}
            src={!!user.approved && user.imageUrl ? user.imageUrl : user.first_name}
          />
        </Grid>

        <Grid item>
          <div className={styles.expandMoreWrapper}>
            <ExpandMore className={styles.arrowDown} />
          </div>
        </Grid>
      </Grid>

      <DropdownMenu
        user={user}
        open={!!userProfileMenuRef}
        anchorEl={userProfileMenuRef}
        submenuItems={userProfileMenuItems}
        handleSubmenuClose={handleUserProfileMenuClose}
        MenuListProps={{
          onMouseLeave: () => handleUserProfileMenuClose(),
        }}
      />
    </div>
  );
});
