import { useCallback } from 'react';

import { InputAdornment } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { TimePickerProps } from '@material-ui/pickers';
import moment from 'moment';

import { Input } from '../../input/Input';
import { InputProps } from '../../input/Input.types';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';
import { USATimeFormatShort } from 'utils';

import { useStyles } from './HoursPicker.styles';
import { HoursPickerDialogContent } from './HoursPickerDialogContent';

const CustomInput = ({ value, label, onClick, onChange, error, name }: InputProps) => {
  const styles = useStyles();

  return (
    <div onClick={onClick} className={styles.customInput}>
      <Input
        size="medium"
        variant="outlined"
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        error={error}
        readOnly
        style={{ pointerEvents: 'none' }}
        endAdornment={
          <InputAdornment position="end">
            <ArrowDropDown className={styles.arrowDownIcon} />
          </InputAdornment>
        }
      />
    </div>
  );
};

/*
 * Explanation of why building own HoursPicker:
 * MuiKeyboardTimePickers has no option to change styles of disabled minutes, also ref's not working (confirmed issue)
 * so StaticPicker had to be used, so own ref can be used and manually (querySelector) find disabledMinutes
 */
export const HoursPicker = (props: Omit<TimePickerProps, 'label'> & { label?: string }) => {
  const setDialog = useDialogDispatch();
  const handleClose = useCallback(() => setDialog({ open: false }), [setDialog]);

  const handleClick = useCallback(() => {
    setDialog({
      open: true,
      variant: 'custom',
      onClose: handleClose,
      dialogContent: <HoursPickerDialogContent {...props} onClose={handleClose} />,
    });
  }, [handleClose, setDialog, props]);

  return (
    <CustomInput
      value={props.value ? moment(props.value).format(USATimeFormatShort) : ''}
      label={props.label}
      name={props.name}
      error={props.error}
      onClick={handleClick}
    />
  );
};
