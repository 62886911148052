import { FC, memo } from 'react';

import { Grid } from '@material-ui/core';

import { AudioPlayer } from '../audioPlayer/AudioPlayer';
import { Button } from '../button/Button';

import { useStyles } from './AudioDialog.styles';
import { AudioDialogProps } from './AudioDialog.types';

export const AudioDialog: FC<AudioDialogProps> = memo(({ url, variant = 'custom', actionButton, onClose }) => {
  const styles = useStyles();

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.audioPlayerWrapper}>
        {variant === 'custom' && <AudioPlayer url={url} />}
        {variant === 'native' && <audio controls src={url} className={styles.nativeAudioPlayer} />}
      </div>

      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <Button buttonType="text" color="primary" onClick={onClose}>
            Close
          </Button>
        </Grid>
        {actionButton && (
          <Grid item>
            <Button buttonType="twoTone" onClick={actionButton.onClick}>
              {actionButton.text}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
});
