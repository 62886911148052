import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import moment, { MomentInput } from 'moment';

import { ClockIcon, colors } from '../../../assets';
import { Button } from '../../button/Button';
import { ConfirmationTooltip } from '../../confirmationTooltip/confirmationTooltip';
import { Paper } from 'components/paper/Paper';
import { USADateTimeFormat } from 'utils';

import { useStyles } from './ClockTimepicker.styles';
import { ClockTimepickerProps } from './ClockTimepicker.types';

export const ClockTimepicker = ({
  value,
  format = 'HH:mm',
  handleConfirm,
  inputStyledButton,
  placement,
  disabled,
  buttonClasses,
  size = 'medium',
  maxDate,
  maxDateErrorText,
  minDate,
  minDateErrorText,
  ...rest
}: ClockTimepickerProps) => {
  const styles = useStyles({ disabled });

  const [selectedTime, setSelectedTime] = useState(value);
  const [state, setState] = useState({
    isFuture: false,
    isPast: false,
  });

  const checkDate = useCallback(
    (date: MomentInput) => {
      const errors = {
        isFuture: false,
        isPast: false,
      };

      if (maxDate && maxDate.isBefore(date, 'minutes')) {
        errors.isFuture = true;
      }

      if (minDate && minDate.isAfter(date, 'minutes')) {
        errors.isPast = true;
      }

      setState(errors);
    },
    [minDate, maxDate],
  );

  const handleTimeChange = useCallback(
    dateTime => {
      checkDate(dateTime);
      setSelectedTime(dateTime);
    },
    [checkDate],
  );

  const handleClose = useCallback(() => {
    setSelectedTime(value);
  }, [value, setSelectedTime]);

  useEffect(() => {
    if (value) {
      checkDate(moment(value));
      setSelectedTime(value);
    }
  }, [value, checkDate]);

  const valueAsText = useMemo(
    () => (selectedTime ? moment(selectedTime).format(format) : 'Select'),
    [format, selectedTime],
  );

  return (
    <ConfirmationTooltip
      toggle={
        inputStyledButton ? (
          <Paper variant={!disabled ? 'outlined' : 'elevation'}>
            <Box
              px={1.625}
              py={size === 'small' ? 1.0875 : 1.8}
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={styles.customButton}
              borderRadius={4}
            >
              <ClockIcon />

              <Box ml={1} pr={{ xs: 0, sm: 2.25 }} flex={1} textAlign="center">
                <Typography variant="h5" component="span" color="secondary">
                  {valueAsText}
                </Typography>
              </Box>
            </Box>
          </Paper>
        ) : (
          <Button
            buttonType="outlined"
            startIcon={
              <Box display="flex" alignItems="center">
                <ClockIcon />
              </Box>
            }
            disabled={disabled}
            classes={buttonClasses}
          >
            {valueAsText}
          </Button>
        )
      }
      fullWidthButton={inputStyledButton}
      placement={placement}
      zIndex={1300}
      onConfirm={() => {
        if (selectedTime) {
          handleConfirm(selectedTime);
        }
      }}
      confirmationDisabled={!selectedTime || state.isFuture || state.isPast}
      onClose={handleClose}
      disabled={disabled}
    >
      <TimePicker
        {...rest}
        variant="static"
        value={new Date(selectedTime || '')}
        onChange={handleTimeChange}
        disabled={disabled}
      />

      {maxDate && state.isFuture && (
        <Box color={colors.functionals.alert} maxWidth={260} mx="auto">
          <Typography color="inherit" variant="subtitle1" align="center">
            {maxDateErrorText || `Time should be before MAX date: (${maxDate.format(USADateTimeFormat)})`}
          </Typography>
        </Box>
      )}

      {minDate && state.isPast && (
        <Box color={colors.functionals.alert} maxWidth={260} mx="auto">
          <Typography color="inherit" variant="subtitle1" align="center">
            {minDateErrorText || `Time should be before MIN date: (${minDate.format(USADateTimeFormat)})`}
          </Typography>
        </Box>
      )}
    </ConfirmationTooltip>
  );
};
