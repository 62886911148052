import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '100%',
    top: 64,
    zIndex: 1500,
  },
  message: {
    '& + $message': {
      marginTop: '5px',
    },
  },
});
