import { PaymentProviderEnum } from "entities/payments";

export enum SettingItemsIdEnum {
  PreJobInspection = "preJobInspection",
  PostJobInspection = "postJobInspetion",
  AutoExpandJobSteps = "autoExpandJobSteps",
  TrainingMode = "trainingMode",
  NavigationApp = "navigationApp",
  PaymentPlatform = "paymentPlatform",
}

export enum SettingItemsEnum {
  PreJobInspection = "Pre-job inspection",
  PostJobInspection = "Post-job inspection",
  AutoExpandJobSteps = "Auto expand job steps",
  TrainingMode = "Training mode",
  NavigationApp = "Navigation app",
  PaymentPlatform = "Payment platform",
}

export enum PaymentPlatformEnum {
  Square = PaymentProviderEnum.Square,
  HunkPay = PaymentProviderEnum.HunkPay,
  Default,
}

export const navigationAppOptions = [
  {
    value: "google",
    label: "Google maps",
  },
  {
    value: "apple",
    label: "Apple",
  },
  {
    value: "waze",
    label: "Waze",
  },
];

export const paymentPlatformOptions = [
  {
    value: PaymentPlatformEnum.Default,
    label: "Default (location managed)",
  },
  {
    value: PaymentPlatformEnum.Square,
    label: "Square",
  },
  {
    value: PaymentPlatformEnum.HunkPay,
    label: "HunkPay",
  },
];
