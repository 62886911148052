import { createEvent, createStore } from "effector";

import { getSettingValue } from "../utils";
import {
  SettingItemsEnum,
  navigationAppOptions,
  paymentPlatformOptions,
} from "../types";
import { NewSelectOption } from "shared/types";

type Settings = {
  preJobInspection: boolean;
  postJobInspetion: boolean;
  autoExpandJobSteps: boolean;
  trainingMode: boolean;
  navigationApp: NewSelectOption;
  paymentPlatform: NewSelectOption;
};

const initialState = {
  preJobInspection: false,
  postJobInspetion: false,
  autoExpandJobSteps: !!getSettingValue<boolean>(
    SettingItemsEnum.AutoExpandJobSteps,
  ),
  trainingMode: !!getSettingValue<boolean>(SettingItemsEnum.TrainingMode),
  navigationApp:
    getSettingValue<NewSelectOption>(SettingItemsEnum.NavigationApp) ??
    navigationAppOptions[0],
  paymentPlatform:
    getSettingValue<NewSelectOption>(SettingItemsEnum.PaymentPlatform) ??
    paymentPlatformOptions[0],
};

export const $settings = createStore<Settings>(initialState);

export const setSettingEv = createEvent<{
  name: keyof Settings;
  value: unknown;
}>();

$settings.on(setSettingEv, (store, event) => ({
  ...store,
  [event.name]: event.value,
}));

export const resetSettingsEv = createEvent();

$settings.on(resetSettingsEv, () => ({
  ...initialState,
  autoExpandJobSteps: !!getSettingValue<boolean>(
    SettingItemsEnum.AutoExpandJobSteps,
  ),
  trainingMode: !!getSettingValue<boolean>(SettingItemsEnum.TrainingMode),
  navigationApp:
    getSettingValue<NewSelectOption>(SettingItemsEnum.NavigationApp) ??
    navigationAppOptions[0],
  paymentPlatform:
    getSettingValue<NewSelectOption>(SettingItemsEnum.PaymentPlatform) ??
    paymentPlatformOptions[0],
}));
