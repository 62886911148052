import { memo, useCallback, useMemo, useState } from 'react';

import { Collapse, Typography } from '@material-ui/core';

import { useLocationTimezone } from 'hooks/useLocationTimezone/useLocationTimezone';
import { USADateFormatShort, USATimeFormatAmPm } from 'utils';

import { useTaskViewActivitiesStyles } from './assets';
import { TaskViewActivitiesProps } from './model';
import { ActivityCard, ViewSection } from './ui';

export const TaskViewActivities = memo(({ timeZone, watch }: TaskViewActivitiesProps) => {
  const styles = useTaskViewActivitiesStyles();

  const { utcToZone } = useLocationTimezone(timeZone);

  const [isShowActivities, setIsShowActivities] = useState(false);

  const activities = watch('activities');

  const activitiesForDisplay = useMemo(
    () => activities.filter(activity => activity.showInTaskActivityList === '1'),
    [activities],
  );

  const buttonText = useMemo(() => (isShowActivities ? 'Hide' : 'View') + ' activity records', [isShowActivities]);

  const toggleList = useCallback(() => {
    setIsShowActivities(state => !state);
  }, []);

  return (
    <ViewSection
      title="Activity"
      button={{
        title: buttonText,
        onClick: toggleList,
      }}
    >
      <Collapse in={isShowActivities}>
        {activitiesForDisplay.length ? (
          <div className={styles.activitiesList}>
            {activitiesForDisplay.map(activity => (
              <ActivityCard
                key={activity.id}
                details={activity.description}
                createdBy={activity.created}
                createdDate={utcToZone(
                  activity.created.datetime,
                  `${USADateFormatShort} ${USATimeFormatAmPm.toLowerCase()}`,
                )}
              />
            ))}
          </div>
        ) : (
          <Typography>No activity found</Typography>
        )}
      </Collapse>
    </ViewSection>
  );
});
