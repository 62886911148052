import { createStyles, withStyles, Theme, ListItemText, ListItem, makeStyles } from '@material-ui/core';

import { colors, RiftFont, NoahFont } from 'assets';

export const ListItemStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '8px 0px',
      padding: '8px 8px',
      borderRadius: '4px',
      transition: 'background-color 250ms ease-out',
      '& .MuiTypography-root': {
        transition: 'color 250ms ease-out',
      },
      '& path': {
        transition: 'fill 250ms ease-out',
      },
      '&:hover': {
        backgroundColor: colors.primary.superlight,
        '& .MuiTypography-root': {
          color: colors.primary.main,
        },
        '& path': {
          fill: colors.primary.main,
        },
      },
      '& .MuiTouchRipple-root': {
        color: colors.primary.main,
      },
      '&:first-child': {
        marginTop: '0px',
      },
    },
    selected: {
      background: 'none!important',
      '& .MuiTypography-root': {
        color: colors.primary.main,
      },
      '& path': {
        fill: colors.primary.main,
      },
    },
  }),
)(ListItem);

export const NestedListItemStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '8px',
      [theme.breakpoints.down('sm')]: {
        padding: '4px 8px',
      },
      borderRadius: '4px',
      transition: 'background-color 250ms ease-out',
      '& .MuiTypography-root': {
        transition: 'color 250ms ease-out',
      },
      '&:hover': {
        backgroundColor: colors.primary.superlight,
        '& .MuiTypography-root': {
          color: colors.primary.main,
        },
      },
      '& .MuiTouchRipple-root': {
        color: colors.primary.main,
      },
    },
    selected: {
      background: 'none!important',
      '& .MuiTypography-root': {
        color: colors.primary.main,
      },
    },
  }),
)(ListItem);

export const NestedListItemTextStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px',
      flex: 'initial',
    },
    primary: {
      fontFamily: NoahFont,
      color: 'rgba(17, 24, 32, 0.48)',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
  }),
)(ListItemText);

export const ListItemTextStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px',
      flex: 'initial',
    },
    primary: {
      fontFamily: RiftFont,
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
  }),
)(ListItemText);

export const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    transition: 'transform 150ms ease-out',
  },
  arrowIconDown: {
    transform: 'rotateZ(90deg)',
  },
  nestedMenuCollapse: {
    marginTop: '4px',
    marginLeft: '16px',
  },
  listItemContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  badge: {
    minWidth: 20,
    height: 20,
    borderRadius: 10,
    padding: '0px 3.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&[class*="success"]': {
      backgroundColor: colors.functionals.success,
    },

    '&[class*="error"]': {
      backgroundColor: colors.functionals.alert,
    },

    '&[class*="primary"]': {
      backgroundColor: colors.primary.main,
    },
  },
  badgeTitle: {
    color: `${colors.white} !important`,
    fontSize: 15,
    lineHeight: '11px',
  },
}));
