import { makeStyles, Theme, fade } from '@material-ui/core';

import { colors } from '../../assets';

interface StyleProps {
  fullScreenBackgroundColor: string;
  isShowScrollToTop: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  paperDefault: {
    [theme.breakpoints.down('xs')]: {
      '&:not(.MuiDialog-paperFullScreen)': {
        margin: 16,
      },
    },
  },
  backdropBodyNonScrollbable: {
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    overflowY: 'scroll',
    display: 'flex',
  },
  scheduleContent: {
    padding: '50px 30px',
  },
  scheduleCloseBtnContainer: {
    position: 'absolute',
    top: '4px',
    right: '8px',
  },
  scheduleCloseIcon: {
    margin: '0 -10px',
  },
  estimateContent: {
    padding: '24px',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: '16px',
    },
  },
  estimateTitle: {
    lineHeight: 1.2,
    wordBreak: 'break-word',
  },
  estimateCloseBtnContainer: {
    marginLeft: 'auto',
  },
  estimateCloseBtn: {
    margin: -12,
    fontSize: 18,
  },
  estimatePaper: {
    borderRadius: 0,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  customPaperScrollBody: {
    '&&': {
      maxWidth: '100%',
      margin: 0,
    },
  },
  estimatePaperScrollBody: {
    '&&': {
      margin: 32,
      height: 'auto',
      maxHeight: '90vh',
    },
  },

  closeBtnContainer: {
    marginLeft: 'auto',
  },
  closeBtn: {
    margin: -12,
    fontSize: 12,
    color: colors.grey180,
  },

  basicContent: {
    height: '100%',
    padding: '8px 16px 16px 16px',
  },
  basicTitleContainer: {
    paddingBottom: '8px',
  },
  basicChildrenContainer: {
    height: '100%',
    paddingTop: '8px',
  },

  withSeparatorContent: {
    padding: '24px',
  },
  withSeparatorTitleContainer: {
    paddingBottom: '24px',
  },
  withSeparatorChildrenContainer: {
    paddingTop: '16px',
  },
  separatorContainer: {
    margin: '0 -24px',
  },

  fullScreenPaper: {
    backgroundColor: ({ fullScreenBackgroundColor }) => fullScreenBackgroundColor,
  },
  fullScreenContent: {
    padding: '0px 64px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 24px',
    },
    overflowY: 'auto',
  },
  fullScreenHeader: {
    position: 'sticky',
    top: 0,
    margin: '0px -64px',
    padding: '24px 64px',
    zIndex: 1000,
    background: `
      linear-gradient(
        to bottom, 
        ${colors.grey10} 30%, 
        ${fade(colors.grey10, 0)} 100%
      )
    `,
    [theme.breakpoints.down('sm')]: {
      margin: '0px -20px',
      padding: '24px 20px',
    },
  },
  fullScreenArrowBackContainer: {
    display: 'flex',
    marginRight: '4px',
    transition: 'all 0.3s',
    transform: ({ isShowScrollToTop }) => (isShowScrollToTop ? 'rotate(90deg)' : ''),
  },
  fullScreenBackButton: {
    margin: '-8px -16px',
  },
  fullScreenCloseButton: {
    margin: '-12px',
  },
  fullScreenBackText: {
    fontSize: '20px',
    letterSpacing: '0.22px',
    color: colors.grey100,
  },
}));
