import { memo, useCallback, MouseEvent as ReactMouseEvent } from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import { TaskCardProps } from '../types';
import { FlagIcon } from 'assets';
import { Paper, StatusBadge } from 'components';

import { useStyles } from './styles';

export const TaskCardMobile = memo(({ task, onClick }: TaskCardProps) => {
  const styles = useStyles();

  const handleClick = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();

      if (onClick) {
        onClick(task);
      }
    },
    [task, onClick],
  );

  return (
    <Paper
      variant="elevation"
      elevation={24}
      className={clsx(styles.root, onClick && styles.clickable)}
      onClick={handleClick}
    >
      <div className={styles.taskIdSection}>
        <Typography variant="body1" color="primary">
          <u>#{task.id}</u>
        </Typography>

        <div className={styles.flagItem}>{task.flag.active ? <FlagIcon /> : <></>}</div>
      </div>

      <Typography variant="body1" className={styles.summary}>
        <b>{task.summary}</b>
      </Typography>

      <div className={styles.boardSwimlaneSection}>
        <Typography variant="body1" className={styles.swimlane}>
          {task.swimlane.name}
        </Typography>

        <StatusBadge text={task.board.name} size="small" customColor={task.board.color} />
      </div>

      <Typography variant="body1" className={styles.dueDateTime}>
        {moment(task.due.datetime).utc(true).format('M/D/YY h:mmA')}
      </Typography>
    </Paper>
  );
});
