import { createContext, useCallback, useMemo } from 'react';

import { OptionsObject, useSnackbar } from 'notistack';

import { Alert } from 'components/alert/Alert';

import { AlertContextDispatch, AlertContextControllerProps } from './AlertContextController.types';
import { HideAlert, ShowAlert } from './AlertContextControllerUtils.types';

export const AlertDispatchContext = createContext<AlertContextDispatch | undefined>(undefined);

export const AlertContextController = ({ children }: AlertContextControllerProps) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const hideAlert: HideAlert = useCallback(
    key => {
      closeSnackbar(key);
    },
    [closeSnackbar],
  );

  const showAlert: ShowAlert = useCallback(
    (message, options) => {
      const variant = options?.variant === 'default' ? 'info' : options?.variant;
      const newOptions: OptionsObject | undefined = options
        ? {
            autoHideDuration: 5000,
            content: (key, message) => (
              <Alert id={key.toString()} severity={variant} onClick={() => hideAlert(key)}>
                {message}
              </Alert>
            ),
            ...options,
          }
        : options;
      const key = enqueueSnackbar(message, {
        ...newOptions,
        // onClick: () => closeSnackbar(key)
      });

      return key;
    },
    [enqueueSnackbar, hideAlert],
  );

  const dispatch = useMemo<AlertContextDispatch>(
    () => ({
      showAlert,
      hideAlert,
    }),
    [hideAlert, showAlert],
  );

  return <AlertDispatchContext.Provider value={dispatch}>{children}</AlertDispatchContext.Provider>;
};
