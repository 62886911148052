import { FC, memo, useMemo } from 'react';

import { FormControl, MenuItem } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { Select } from 'components/select/Select';

import { FormSelectProps } from './FormItems.types';

export const FormSelect: FC<FormSelectProps> = memo(
  ({
    control,
    name,
    size = 'medium',
    variant = 'outlined',
    options,
    required,
    isAlphaSortOptions,
    placeholder,
    ...rest
  }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    return (
      <FormControl error={!!error} fullWidth>
        <Select {...rest} {...field} required={required} size={size} variant={variant} error={!!error}>
          {placeholder && (
            <MenuItem value={0} disabled style={{ display: 'none' }}>
              <span className="select-placeholder">{placeholder}</span>
            </MenuItem>
          )}
          {Array.isArray(options)
            ? options.map((option, index) =>
                typeof option === 'object' ? (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ) : (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ),
              )
            : isAlphaSortOptions
            ? Object.entries(options)
                .map(entry => ({
                  id: entry[0],
                  value: String(entry[1]),
                }))
                .sort((a, b) => a.value.localeCompare(b.value))
                .map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))
            : Object.keys(options).map(key => (
                <MenuItem key={key} value={key}>
                  {options[key]}
                </MenuItem>
              ))}
        </Select>

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
