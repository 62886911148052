import { FC, memo, useMemo } from 'react';

import { FormControl } from '@material-ui/core';
import moment from 'moment';
import { useController } from 'react-hook-form';

import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { ClockTimepicker } from 'components/pickers';
import { USADateTimeFormat } from 'utils';

import { FormClockTimepickerProps } from './FormItems.types';

export const FormClockTimepicker: FC<FormClockTimepickerProps> = memo(
  ({ control, className, name, required, ...rest }) => {
    const {
      field: { onChange, value },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: undefined,
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    return (
      <FormControl error={!!error} fullWidth className={className}>
        <ClockTimepicker
          {...rest}
          value={value}
          handleConfirm={time => onChange(moment(time as string).format(USADateTimeFormat))}
          error={!!error}
        />

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
