import { ComponentType, LazyExoticComponent, lazy } from "react";

type FactoryType = () => Promise<{ default: ComponentType<any> }>;

interface LazyComponentWithPreload
  extends LazyExoticComponent<ComponentType<any>> {
  preload: FactoryType;
}

export const lazyWithPreload = (
  factory: FactoryType,
): LazyComponentWithPreload => {
  const Component = lazy(factory) as LazyComponentWithPreload;

  Component.preload = factory;

  return Component;
};
