export const toolbarIcons = {
  bold: `
    <svg width="24px" height="24px" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6 10.79C16.57 10.12 17.25 9.02 17.25 8C17.25 5.74 15.5 4 13.25 4H7V18H14.04C16.13 18 17.75 16.3 17.75 14.21C17.75 12.69 16.89 11.39 15.6 10.79ZM10 6.5H13C13.83 6.5 14.5 7.17 14.5 8C14.5 8.83 13.83 9.5 13 9.5H10V6.5ZM13.5 15.5H10V12.5H13.5C14.33 12.5 15 13.17 15 14C15 14.83 14.33 15.5 13.5 15.5Z" fill="#6B7587"/>
    </svg>
  `,
  italic: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4V7H12.21L8.79 15H6V18H14V15H11.79L15.21 7H18V4H10Z" fill="#6B7587"/>
    </svg>
  `,
  strike: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 19H14V16H10V19ZM5 4V7H10V10H14V7H19V4H5ZM3 14H21V12H3V14Z" fill="#6B7587"/>
    </svg>
  `,
  underline: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 17C15.31 17 18 14.31 18 11V3H15.5V11C15.5 12.93 13.93 14.5 12 14.5C10.07 14.5 8.5 12.93 8.5 11V3H6V11C6 14.31 8.69 17 12 17ZM5 19V21H19V19H5Z" fill="#6B7587"/>
    </svg>
  `,
  erase: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 7.99999V4.99999H6.39L9.39 7.99999H11.22L10.67 9.27998L12.76 11.38L14.21 7.99999H20ZM3.41 4.85999L2 6.26999L8.97 13.24L6.5 19H9.5L11.07 15.34L16.73 21L18.14 19.59L3.41 4.85999Z" fill="#6B7587"/>
    </svg>
  `,
  align_justify: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3H21V5H3V3Z" fill="#6B7587"/>
      <path d="M3 7H21V9H3V7Z" fill="#6B7587"/>
      <path d="M3 11H21V13H3V11Z" fill="#6B7587"/>
      <path d="M3 15H21V17H3V15Z" fill="#6B7587"/>
      <path d="M3 19H12V21H3V19Z" fill="#6B7587"/>
    </svg>
  `,
  align_left: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 15H3V17H15V15ZM15 7H3V9H15V7ZM3 13H21V11H3V13ZM3 21H21V19H3V21ZM3 3V5H21V3H3Z" fill="#6B7587"/>
    </svg>
  `,
  align_right: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 21H21V19H3V21ZM9 17H21V15H9V17ZM3 13H21V11H3V13ZM9 9H21V7H9V9ZM3 3V5H21V3H3Z" fill="#6B7587"/>
    </svg>
  `,
  align_center: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 15V17H17V15H7ZM3 21H21V19H3V21ZM3 13H21V11H3V13ZM7 7V9H17V7H7ZM3 3V5H21V3H3Z" fill="#6B7587"/>
    </svg>
  `,
  list_bullets: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM7 19H21V17H7V19ZM7 13H21V11H7V13ZM7 5V7H21V5H7Z" fill="#6B7587"/>
    </svg>
  `,
  list_number: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 17H4V17.5H3V18.5H4V19H2V20H5V16H2V17ZM3 8H4V4H2V5H3V8ZM2 11H3.8L2 13.1V14H5V13H3.2L5 10.9V10H2V11ZM7 5V7H21V5H7ZM7 19H21V17H7V19ZM7 13H21V11H7V13Z" fill="#6B7587"/>
    </svg>
  `,
  blockquote: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.62 18H13.38L15.38 14H13V6H21V13.24L18.62 18ZM16.62 16H17.38L19 12.76V8H15V12H18.62L16.62 16ZM8.62 18H3.38L5.38 14H3V6H11V13.24L8.62 18ZM6.62 16H7.38L9 12.76V8H5V12H8.62L6.62 16Z" fill="#6B7587"/>
    </svg>
  `,
  font_color: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 20H22V24H2V20ZM5.49 17H7.91L9.18 13.42H14.83L16.09 17H18.51L13.25 3H10.75L5.49 17ZM9.91 11.39L11.94 5.6H12.06L14.09 11.39H9.91Z" fill="#6B7587"/>
    </svg>
  `,
  highlight_color: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.56 8.94L7.62 0L6.21 1.41L8.59 3.79L3.44 8.94C2.85 9.53 2.85 10.48 3.44 11.06L8.94 16.56C9.23 16.85 9.62 17 10 17C10.38 17 10.77 16.85 11.06 16.56L16.56 11.06C17.15 10.48 17.15 9.53 16.56 8.94ZM5.21 10L10 5.21L14.79 10H5.21ZM19 11.5C19 11.5 17 13.67 17 15C17 16.1 17.9 17 19 17C20.1 17 21 16.1 21 15C21 13.67 19 11.5 19 11.5ZM2 20H22V24H2V20Z" fill="#6B7587"/>
    </svg>
  `,
  undo: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.56415 4.5987C7.26131 6.25132 7.21638 6.28666 7.27782 6.39896C7.31163 6.46075 11.6192 9.57651 11.8031 9.67217C11.8717 9.70783 11.9016 9.70563 11.9737 9.65944L12.0612 9.60345V8.53268V7.46191H12.2497C13.0365 7.46195 14.2569 7.84655 15.0592 8.34727C18.1547 10.2795 18.6609 14.5105 16.1018 17.0624C12.8311 20.324 7.31931 18.7738 6.27055 14.2973C6.06673 13.4273 5.70628 13.0863 5.03343 13.127C4.19978 13.1774 3.84217 13.7896 4.06484 14.7851C5.18285 19.7838 10.7959 22.4397 15.4982 20.195C20.2823 17.911 21.5045 11.7483 17.9445 7.85806C16.5151 6.29607 14.2492 5.25124 12.2856 5.24872L12.0612 5.24845V4.1663C12.0612 3.01175 12.0593 2.99973 11.8833 3C11.8192 3.00009 11.1229 3.48004 9.56415 4.5987Z" fill="#6B7587"/>
    </svg>
  `,
  redo: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4359 4.5987C16.7387 6.25131 16.7836 6.28666 16.7222 6.39896C16.6884 6.46074 12.3808 9.57651 12.1969 9.67217C12.1283 9.70783 12.0984 9.70562 12.0263 9.65943L11.9388 9.60345V8.53268V7.4619H11.7503C10.9635 7.46195 9.74306 7.84655 8.94083 8.34726C5.84525 10.2795 5.33912 14.5105 7.89819 17.0624C11.1689 20.324 16.6807 18.7738 17.7294 14.2973C17.9333 13.4273 18.2937 13.0863 18.9666 13.127C19.8002 13.1774 20.1578 13.7896 19.9352 14.7851C18.8171 19.7838 13.2041 22.4397 8.50187 20.195C3.71763 17.911 2.49555 11.7483 6.05551 7.85806C7.4849 6.29606 9.75078 5.25123 11.7144 5.24871L11.9388 5.24844V4.16629C11.9388 3.01174 11.9407 2.99973 12.1167 3C12.1808 3.00009 12.8771 3.48003 14.4359 4.5987Z" fill="#6B7587"/>
    </svg>
  `,
  expansion: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.19502 4.05764C3.98503 4.18507 4.00024 3.96663 4.00024 7.00973V9.76156L4.10372 9.86472C4.22546 9.98608 4.31675 10.0073 4.76109 10.0073C5.16282 10.0043 5.29368 9.97395 5.41237 9.85865L5.50672 9.7646L5.51585 8.19602L5.52498 6.62744L7.70709 8.79979C9.7492 10.8326 9.89833 10.9751 10.014 10.9934C10.2057 11.0237 10.3123 10.96 10.6744 10.5929C10.994 10.2652 11 10.2561 11 10.1165C11 10.0377 10.9818 9.93754 10.9574 9.89203C10.9331 9.84652 9.95311 8.8544 8.78141 7.68631L6.64799 5.56251L8.17881 5.54734C9.87703 5.52914 9.80399 5.53824 9.93485 5.32586C9.98659 5.24091 9.99572 5.17113 9.99572 4.77367C9.99572 4.25486 9.96529 4.15473 9.77964 4.05461C9.68225 4.00304 9.53312 4 6.98276 4C4.38371 4 4.28632 4.00304 4.19502 4.05764Z" fill="#6B7587"/>
      <path d="M14.2205 4.05469C14.0348 4.15481 14.0044 4.25493 14.0044 4.77374C14.0044 5.17119 14.0135 5.24097 14.0652 5.32592C14.1961 5.5383 14.1231 5.52919 15.8213 5.5474L17.3522 5.56257L15.2187 7.68635C14.047 8.85442 13.067 9.84653 13.0426 9.89204C13.0183 9.93755 13 10.0377 13 10.1165C13 10.2562 13.0061 10.2652 13.3287 10.5929C13.6878 10.96 13.7943 11.0237 13.9861 10.9934C14.1018 10.9751 14.2509 10.8326 16.2931 8.79982L18.4752 6.62749L18.4844 8.19605L18.4935 9.76462L18.5878 9.85866C18.7065 9.97396 18.8374 10.0043 19.2391 10.0074C19.6834 10.0074 19.7748 9.98609 19.8965 9.86473L20 9.76158V7.00674C20 4.25796 20 4.25189 19.9361 4.16998C19.7961 3.99098 19.9391 4.00008 17.0113 4.00008C14.467 4.00008 14.3179 4.00311 14.2205 4.05469Z" fill="#6B7587"/>
      <path d="M9.95003 13.0261C9.88916 13.0444 9.15872 13.7511 7.69176 15.2132L5.52479 17.373L5.51566 15.8047L5.50653 14.2365L5.41218 14.1424C5.29348 14.0272 5.16261 13.9968 4.76087 13.9938C4.31652 13.9938 4.22522 14.015 4.10348 14.1364L4 14.2395V16.9938C4 19.7421 4 19.7481 4.06391 19.83C4.20391 20.009 4.06087 19.9999 6.98871 19.9999C9.53307 19.9999 9.68221 19.9968 9.7796 19.9453C9.96525 19.8452 9.99569 19.7451 9.99569 19.2264C9.99569 18.829 9.98656 18.7593 9.93481 18.6743C9.80394 18.462 9.87699 18.4711 8.17871 18.4528L6.64784 18.4377L8.78133 16.3144C9.95307 15.1465 10.9331 14.1546 10.9574 14.1091C10.9818 14.0636 11 13.9635 11 13.8846C11 13.7451 10.994 13.736 10.6744 13.4083C10.2757 13.008 10.1814 12.9564 9.95003 13.0261Z" fill="#6B7587"/>
      <path d="M13.7456 13.0426C13.6969 13.0668 13.5083 13.2335 13.3287 13.4152C13.0061 13.7426 13 13.7516 13 13.891C13 13.9698 13.0183 14.0698 13.0426 14.1153C13.067 14.1607 14.047 15.1516 15.2187 16.3182L17.3522 18.4393L15.8213 18.4545C14.1231 18.4727 14.1961 18.4636 14.0652 18.6757C14.0135 18.7606 14.0044 18.8303 14.0044 19.2272C14.0044 19.7454 14.0348 19.8453 14.2205 19.9454C14.3179 19.9968 14.467 19.9999 17.0113 19.9999C19.9391 19.9999 19.7961 20.009 19.9361 19.8302C20 19.7484 20 19.7424 20 16.997V14.2455L19.8965 14.1426C19.7748 14.0213 19.6834 14.0001 19.2391 14.0001C18.8374 14.0031 18.7065 14.0335 18.5878 14.1486L18.4935 14.2425L18.4783 15.803L18.4631 17.3636L16.3022 15.2122C14.1261 13.0426 14.0744 12.9971 13.8826 13.0001C13.8583 13.0032 13.7974 13.0214 13.7456 13.0426Z" fill="#6B7587"/>
    </svg>
  `,
  reduction: `
    <svg width="24" height="24" viewBox="0 0 24 24" fontSize="24px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.195 13.0576C12.985 13.1851 13.0002 12.9666 13.0002 16.0097V18.7616L13.1037 18.8647C13.2255 18.9861 13.3168 19.0073 13.7611 19.0073C14.1628 19.0043 14.2937 18.9739 14.4124 18.8587L14.5067 18.7646L14.5159 17.196L14.525 15.6274L16.7071 17.7998C18.7492 19.8326 18.8983 19.9751 19.014 19.9934C19.2057 20.0237 19.3123 19.96 19.6744 19.5929C19.994 19.2652 20 19.2561 20 19.1165C20 19.0377 19.9818 18.9375 19.9574 18.892C19.9331 18.8465 18.9531 17.8544 17.7814 16.6863L15.648 14.5625L17.1788 14.5473C18.877 14.5291 18.804 14.5382 18.9348 14.3259C18.9866 14.2409 18.9957 14.1711 18.9957 13.7737C18.9957 13.2549 18.9653 13.1547 18.7796 13.0546C18.6823 13.003 18.5331 13 15.9828 13C13.3837 13 13.2863 13.003 13.195 13.0576Z" fill="#6B7587"/>
      <path d="M5.22047 13.0547C5.0348 13.1548 5.00439 13.2549 5.00439 13.7737C5.00439 14.1712 5.0135 14.241 5.0652 14.3259C5.19608 14.5383 5.12308 14.5292 6.82132 14.5474L8.35217 14.5626L6.21868 16.6863C5.04699 17.8544 4.06699 18.8465 4.0426 18.892C4.01829 18.9375 4 19.0377 4 19.1165C4 19.2562 4.0061 19.2652 4.32867 19.5929C4.68783 19.96 4.79432 20.0237 4.9861 19.9934C5.10178 19.9751 5.25088 19.8326 7.29307 17.7998L9.47525 15.6275L9.48435 17.1961L9.49345 18.7646L9.58784 18.8587C9.70653 18.974 9.83741 19.0043 10.2391 19.0074C10.6834 19.0074 10.7748 18.9861 10.8965 18.8647L11 18.7616V16.0067C11 13.258 11 13.2519 10.9361 13.17C10.7961 12.991 10.9391 13.0001 8.0113 13.0001C5.46695 13.0001 5.31786 13.0031 5.22047 13.0547Z" fill="#6B7587"/>
      <path d="M18.95 4.02612C18.8892 4.04435 18.1587 4.75113 16.6918 6.21322L14.5248 8.37302L14.5157 6.8047L14.5065 5.23647L14.4122 5.1424C14.2935 5.02719 14.1626 4.9968 13.7609 4.9938C13.3165 4.9938 13.2252 5.01503 13.1035 5.13641L13 5.23947V7.99382C13 10.7421 13 10.7481 13.0639 10.83C13.2039 11.009 13.0609 10.9999 15.9887 10.9999C18.5331 10.9999 18.6822 10.9968 18.7796 10.9453C18.9652 10.8452 18.9957 10.7451 18.9957 10.2264C18.9957 9.82903 18.9866 9.75927 18.9348 9.67427C18.8039 9.46199 18.877 9.47107 17.1787 9.45283L15.6478 9.43768L17.7813 7.31435C18.9531 6.14646 19.9331 5.15455 19.9574 5.1091C19.9818 5.06356 20 4.9635 20 4.88458C20 4.74506 19.994 4.73598 19.6744 4.40832C19.2757 4.00798 19.1814 3.95636 18.95 4.02612Z" fill="#6B7587"/>
      <path d="M4.74563 4.0426C4.69693 4.0668 4.50825 4.23345 4.32867 4.41524C4.0061 4.74257 4 4.75163 4 4.89101C4 4.96985 4.01829 5.0698 4.0426 5.11529C4.06699 5.1607 5.04699 6.15156 6.21868 7.31822L8.35217 9.43933L6.82132 9.45446C5.12308 9.47267 5.19608 9.46361 5.0652 9.67567C5.0135 9.76058 5.00439 9.83027 5.00439 10.2272C5.00439 10.7454 5.0348 10.8453 5.22047 10.9454C5.31786 10.9968 5.46695 10.9999 8.0113 10.9999C10.9391 10.9999 10.7961 11.009 10.9361 10.8302C11 10.7484 11 10.7424 11 7.99698V5.24552L10.8965 5.14257C10.7748 5.02132 10.6834 5.00012 10.2391 5.00012C9.83741 5.00311 9.70653 5.03346 9.58784 5.14856L9.49345 5.24253L9.47825 6.80304L9.46305 8.36364L7.30217 6.21219C5.12609 4.0426 5.07439 3.99711 4.88261 4.00011C4.85831 4.00318 4.79742 4.0214 4.74563 4.0426Z" fill="#6B7587"/>
    </svg>
  `,
  image: `
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 14H14C14.2 14 14.35 13.9083 14.45 13.725C14.55 13.5417 14.5333 13.3667 14.4 13.2L11.65 9.525C11.55 9.39167 11.4167 9.325 11.25 9.325C11.0833 9.325 10.95 9.39167 10.85 9.525L8.25 13L6.4 10.525C6.3 10.3917 6.16667 10.325 6 10.325C5.83334 10.325 5.7 10.3917 5.6 10.525L3.6 13.2C3.46667 13.3667 3.45 13.5417 3.55 13.725C3.65 13.9083 3.8 14 4 14ZM2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H16C16.55 1.69779e-06 17.021 0.196002 17.413 0.588002C17.805 0.980002 18.0007 1.45067 18 2V16C18 16.55 17.804 17.021 17.412 17.413C17.02 17.805 16.5493 18.0007 16 18H2ZM2 16H16V2H2V16Z" fill="#6B7587"/>
    </svg>
  `,
};
