import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { useStyles } from './ExpandMoreIcon.styles';

export const ExpandMoreIcon = ({
  isOpen,
  classes,
}: {
  isOpen: boolean;
  classes?: { showMoreIcon: string; showLessIcon: string };
}) => {
  const styles = useStyles();

  return (
    <ExpandMore
      fontSize="small"
      className={clsx(
        classes?.showMoreIcon || styles.showMoreIcon,
        (isOpen && classes?.showLessIcon) || (isOpen && styles.showLessIcon),
      )}
    />
  );
};
