import { createEffect, createStore } from "effector";

import { getAppSettings } from "../api";
import { AppSettings } from "../types";

export const $appSettings = createStore<{
  appSettings: AppSettings | null;
  loading: boolean;
}>({
  appSettings: null,
  loading: true,
});

export const getAppSettingsFx = createEffect(getAppSettings);

$appSettings
  .on(getAppSettingsFx.doneData, (store, response) => ({
    ...store,
    appSettings: response.data.app[0] ?? null,
  }))
  .on(getAppSettingsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));
