import { Badge, Box, Avatar as MuiAvatar, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode, memo } from "react";

import { useStyles } from "./styles";

export const Avatar = memo(
  ({
    imageUrl,
    icon,
    avatarApproved,
    isMobile,
    className,
  }: {
    imageUrl?: string;
    icon?: ReactNode;
    avatarApproved?: string;
    isMobile?: boolean;
    className?: string;
  }) => {
    const styles = useStyles();

    return (
      <>
        <Badge
          badgeContent={
            icon ? (
              <div className={clsx(styles.avatarBadge, className)}>{icon}</div>
            ) : (
              <></>
            )
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MuiAvatar
            src={imageUrl}
            classes={{
              root: isMobile ? styles.avatarMobile : styles.avatarDesktop,
            }}
          />
        </Badge>
        {avatarApproved && (
          <Typography variant="h5" className={styles.avatarApproved}>
            {avatarApproved}
          </Typography>
        )}
      </>
    );
  },
);
