import { FocusEventHandler, Ref } from 'react';

import { InputMask, InputVariant } from '../input/Input.types';

export enum InputUserDecision {
  Untouched = 'untouched',
  Confirmed = 'confirmed',
  Rejected = 'rejected',
}

export type ConfirmationInputTypes = {
  hideConfirmationAdortment?: boolean;
  onConfirmation: (isConfirmed: boolean) => void;

  // Input properties
  className?: string;
  onChange: (value: string) => void;
  value: string | number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  label?: string;
  placeholder?: string;
  mask?: InputMask;
  variant?: InputVariant;
  name?: string;
  error?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  size?: 'small' | 'medium' | 'large';
  checkAdornment?: boolean;
};
