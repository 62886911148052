import { makeStyles } from '@material-ui/core';

export const useTaskViewStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 740,
    maxWidth: '100%',
    overflowY: 'auto',
  },

  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  bottomBar: {
    position: 'sticky',
    bottom: 0,
    zIndex: 5,
    boxShadow: '-1px 0px 10px 5px rgba(0, 0, 0, 0.05)',
    padding: 16,
    backdropFilter: 'blur(8px)',
  },

  scrollNavTitle: {
    textWrap: 'nowrap',
  },
}));
