type Dictionary<T> = Record<string, T>;

export const omitBy = <T>(obj: Dictionary<T>, predicate: (value: T, key: string) => boolean): Dictionary<T> => {
  const result: Dictionary<T> = {};

  Object.keys(obj).forEach(key => {
    if (!predicate(obj[key], key)) {
      result[key] = obj[key];
    }
  });

  return result;
};
