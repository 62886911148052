import { FC, memo, useMemo } from 'react';

import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import { Button } from '../../button/Button';
import { CustomerInfo } from '../../customerInfo/CustomerInfo';
import { Popover } from '../../popover/Popover';
import { Tooltip } from '../../tooltip/Tooltip';
import { KebabMenu, Shortcuts } from '../actionMenus';
import { useStyles } from '../GeneralTopbar.styles';
import { GeneralTopbarProps } from '../GeneralTopbar.types';
import { useEmailStyles } from '../hooks/useEmailStyles';
import { useSchedulesDialog } from '../hooks/useSchedulesDialog';
import { LocationSelect } from '../locationSelect/LocationSelect';
import { TooltipSelect } from '../tooltipSelect/TooltipSelect';
import { CalendarFlipIcon, CalendarIcon, ClockIcon, colors, MailIcon, PencilIcon, PhoneIcon, EyeIcon } from 'assets';
import { applyPhoneMask } from 'utils';

export const GeneralTopbarDesktop: FC<GeneralTopbarProps> = memo(
  ({
    account,
    category,
    categoryDisabled,
    categories,
    handleKebabMenuMenuItemClick,
    handleShortcutMenuMenuItemClick,
    jobId,
    kebabMenuItems,
    location,
    locationOptions,
    jobTypeDisabled,
    jobTypeTooltip,
    jobType,
    jobTypes,
    onChangeJobType,
    onChangeCategory,
    onChangeStatus,
    onReschedule,
    onSetSchedule,
    rescheduleDisabled,
    scheduleDate,
    schedules,
    shortcuts,
    status,
    statusDisabled,
    statuses,
    convertedAppointmentId,
  }) => {
    const styles = useStyles();
    const isSchedulesEmpty = useMemo(() => !Boolean(schedules.length), [schedules]);

    const { handleSchedulesDialogOpen } = useSchedulesDialog();
    const {
      isAbbreviatedEmailDisplays,
      accountInfoRef,
      abbreviatedEmailWidth,
      abbreviatedEmailRef,
      fullEmailRef,
      phoneRef,
      phoneWidth,
    } = useEmailStyles(account.email);

    return (
      <Box className={styles.desktopRoot}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item className={styles.infoBlock}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item className={styles.appointmentInfo}>
                <Box className={styles.idTypeContainer}>
                  <Grid container justify="space-between" alignItems="center" spacing={1}>
                    <Grid item>
                      <Box width={146}>
                        <Typography variant="body1" color="inherit">
                          ID: {jobId}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box mx={-0.5}>
                        <Tooltip
                          isHover
                          isArrow
                          interactive
                          placement="right"
                          disableHoverListener={!!!jobTypeTooltip?.isShow}
                          title={
                            <Typography variant="body1" style={{ fontWeight: 'bold', whiteSpace: 'pre-line' }}>
                              {jobTypeTooltip?.tooltipTitle || ''}
                            </Typography>
                          }
                        >
                          <Box>
                            <TooltipSelect
                              disabled={jobTypeDisabled}
                              selected={jobType}
                              list={jobTypes}
                              onChange={onChangeJobType}
                              typographyVariant="inherit"
                              classes={{
                                toggleWrapper: styles.jobTypesTooltip,
                                title: styles.jobTypesTooltipTitle,
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Box mx={-0.5}>
                      <TooltipSelect
                        selected={category}
                        list={categories}
                        onChange={onChangeCategory}
                        disabled={categoryDisabled}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={styles.statusContainer}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item>
                      <Box className={styles.statusTooltipContainer}>
                        <TooltipSelect
                          selected={status}
                          list={statuses}
                          onChange={onChangeStatus}
                          toggleVariant="outlined"
                          disabled={statusDisabled}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item className={styles.accountAndDateInfo}>
                <Grid container spacing={3} wrap="nowrap">
                  <Grid
                    item
                    className={clsx(styles.accountInfo, 'accountInfo')}
                    innerRef={accountInfoRef}
                    style={{
                      width: `${
                        abbreviatedEmailWidth > 230 || phoneWidth > 230
                          ? abbreviatedEmailWidth > phoneWidth
                            ? abbreviatedEmailWidth
                            : phoneWidth
                          : 230
                      }px`,
                    }}
                  >
                    <Box className={styles.accountInfoNameContainer}>
                      <Popover
                        variant="hover"
                        toggle={
                          <a
                            href={`https://support-testing.chhj.com/hunkware/bookingflow/clientview?id=${account.id}&loc_id=${location.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.link}
                          >
                            <Typography variant="h4" className={styles.accountInfoName}>
                              {account.name}
                            </Typography>
                          </a>
                        }
                      >
                        <CustomerInfo
                          name={account.name}
                          id={account.id}
                          clientType={account.clientType}
                          email={account.email}
                          phone={account.phone}
                          address={account.address}
                          referralSource={account.referralSource}
                          franchisee={account.franchise}
                        />
                      </Popover>
                    </Box>

                    {account.phone && (
                      <Box className={styles.accountInfoItem}>
                        <a
                          href={`tel:+1${account.phone}`}
                          className={clsx(styles.link, styles.orangeHoverEffect, styles.orangeHoverEffectPhone)}
                        >
                          <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            wrap="nowrap"
                            className={clsx(styles.muiGridContVertPaddingNone, 'MuiSpacingWidthAuto')}
                          >
                            <Grid item>
                              <PhoneIcon className={clsx(styles.phoneIcon, styles.icon)} color={colors.grey100} />
                            </Grid>
                            <Grid item>
                              <Typography innerRef={phoneRef} noWrap variant="body1">
                                {applyPhoneMask(account.phone)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </a>
                      </Box>
                    )}

                    {account.email && (
                      <Box className={styles.accountInfoItem}>
                        <a
                          href={`mailto:${account.email}`}
                          className={clsx(styles.link, styles.orangeHoverEffect, styles.orangeHoverEffectEmail)}
                        >
                          <Tooltip
                            isClick={!isAbbreviatedEmailDisplays}
                            isArrow
                            interactive
                            title={<Typography variant="body1">{account.email}</Typography>}
                          >
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              wrap="nowrap"
                              className={clsx(styles.muiGridContVertPaddingNone, 'MuiSpacingWidthAuto')}
                            >
                              <Grid item>
                                <MailIcon className={clsx(styles.mailIcon, styles.icon)} color={colors.grey100} />
                              </Grid>
                              <Grid item className={styles.accountEmailContainer}>
                                <Grid
                                  container
                                  innerRef={abbreviatedEmailRef}
                                  wrap="nowrap"
                                  style={{ display: isAbbreviatedEmailDisplays ? 'flex' : 'none' }}
                                >
                                  <Typography variant="body1">{account.email.split('@')[0].slice(0, 3)}...</Typography>
                                  <Typography variant="body1">{account.email.split('@')[0].slice(-3)}</Typography>
                                  <Typography variant="body1">@{account.email.split('@')[1]}</Typography>
                                </Grid>
                                <Grid
                                  innerRef={fullEmailRef}
                                  style={{ display: !isAbbreviatedEmailDisplays ? 'block' : 'none' }}
                                >
                                  <Typography variant="body1">{account.email}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Tooltip>
                        </a>
                      </Box>
                    )}
                  </Grid>

                  <Grid item className={styles.dateInfo}>
                    <Grid container alignItems="center" spacing={1} wrap="nowrap" className="MuiSpacingWidthAuto">
                      <Grid item>
                        <CalendarIcon className={clsx(styles.calendarIcon, styles.icon)} color={colors.grey100} />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{moment(scheduleDate).format('MMM DD, YYYY')}</Typography>
                      </Grid>

                      {onReschedule && !rescheduleDisabled && (
                        <Grid item>
                          <IconButton className={styles.iconButtonMediumMargin} onClick={onReschedule}>
                            <PencilIcon className={styles.pencilIcon} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>

                    <Box mt={1}>
                      <Grid
                        container
                        spacing={1}
                        alignItems={schedules.length < 2 ? 'center' : 'flex-start'}
                        wrap="nowrap"
                        className="MuiSpacingWidthAuto"
                      >
                        <Grid item className={isSchedulesEmpty ? styles.alignSelf : ''}>
                          <ClockIcon
                            className={clsx(
                              styles.clockIcon,
                              styles.icon,
                              schedules.length > 1 && styles.clockIconMargin,
                            )}
                            color={colors.grey100}
                          />
                        </Grid>

                        <Grid item className={styles.timeContainer}>
                          {schedules.length ? (
                            schedules.slice(0, 3).map((schedule, i) => (
                              <Typography key={i} variant="body1">
                                {moment(schedule.startDate).format('h:mma')} -{' '}
                                {moment(schedule.endDate).format('h:mma')}
                              </Typography>
                            ))
                          ) : (
                            <Button
                              buttonType="text"
                              size="small"
                              color="primary"
                              disabled={rescheduleDisabled}
                              onClick={onSetSchedule}
                              className={styles.setScheduleButton}
                            >
                              Set Schedule
                            </Button>
                          )}
                        </Grid>

                        {schedules.length > 2 && (
                          <Grid item className={isSchedulesEmpty ? styles.alignSelf : ''}>
                            <IconButton
                              className={styles.eyeIconButton}
                              onClick={() => {
                                handleSchedulesDialogOpen(schedules);
                              }}
                              edge="end"
                            >
                              <EyeIcon fontSize={20} />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item className={styles.franchiseInfo}>
                    {!!locationOptions.length && (
                      <LocationSelect location={location} locationOptions={locationOptions} />
                    )}

                    {convertedAppointmentId ? (
                      <Grid container spacing={1} wrap="nowrap" alignItems="center">
                        <Grid item>
                          <Box display="flex" alignItems="center">
                            <CalendarFlipIcon />
                          </Box>
                        </Grid>

                        <Grid item>
                          <Typography variant="body1">
                            Job:
                            <a
                              href={`/appointments/${convertedAppointmentId}`}
                              target="_blank"
                              className={styles.convertedJobLink}
                              rel="noreferrer"
                            >
                              #{convertedAppointmentId}
                            </a>
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <Shortcuts shortcuts={shortcuts} handleShortcutMenuMenuItemClick={handleShortcutMenuMenuItemClick} />
              </Grid>
              <Grid item>
                <KebabMenu
                  kebabMenuItems={kebabMenuItems}
                  handleKebabMenuMenuItemClick={handleKebabMenuMenuItemClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  },
);
