import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(theme => ({
  container: {
    height: '290px',
  },
  title: {
    fontWeight: 'bold',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& span': {
      padding: '0 48px',
    },
  },
  arrow: {
    color: colors.primary.main,
    cursor: 'pointer',
  },
  timeContainer: {
    overflow: 'auto',
  },
  alwaysShowScrollbar: {
    '& *::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '& *::-webkit-scrollbar:vertical': {
      width: 11,
    },
    '& *::-webkit-scrollbar:horizontal': {
      height: 11,
    },
    '& *::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white' /* can't be transparent */,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      height: '20px !important',
      maxHeight: 20,
      minHeight: 20,
    },
    '& *::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
    '& .track-horizontal': {
      opacity: '1 !important',
    },
    '& .track-vertical': {
      opacity: '1 !important',
    },
  },
  timeButton: {
    padding: '2px',
    width: 185,
    margin: '0 auto',

    '&:not(:last-child)': {
      marginBottom: 11,
    },
    '& span div': {
      fontSize: 18,
      lineHeight: '26px',
      padding: '9px 10px',
    },
  },
  selected: {
    '& button div': {
      backgroundColor: colors.primary.main,
      color: colors.basic.white,
    },
  },
  noAvailability: {
    color: colors.grey60,
    textAlign: 'center',
  },
}));
