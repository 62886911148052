import { FC, memo, useCallback, useState } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';

import { VisibilityIcon, VisibilityOffIcon } from 'assets';

import { FormInput } from './FormInput';
import { FormInputProps } from './FormItems.types';

export const FormPasswordInput: FC<FormInputProps> = memo(
  ({ control, name, placeholder, type = 'password', ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword, setShowPassword]);

    return (
      <FormInput
        {...props}
        name={name}
        placeholder={placeholder}
        type={showPassword ? 'text' : type}
        control={control}
        endAdornment={
          <InputAdornment position="end" style={{ marginTop: -16 }}>
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={handleClickShowPassword}
              size="small"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        }
        fullWidth
      />
    );
  },
);
