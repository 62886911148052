import React, { memo } from 'react';

import { Grid, Avatar, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { colors } from '../../assets';
import { getComplementaryColorByString } from 'utils';

import { useStyles } from './UserIdentifier.styles';
import { UserIdentifierProps } from './UserIdentifier.types';

export const stringAvatar = (
  firstName: string,
  imageUrl?: string,
): { style: Record<string, unknown>; children: React.ReactNode } => {
  return {
    style: {
      backgroundColor: getComplementaryColorByString(firstName),
    },
    children: !imageUrl && <Typography variant="h5">{firstName ? firstName[0].toUpperCase() : ''}</Typography>,
  };
};

export const UserIdentifier = memo(
  ({
    name,
    imageUrl,
    size = 'large',
    isDisplayName = true,
    isUnderlineName = false,
    className,
    classes,
    color,
  }: UserIdentifierProps) => {
    const styles = useStyles();

    return !name || name.length <= 1 ? (
      <div style={{ color: colors.grey140 }}>
        <Typography>Unassigned</Typography>
      </div>
    ) : (
      <Grid
        container
        spacing={1}
        className={clsx(styles.container, 'MuiSpacingWidthAuto', className, classes?.rootContainer)}
        wrap="nowrap"
      >
        <Grid item className={classes?.avatarContainer}>
          <Avatar
            alt={name}
            {...stringAvatar(name.split(' ')[0] || name, imageUrl)}
            src={imageUrl}
            className={clsx(
              styles.avatar,
              classes?.avatar,
              size === 'small' && 'small',
              size === 'medium' && 'medium',
              size === 'large' && 'large',
            )}
          />
        </Grid>
        {isDisplayName && (
          <Grid item className={classes?.usernameContainer}>
            <div style={{ color: color }}>
              <Typography
                variant="body1"
                color="inherit"
                className={clsx(
                  styles.name,
                  size === 'small' && 'small',
                  size === 'medium' && 'medium',
                  size === 'large' && 'large',
                  isUnderlineName && styles.underlineName,
                  classes?.username,
                )}
              >
                {name}
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    );
  },
);
