import { Theme, makeStyles } from '@material-ui/core';

import { colors } from 'assets';

type Props = {
  cellCount: number;
};

export const useAccountListStyles = makeStyles<Theme, Props>(theme => ({
  list: {
    margin: '16px 0',
    padding: 0,
    listStyle: 'none',
  },

  item: {
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    gap: 16,

    '&:nth-child(even)': {
      backgroundColor: colors.grey10,
    },
  },

  row: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    maxWidth: ({ cellCount }) => `calc(100% - ${cellCount > 3 ? 80 : 114}px)`,

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 40px)',
      flexWrap: 'wrap',
    },
  },

  col: {
    width: ({ cellCount }) => `calc(100% / ${cellCount})`,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 18,

    [theme.breakpoints.down('xs')]: {
      width: 'initial',
    },
  },

  name: {
    [theme.breakpoints.down('xs')]: {
      width: 'initial',
      maxWidth: '35%',
    },
  },

  address: {
    maxWidth: ({ cellCount }) => (cellCount > 3 ? 'calc(50% - 140px)' : '40%'),
    width: '100% !important',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(65% - 16px) !important',
    },
  },

  phone: {
    width: '116px !important',
  },

  email: {
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      maxWidth: ({ cellCount }) => (cellCount > 3 ? 'calc(100% - 140px)' : '100%'),
    },
  },
}));
