import { createApi, createStore, createEffect } from "effector";

import { login } from "../api";
import { generateAccessToken } from "shared/utils";
import { LogginedUser } from "../types";

import { getUserFx } from "./authorized-user";

export const loginUserFx = createEffect(login);

interface Auth {
  token: string | null;
  locationId: number | null;
  isAuthorized: boolean;
  isAuthorizing: boolean;
  isAuthorizationFailed: boolean;
}

const authInitialValues: Auth = {
  token: null,
  locationId: null,
  isAuthorized: false,
  isAuthorizing: true,
  isAuthorizationFailed: false,
};

export const $auth = createStore<Auth>(authInitialValues);

export const authApi = createApi($auth, {
  setToken: (
    authState,
    { token, locationId }: { token: string; locationId: number },
  ) => ({
    ...authState,
    token,
    locationId,
    isAuthorized: true,
  }),

  deleteToken: () => ({
    ...authInitialValues,
    isAuthorizing: false,
  }),

  setAuthorizing: (authState, isAuthorizing: boolean) => ({
    ...authState,
    isAuthorizing,
  }),

  setAuthorizationFailed: (authState, isAuthorizationFailed: boolean) => ({
    ...authState,
    isAuthorizationFailed,
  }),
});

$auth
  .on(loginUserFx.doneData, (_, response) => {
    const user = response.data.user as LogginedUser;

    authApi.setToken({
      token: generateAccessToken(user.id, user.password),
      locationId: user.location.id,
    });
  })
  .on(loginUserFx.pending, (store, loading) => ({
    ...store,
    isAuthorizing: loading,
  }));

$auth
  .on(getUserFx.doneData, (state, response) => ({
    ...state,
    locationId: response.data.users[0].location.id,
  }))
  .on(getUserFx.pending, (store, loading) => ({
    ...store,
    isAuthorizing: loading,
  }));
