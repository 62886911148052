import { ColorPartial, PaletteOptions, TypeBackground, PaletteColor } from '@material-ui/core/styles/createPalette';

export const colors = {
  white: '#ffffff',
  grey0: '#ffffff',
  grey10: '#F6F7F9',
  grey20: '#ECEFF3',
  grey30: '#E3E7ED',
  grey40: '#DADFE8',
  grey60: '#C6CEDB',
  grey80: '#B3BDCE',
  grey100: '#A0ADC2',
  grey120: '#8691A6',
  grey140: '#6B7587',
  grey160: '#505868',
  grey170: '#404857',
  grey180: '#323847',
  grey190: '#0E0F16',
  black: '#0E0F16',

  primary: {
    main: '#EA7200',
    light: '#F0CBA7',
    superlight: '#FDF2E8',
    dark: '#BF6006',
  },

  secondary: {
    main: '#226937',
    light: '#E9F0EB',
    dark: '#1F5932',
  },

  basic: {
    white: '#FFFFFF',
    black: '#4A4A4A',
  },

  functionals: {
    alert: '#E9554E',
    alertLight: '#FCEBEA',

    disabled: '#6B7587',
    disabledLight: '#DADFE8',

    info: '#6798F8',
    infoLight: '#ECF2FE',
    infoDark: '#6798f8',

    success: '#69D4A1',
    successMedium: '#C9F0DD',
    successLight: '#EBF9F3',

    warning: '#E86825',
    warningLight: '#FFF1E0',
  },

  complementary: {
    yellow: {
      shade01: '#E5B764',
      shade02: '#FFCB6F',
    },
    red: {
      shade01: '#E57977',
      shade02: '#FF8684',
      shade03: '#FFDBDA',
      shade04: '#FFE7E6',
    },
    green: {
      shade01: '#51C9AF',
      shade02: '#5ADFC2',
    },
    blue: {
      shade01: '#5A8BE5',
      shade02: '#649AFF',
      shade03: '#D7E3F1',
      shade04: '#F3F8FF',
    },
  },
};

const primary: PaletteColor = {
  main: colors.primary.main,
  light: colors.primary.light,
  dark: colors.primary.dark,
  contrastText: colors.basic.white,
};

const secondary: PaletteColor = {
  main: colors.secondary.main,
  light: colors.secondary.light,
  dark: colors.secondary.dark,
  contrastText: colors.basic.white,
};

const grey: ColorPartial = {
  100: colors.grey10,
  200: colors.grey20,
  300: colors.grey30,
  400: colors.grey40,
  600: colors.grey60,
  800: colors.grey80,
  900: colors.grey100,
};

const background: Partial<TypeBackground> = { default: colors.basic.white };

export const palette: PaletteOptions = {
  type: 'light',
  tonalOffset: 0.2,
  contrastThreshold: 3,
  primary: primary,
  secondary: secondary,
  grey: grey,

  text: {
    primary: colors.basic.black,
    secondary: colors.basic.black,
    disabled: colors.grey60,
  },

  common: {
    black: colors.basic.black,
    white: colors.basic.white,
  },

  background: background,

  action: {
    disabled: colors.grey60,
    disabledBackground: colors.grey60,
  },

  error: {
    main: colors.functionals.alert,
  },

  success: {
    main: colors.functionals.success,
  },

  warning: {
    main: colors.functionals.warning,
  },
};
