import { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { matchPath, NavigateOptions, useLocation } from "react-router-dom";
import { useStore } from "effector-react";

import { loginRoutePaths } from "shared/utils";
import { $location, useAuth } from "features/auth";
import { $permissionsStore } from "features/permissions";

import { SideNavDispatchContext, SideNavStateContext } from "./model";
import {
  HeaderActionButton,
  SideNavDispatchContextValue,
  SideNavStateContextValue,
} from "./model/types";

export const SideNavController = memo(({ children }) => {
  const { pathname } = useLocation();

  const { loading: locationLoading } = useStore($location);
  const { loading: permissionsLoading } = useStore($permissionsStore);

  const { isAuthorized, isAuthorizing } = useAuth();

  const [showGoBackButton, setShowGoBackButton] = useState(false);
  const [goBackToUrl, setGoBackUrl] = useState<string>("");
  const [goBackAction, setGoBackAction] = useState<() => void>();
  const [goBackOptions, setGoBackOptions] = useState<NavigateOptions>();
  const [pageName, setPageName] = useState("");
  const [actionButton, setActionButton] = useState<HeaderActionButton | null>(
    null,
  );
  const [pageNameAddition, setPageNameAddition] = useState<ReactNode | null>(
    null,
  );
  const [elevation, setElevation] = useState(0);

  const isLoginPage = useMemo(
    () => !!loginRoutePaths.find((path) => matchPath(path, pathname)),
    [pathname],
  );

  const showSideNav = useMemo(
    () =>
      !isLoginPage &&
      isAuthorized &&
      !isAuthorizing &&
      !permissionsLoading &&
      !locationLoading,
    [
      isLoginPage,
      isAuthorized,
      isAuthorizing,
      permissionsLoading,
      locationLoading,
    ],
  );

  const handleSetPageName = useCallback((name) => {
    setPageName(name);
  }, []);

  const handleSetActionButton = useCallback((actionButtonProps) => {
    setActionButton(actionButtonProps);
  }, []);

  const handleShowGoBackButton = useCallback((value?: boolean) => {
    if (typeof value === "boolean") {
      setShowGoBackButton(value);
      return;
    }

    setShowGoBackButton((prevState) => !prevState);
  }, []);

  const handleSetGoBackUrl = useCallback((url: string) => {
    setGoBackUrl(url);
  }, []);

  const handleSetGoToBackAction = useCallback((action?: () => void) => {
    setGoBackAction(() => action);
  }, []);

  const handleSetGoBackOptions = useCallback((options?: NavigateOptions) => {
    setGoBackOptions(options);
  }, []);

  const handleSetPageNameAddition = useCallback((content) => {
    setPageNameAddition(content);
  }, []);

  const handleClearActionButton = useCallback(() => {
    setActionButton(null);
  }, []);

  const handleSetElevation = useCallback((value: number) => {
    setElevation(value);
  }, []);

  const state: SideNavStateContextValue = useMemo(
    () => ({
      showSideNav,
      showGoBackButton,
      goBackToUrl,
      goBackAction,
      goBackOptions,
      pageName,
      actionButton,
      pageNameAddition,
      elevation,
    }),
    [
      showSideNav,
      goBackToUrl,
      goBackAction,
      goBackOptions,
      showGoBackButton,
      pageName,
      actionButton,
      pageNameAddition,
      elevation,
    ],
  );

  const dispatchValues: SideNavDispatchContextValue = useMemo(() => {
    return {
      setPageName: handleSetPageName,
      setActionButton: handleSetActionButton,
      setShowGoBackButton: handleShowGoBackButton,
      setGoToBackUrl: handleSetGoBackUrl,
      setGoToBackAction: handleSetGoToBackAction,
      setGoToBackOptions: handleSetGoBackOptions,
      clearActionButton: handleClearActionButton,
      setPageNameAddition: handleSetPageNameAddition,
      setElevation: handleSetElevation,
    };
  }, [
    handleSetPageName,
    handleSetActionButton,
    handleShowGoBackButton,
    handleSetGoBackUrl,
    handleSetGoToBackAction,
    handleSetGoBackOptions,
    handleClearActionButton,
    handleSetPageNameAddition,
    handleSetElevation,
  ]);

  return (
    <SideNavStateContext.Provider value={state}>
      <SideNavDispatchContext.Provider value={dispatchValues}>
        {children}
      </SideNavDispatchContext.Provider>
    </SideNavStateContext.Provider>
  );
});
