import React, { createContext, useState, Dispatch, SetStateAction } from 'react';

import { MessagesContainer } from 'components/message/messageContainer/MessagesContainer';

import { MessagesContextControllerProps, MessagesContextStateType } from './MessagesContextController.types';

export const MessagesContextState = createContext<MessagesContextStateType | undefined>(undefined);
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const MessagesContextDispatch = createContext<Dispatch<SetStateAction<MessagesContextStateType>>>(() => {});

export const MessagesContextController = ({ children }: MessagesContextControllerProps) => {
  const [messages, setMessages] = useState<MessagesContextStateType>([]);

  const handleCloseMessage = (id: string | number) => {
    const currentMessages = messages.slice();
    const filteredMessages = currentMessages.filter(message => message.id !== id);
    setMessages(filteredMessages);
  };

  return (
    <MessagesContextState.Provider value={messages}>
      <MessagesContextDispatch.Provider value={setMessages}>
        <MessagesContainer messages={messages} onClose={handleCloseMessage} />
        {children}
      </MessagesContextDispatch.Provider>
    </MessagesContextState.Provider>
  );
};
