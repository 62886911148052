import { withStyles, createStyles, Theme, List, makeStyles } from '@material-ui/core';

export const ListStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px',
    },
  }),
)(List);

export const useStyles = makeStyles((theme: Theme) => ({
  parentList: {
    margin: '-8px',
  },
}));
