import { makeStyles, withStyles, Slider } from '@material-ui/core';

import { colors } from '../../assets';

export const useStyles = makeStyles({
  playButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButton: {
    fontSize: '40px',
    padding: 0,
  },
  durationContainer: {
    marginTop: '21px',
  },
  timeContainer: {
    marginTop: '17px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
  },
});

export const CustomSlider = withStyles({
  root: {
    padding: 0,
    color: colors.primary.main,
    height: 4,
  },
  thumb: {
    height: 10,
    width: 10,
    backgroundColor: '#fff',
    border: `2px solid ${colors.primary.main}`,
    marginTop: -3,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  track: {
    height: 4,
    borderRadius: 5,
  },
  rail: {
    height: 4,
    borderRadius: 5,
    backgroundColor: colors.grey20,
  },
})(Slider);
