import React, { createElement } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import { DownloadIcon, EyeIcon, TrashIcon } from '../../assets';
import { Paper } from '../paper/Paper';
import { getFileIcon } from 'utils';

import { useStyles } from './AttachmentCard.styles';
import { AttachmentCardProps } from './AttachmentCard.types';

export const AttachmentCard: React.FC<AttachmentCardProps> = ({
  author,
  createdDate,
  description,
  fileName,
  undefinedCreatedDataText = 'Recently',
  classes,
  onView,
  onDownload,
  onDelete,
  ...props
}) => {
  const styles = useStyles();

  return (
    <Paper elevation={24} className={classes?.paper} {...props}>
      <Box p={2} className={clsx(styles.wrapper, classes?.wrapper)}>
        <Box className={styles.info}>
          <Box mr={2}>{createElement(getFileIcon({ fileName }), { className: styles.fileIcon })}</Box>

          <Box>
            <Typography variant="subtitle1" className={styles.primaryText}>
              {fileName}
            </Typography>
            <Typography variant="body1" className={styles.secondaryText}>
              by {author} • {createdDate ? moment(createdDate).format('MM/DD/YYYY') : undefinedCreatedDataText}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.actions}>
          {onView && (
            <IconButton color="secondary" aria-label="view attachment" onClick={onView}>
              <EyeIcon className={styles.actionButton} />
            </IconButton>
          )}

          {onDownload && (
            <IconButton color="secondary" aria-label="view attachment" onClick={onDownload}>
              <DownloadIcon className={styles.actionButton} />
            </IconButton>
          )}

          {onDelete && (
            <IconButton color="secondary" aria-label="view attachment" onClick={onDelete}>
              <TrashIcon className={styles.actionButton} />
            </IconButton>
          )}
        </Box>

        {description && (
          <Box mt={2.25} className={styles.description} width="100%">
            <Typography variant="body1" className={clsx(styles.secondaryText, styles.descriptionText)}>
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
