export enum AppointmentCategoriesEnum {
  "All categories" = 0,
  "Junk Removal" = 1,
  "Labor" = 4,
  "Moving" = 5,
  "Move labor" = 7,
  "Express Junk" = 8,
}

export const AppointmentCategories: Record<AppointmentCategoriesEnum, string> =
  {
    0: "All categories",
    1: "Junk Removal",
    4: "Labor",
    5: "Moving",
    7: "Move labor",
    8: "Express Junk",
  };

export type Category = {
  id: number;
  name: string;
};

export enum CategoryIdEnum {
  JunkRemoval = 1,
  Labor = 4,
  Moving = 5,
  ExpressJunk = 8,
  MoveLabor = 7,
}
