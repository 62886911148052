import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { TaskViewFormValues, TaskViewProps, taskViewValidationSchema } from '../';
import { TaskBoardTypesEnum } from 'entities/tasks';
import { useScrollTo } from 'hooks';

export const useViewForm = ({ loading, task }: Pick<TaskViewProps, 'task' | 'loading'>) => {
  const { control, formState, getValues, handleSubmit, reset, setValue, setError, watch, trigger, clearErrors } =
    useForm<TaskViewFormValues>({
      defaultValues: task,
      resolver: yupResolver(taskViewValidationSchema),
      mode: 'all',
    });

  const { addItemRef, containerRef, itemsKeys, removeItemRef, scrollToId, setScrollToId } = useScrollTo();

  const [isCommentFormVisible, setCommentFormVisibility] = useState(false);

  const boardType = watch('board.type');
  const dueDate = watch('dueDate');
  const dueTime = watch('dueTime');
  const appointments = watch('appointments');
  const accounts = watch('accounts');

  const isClaim = useMemo(() => boardType?.id === TaskBoardTypesEnum.CLAIMS, [boardType]);

  const isEstimate = useMemo(() => boardType?.id === TaskBoardTypesEnum.ESTIMATE, [boardType]);

  const scrollNavOptions = useMemo(
    () =>
      itemsKeys.map((name, id) => ({
        id,
        name,
        onMenuItemClick: () => setScrollToId(name),
      })),
    [itemsKeys],
  );

  const hasUnsavedChanges = useMemo(() => formState.isDirty, [formState.isDirty]);

  const setRef = useCallback(
    (id: string) => (el: HTMLDivElement | null) => !itemsKeys.includes(id) && addItemRef(id, el),
    [addItemRef, itemsKeys],
  );

  useEffect(() => {
    if (!accounts || !accounts.length) {
      removeItemRef('Contact information');
    }
  }, [accounts, removeItemRef]);

  useEffect(() => {
    if (!appointments || !appointments.length) {
      removeItemRef('Job information');
    }
  }, [appointments, removeItemRef]);

  const firstReset = useRef(true);
  useEffect(() => {
    if (task && !loading && firstReset.current) {
      firstReset.current = false;
      reset(task);
    }

    return () => {
      firstReset.current = true;
    };
  }, [loading, reset, task]);

  return {
    containerRef,
    control,
    dueDate,
    dueTime,
    formState,
    getValues,
    handleSubmit,
    hasUnsavedChanges,
    isClaim,
    isEstimate,
    isCommentFormVisible,
    reset,
    scrollNavOptions,
    scrollToId,
    setCommentFormVisibility,
    setRef,
    setScrollToId,
    setValue,
    setError,
    watch,
    trigger,
    clearErrors,
  };
};
