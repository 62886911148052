import MaskedInput from 'react-text-mask';

import { isCanadaPostal, isUSAPostal } from 'utils';

import { TextMaskProps } from './TextMaskProps.types';
import { useFixCaptureAttr } from './useFixCaptureAttr';

export const PostalMask = ({ inputRef, capture, ...restProps }: TextMaskProps) => {
  const fixedCapture = useFixCaptureAttr(capture);

  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...restProps}
      capture={fixedCapture}
      mask={value => {
        if (isCanadaPostal(value)) {
          return [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/];
        }

        if (isUSAPostal(value)) {
          return [/\d/, /\d/, /\d/, /\d/, /\d/];
        }

        return false;
      }}
    />
  );
};
