import { ReactNode } from 'react';

import { CssBaseline, unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';

import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

const theme = createMuiTheme({
  palette,
  typography: (palette: Palette) => typography(palette),
  overrides,
});

export const CollegeHunksTheme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
