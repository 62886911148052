import { useConfirmationDialog } from "@chhjpackages/components";
import { useCallback } from "react";
import { useStore } from "effector-react";

import {
  $settings,
  SettingItemsEnum,
  setSettingEv,
  setSettingValue,
} from "features/settings";

export const useTrainingMode = () => {
  const { trainingMode } = useStore($settings);

  const { openConfirmation, closeConfirmation } = useConfirmationDialog({
    title: "Training mode",
    message:
      "Training mode has been turned on. The information shown will differ from your settings and location data. To return to your location and saved data turn training mode off.",
    hideCancelButton: true,
    confirmButtonText: "Close",
  });

  const handleChangeTrainingMode = useCallback((status: boolean) => {
    setSettingEv({ name: "trainingMode", value: status });
    setSettingValue(SettingItemsEnum.TrainingMode, status);

    window.location.reload();
  }, []);

  const toggleTrainingMode = useCallback(() => {
    if (trainingMode) {
      handleChangeTrainingMode(!trainingMode);
    } else {
      openConfirmation(() => {
        closeConfirmation();
        handleChangeTrainingMode(!trainingMode);
      });
    }
  }, [trainingMode, handleChangeTrainingMode]);

  return {
    isTrainingModeOn: trainingMode,
    toggleTrainingMode,
  };
};
