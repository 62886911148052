import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useScrollTo = (
  scrollPosition: 'start' | 'center' | 'end' = 'start',
  behavior: ScrollBehavior = 'smooth',
  offset = 0,
  onScroll?: (toElement: HTMLElement, scrollContainerElement: HTMLElement, scrollToId: string) => void,
) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<{ [key: string]: HTMLDivElement }>({});

  const [refCount, setRefCount] = useState(0);

  const itemsKeys = useMemo(() => (refCount ? Object.keys(itemsRef.current) : []), [refCount]);

  const [scrollToId, setScrollToId] = useState<string>();

  const addItemRef = useCallback(
    (key, element: HTMLDivElement | null) => {
      if (element && !itemsRef.current[key]) {
        itemsRef.current[key] = element;
        setRefCount(refCount + 1);
      }
    },
    [refCount],
  );

  const removeItemRef = useCallback(
    key => {
      if (itemsRef.current[key]) {
        delete itemsRef.current[key];
        setRefCount(refCount - 1);
      }
    },
    [refCount],
  );

  useEffect(() => {
    if (typeof scrollToId !== 'undefined' && !!!onScroll) {
      const toElement = itemsRef.current[scrollToId];

      if (toElement) {
        const distance = toElement.getBoundingClientRect().top;

        const scrollContainerElement = containerRef.current;

        if (scrollContainerElement) {
          const top =
            scrollPosition === 'start'
              ? distance + scrollContainerElement.scrollTop - offset
              : scrollPosition === 'center'
              ? distance +
                scrollContainerElement.scrollTop -
                scrollContainerElement.clientHeight / 2 +
                toElement.clientHeight / 2 -
                offset
              : scrollPosition === 'end'
              ? distance + scrollContainerElement.scrollTop + toElement.clientHeight - offset
              : 0;

          scrollContainerElement?.scrollTo({
            top: top,
            behavior: behavior,
          });

          setScrollToId(undefined);
        }
      }
    }

    if (typeof scrollToId !== 'undefined' && onScroll) {
      const toElement = itemsRef.current[scrollToId];
      const scrollContainerElement = containerRef.current;

      if (toElement && scrollContainerElement) {
        onScroll(toElement, scrollContainerElement, scrollToId);

        setScrollToId(undefined);
      }
    }
  }, [behavior, offset, scrollPosition, scrollToId]);

  return {
    addItemRef,
    containerRef,
    itemsKeys,
    itemsRef,
    removeItemRef,
    scrollToId,
    setScrollToId,
  };
};
