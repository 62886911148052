import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles({
  titleSuccess: {
    color: colors.functionals.success,
  },
  titleError: {
    color: colors.functionals.alert,
  },
  titleInfo: {
    color: colors.functionals.info,
  },
  titleWarning: {
    color: colors.complementary.yellow.shade01,
  },
});
