import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

import { CalendarPickerOverrides } from 'components/pickers/calendarPicker/CalendarPicker.styles';

import { RiftSoft } from './typography';

const defaultTheme = createMuiTheme();

const pageWidth = {
  [defaultTheme.breakpoints.down('xs')]: {
    width: '100%',
  },
  [defaultTheme.breakpoints.up('sm')]: {
    width: 580,
  },
  [defaultTheme.breakpoints.up('md')]: {
    width: 940,
  },
  [defaultTheme.breakpoints.up('lg')]: {
    width: 1140,
  },
  [defaultTheme.breakpoints.up('xl')]: {
    width: 1320,
  },
};

export const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      '@font-face': RiftSoft,
    },
  },
  MuiContainer: {
    root: pageWidth,
  },
  ...CalendarPickerOverrides,
};
