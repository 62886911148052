import React, { memo } from 'react';

import { PopoverStyled } from './Popover.styles';
import { PopoverProps } from './Popover.types';
import { usePopoverControls } from './usePopoverControls';

export const Popover: React.FC<PopoverProps> = memo(
  ({ children, popoverProps, toggle, toggleProps, variant = 'click', disabled, open, onOpen, onClose }) => {
    const {
      isVisible,
      popoverAnchor,
      popoverActions,
      popoverClose,
      triggerProps,
      isHoverVariant,
      popoverClass,
      paperClass,
    } = usePopoverControls({
      variant,
      disabled,
      open,
      onOpen,
      onClose,
    });

    return (
      <React.Fragment>
        <span
          ref={popoverAnchor}
          aria-owns="mouse-over-popover"
          aria-haspopup="true"
          {...triggerProps}
          {...toggleProps}
        >
          {toggle}
        </span>

        {!disabled && (
          <PopoverStyled
            id="mouse-over-popover"
            open={isVisible}
            anchorEl={popoverAnchor.current}
            action={popoverActions}
            onClose={popoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            className={popoverClass}
            classes={paperClass}
            PaperProps={isHoverVariant ? triggerProps : undefined}
            {...popoverProps}
          >
            {children}
          </PopoverStyled>
        )}
      </React.Fragment>
    );
  },
);
