import { FC, forwardRef } from 'react';

import clsx from 'clsx';

import { useStyles, TextFieldStyled } from './TextArea.styles';
import { TextAreaProps } from './TextArea.types';

export const TextArea: FC<TextAreaProps> = forwardRef(({ size, ...props }, ref) => {
  const styles = useStyles();

  return (
    <TextFieldStyled
      {...props}
      ref={ref}
      multiline
      className={clsx(
        styles.root,
        props.error && styles.error,
        props.disabled && styles.rootDisabled,
        props.variant === 'outlined' && styles.rootOutlined,
        size === 'small' && styles.rootSmall,
        size === 'medium' && styles.rootMedium,
        size === 'large' && styles.rootLarge,
      )}
    />
  );
});
