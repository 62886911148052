import { Typography, createStyles, makeStyles, withStyles } from '@material-ui/core';

export const useStyles = () => {
  const style = makeStyles(
    createStyles({
      wrapper: {
        maxWidth: 384,
        width: '90%',
      },
    }),
  );

  return style();
};

export const Subtitle = withStyles(() =>
  createStyles({
    root: {
      lineHeight: 1.25,
      fontSize: 16,
    },
  }),
)(Typography);
