import moment from 'moment';
import * as yup from 'yup';

import { TaskBoardTypesEnum, TaskSwimlaneTypesEnum, TaskSwimlane, resolutionTypes } from 'entities/tasks';
import { USADateFormat } from 'utils';

const infoModifySchema = yup.object({
  datetime: yup.string(),
  name: yup.string(),
  id: yup.number(),
  imageUrl: yup.string(),
});
const dataItemSchema = yup.object({
  id: yup.number(),
  name: yup.string(),
});

export const taskViewValidationSchema = yup.object({
  assignees: yup
    .array(
      yup.object({
        assigneeId: yup.number(),
        label: yup.string(),
        value: yup.number(),
      }),
    )
    .transform((_, originalValue) => {
      if (Array.isArray(originalValue)) {
        return originalValue;
      }

      return originalValue ? [originalValue] : [];
    }),

  comments: yup.array(
    yup.object({
      id: yup.number(),
      comment: yup.string(),
      canBeEdited: yup.boolean(),
      canBeDeleted: yup.boolean(),
      created: infoModifySchema,
      isDeleted: yup.boolean(),
      type: dataItemSchema,
    }),
  ),

  attachments: yup
    .array(
      yup.object({
        id: yup.number(),
        url: yup.string(),
        description: yup.string(),
        canBeDeleted: yup.boolean(),
        created: infoModifySchema,
        isDeleted: yup.boolean(),
        isPublic: yup.boolean(),
        file: yup.mixed<File>(),
      }),
    )
    .test(values => !!!values?.find(value => (value.file ? value.file.size / (1024 * 1024) >= 10 : false))),

  description: yup.string().trim(),

  dueDate: yup
    .date()
    .typeError('Due date must be in MM/DD/YYYY format')
    .transform((value, originalValue) => {
      if (originalValue.length < 8) {
        return null;
      }
      const date = moment(originalValue, USADateFormat).toDate();

      return date.getTime() ? date : undefined;
    }),

  dueTime: yup.string(),

  likes: yup.array(
    yup.object({
      id: yup.number(),
      name: yup.string(),
    }),
  ),

  priority: yup.number().required('Priority is required'),

  summary: yup.string().trim().required('Summary is required'),

  swimlane: yup.number().required('Summary is required'),

  watchers: yup.array(
    yup.object({
      id: yup.number(),
      name: yup.string(),
    }),
  ),

  resolution: yup.string().when(['swimlane', 'board.swimlanes', 'board.type.id'], {
    is: (swimlane?: number, swimlanes?: TaskSwimlane[], boardType?: number) =>
      Boolean(
        boardType === TaskBoardTypesEnum.CLAIMS &&
          swimlanes?.find(item => item.id === swimlane)?.type.id === TaskSwimlaneTypesEnum.Done,
      ),
    then: schema => schema.required('Resolution type is required'),
    otherwise: schema => schema,
  }),

  totalCost: yup
    .number()
    .transform((_, originvalValue) => {
      const val = Number(originvalValue);

      return val ? val : undefined;
    })
    .when(['resolution'], {
      is: (resolution?: string) => Boolean(resolution && resolution !== resolutionTypes.Denied),
      then: schema => schema.required('Total cost is required'),
      otherwise: schema => schema,
    }),

  update: yup
    .string()
    .transform(value =>
      value
        .replaceAll('&nbsp;', '')
        .replaceAll('&ZeroWidthSpace;', '')
        .replaceAll(' ', '')
        .replaceAll('<br>', '')
        .replaceAll('<p></p>', '') === ''
        ? ''
        : value,
    )
    .when(['swimlane', 'board.swimlanes', 'board.type.id'], {
      is: (swimlane?: number, swimlanes?: TaskSwimlane[], boardType?: number) =>
        Boolean(
          boardType === TaskBoardTypesEnum.CLAIMS &&
            swimlanes?.find(item => item.id === swimlane)?.type.id === TaskSwimlaneTypesEnum.Done,
        ),
      then: schema => schema.required('Resolution note is required'),
      otherwise: schema => schema,
    }),
});
