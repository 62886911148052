import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles({
  tile: {
    minHeight: 112,
    display: 'flex',
    background: colors.basic.white,
    border: `1px solid ${colors.grey20}`,
    transition: 'border-color 200ms ease 0s, box-shadow 200ms ease 0s ',
    '& button': {
      width: '100%',
      padding: '3px',
      lineHeight: 1.2,
    },
    '&$tileSelected': {
      borderColor: colors.primary.main,
      boxShadow: `0px 0px 8px ${colors.primary.main}3d`,
    },
  },
  tileSelected: {},
  iconContainer: {
    '& svg': {
      fontSize: '48px',
    },
  },
  iconText: {
    lineHeight: 'inherit',
  },
});
