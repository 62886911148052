import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const scheduleSidebarWidth = 400;
export const scheduleSidebarWidthXS = 290;
const drawerWidth = 256;
const drawerWidthSm = '100vw';
const drawerWidthClose = 0;
const drawerWidthCloseSm = 0;
const topBarHeightRaw = 60;

export const useStyles = makeStyles(theme => ({
  toolbar: {
    position: 'relative',
    zIndex: 1299,
    background: colors.basic.white,
    boxShadow: '0px 0px 24px rgba(134, 145, 166, 0.32)',
    padding: '0px 16px',
  },

  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: drawerWidthSm,

    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },

  drawerPaper: {
    overflowX: 'hidden',
    width: 'inherit',
    height: `calc(100vh - ${topBarHeightRaw}px)`,
    marginTop: topBarHeightRaw,
    boxShadow: '0px 0px 24px rgba(134, 145, 166, 0.32)',
    border: 0,
  },

  drawerOpen: {
    width: drawerWidthSm,

    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
    },

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    width: drawerWidthClose,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.only('sm')]: {
      width: drawerWidthCloseSm,

      '& .MuiListItem-gutters': {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },

  hunkWareWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
  },

  closeWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
    marginRight: -8,

    [theme.breakpoints.down('xs')]: {
      marginLeft: -16,
    },
  },

  hunkWareIcon: {
    height: 16,
    width: 'auto',

    [theme.breakpoints.down(1400)]: {
      height: 14,
    },

    [theme.breakpoints.down(1100)]: {
      height: 15,
    },
  },

  listItem: {
    margin: '0 8px',
    padding: 8,

    '&:hover': {
      color: colors.primary.main,
      backgroundColor: colors.primary.superlight,
      borderRadius: 4,
    },
  },

  userProfileWrapper: {
    marginLeft: 'auto',
  },

  userProfileContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  locationSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  messageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    marginRight: 20,

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 12,
    },
  },

  messageIconButton: {
    height: 'fit-content',
    margin: -12,
  },

  navigationItemsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));
