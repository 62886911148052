import { AppointmentCategoryEnum } from '../types';
import { colors } from 'assets';

export const appointmentCategories: {
  [key: number]: { color: string; name: string };
} = {
  [AppointmentCategoryEnum.ExpressJunk]: {
    color: colors.functionals.warning,
    name: 'Junk',
  },
  [AppointmentCategoryEnum.JunkRemoval]: {
    color: colors.functionals.warning,
    name: 'Junk',
  },
  [AppointmentCategoryEnum.Moving]: {
    color: colors.functionals.success,
    name: 'Moving',
  },
  [AppointmentCategoryEnum.Labor]: {
    color: colors.functionals.info,
    name: 'General Labor',
  },
  [AppointmentCategoryEnum.MoveLabor]: {
    color: '#B0760D',
    name: 'Move Labor',
  },
};
