import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  DashboardLazy,
  JobDetailsLazy,
  LoginLazy,
  NeedHelpLazy,
  ResetPasswordLinkLazy,
  ResetPasswordLinkSuccessLazy,
  RestorePasswordLazy,
  RestorePasswordSuccessLazy,
  TermsAndConditionsLazy,
  UnprocessedChangesLazy,
  NasaRequirementsLazy,
  SettingsLazy,
  EstimateDetailLazy,
  CreateTeamLazy,
  PaymentsLazy,
  PaymentCollectLazy,
  PaymentResultLazy,
  CouponsAndDiscountsLazy,
  CouponsLazy,
  DiscountsLazy,
  CartLazy,
  TrainingLazy,
  PerfectJobStepsLazy,
  MoveJobStepsLazy,
  BinderPresentationListLazy,
  PricingsLazy,
  LeaderBoardLazy,
  UserProfileLazy,
  EditTravelDevelopmentsLazy,
  EditWorkDevelopmentsLazy,
  BillOfLadingEstimateLazy,
  BillOfLadingSignatureLazy,
  JobInspectionTermsLazy,
  JobInspectionSignatureLazy,
  NotificationsLazy,
  ValuationLazy,
  ValuationSignatureLazy,
  PreExistingDamageLazy,
  PreExistingDamageSignatureLazy,
  LiabilityWaiverLazy,
  LiabilityWaiverSignatureLazy,
  AddendumLazy,
  AddendumSignatureLazy,
  TasksListLazy,
  AttachmentsLazy,
  CompleteAppointmentLazy,
  SendInvoiceLazy,
  CartEditLazy,
  SendPaymentLinkLazy,
  PricingsAddLazy,
  BinderPresentationDetailsLazy,
  ContactsLazy,
  MapTypesDialogLazy,
  NotesDialogLazy,
  TaskDetailsLazy,
  PaymentAttachLazy,
} from "../utils";

export const usePreloadPages = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    DashboardLazy.preload();
    SettingsLazy.preload();
    LoginLazy.preload();
    TrainingLazy.preload();
    LeaderBoardLazy.preload();
    UserProfileLazy.preload();
    NotificationsLazy.preload();
    TasksListLazy.preload();

    if (pathname.includes("login")) {
      NeedHelpLazy.preload();
      ResetPasswordLinkLazy.preload();
      ResetPasswordLinkSuccessLazy.preload();
      RestorePasswordLazy.preload();
      RestorePasswordSuccessLazy.preload();
      TermsAndConditionsLazy.preload();
    }

    if (pathname.includes("dashboard")) {
      JobDetailsLazy.preload();
      UnprocessedChangesLazy.preload();
      CreateTeamLazy.preload();
      NasaRequirementsLazy.preload();
      ContactsLazy.preload();
      MapTypesDialogLazy.preload();
      NotesDialogLazy.preload();
    }

    if (pathname.includes("job-details")) {
      EstimateDetailLazy.preload();
      PaymentsLazy.preload();
      CartLazy.preload();
      PricingsLazy.preload();
      EditTravelDevelopmentsLazy.preload();
      EditWorkDevelopmentsLazy.preload();
      BillOfLadingEstimateLazy.preload();
      JobInspectionTermsLazy.preload();
      ValuationLazy.preload();
      PreExistingDamageLazy.preload();
      LiabilityWaiverLazy.preload();
      AddendumLazy.preload();
      AttachmentsLazy.preload();
      CompleteAppointmentLazy.preload();
      SendInvoiceLazy.preload();
      ContactsLazy.preload();
      NotesDialogLazy.preload();
      CreateTeamLazy.preload();
    }

    if (pathname.includes("nasa-requirements")) {
      AttachmentsLazy.preload();
    }

    if (pathname.includes("liability-waiver")) {
      LiabilityWaiverSignatureLazy.preload();
    }

    if (pathname.includes("addendum")) {
      AddendumSignatureLazy.preload();
    }

    if (pathname.includes("add-product")) {
      PricingsAddLazy.preload();
    }

    if (pathname.includes("valuation")) {
      ValuationSignatureLazy.preload();
    }

    if (pathname.includes("pre-existing-damage")) {
      PreExistingDamageSignatureLazy.preload();
    }

    if (pathname.includes("job-inspection")) {
      JobInspectionSignatureLazy.preload();
    }

    if (pathname.includes("bill-of-lading")) {
      BillOfLadingSignatureLazy.preload();
    }

    if (pathname.includes("cart")) {
      CouponsAndDiscountsLazy.preload();
      CouponsLazy.preload();
      DiscountsLazy.preload();
      CartEditLazy.preload();
      BillOfLadingSignatureLazy.preload();
    }

    if (pathname.includes("tasks")) {
      TaskDetailsLazy.preload();
    }

    if (pathname.includes("training")) {
      PerfectJobStepsLazy.preload();
      MoveJobStepsLazy.preload();
      BinderPresentationListLazy.preload();
      BinderPresentationDetailsLazy.preload();
    }

    if (pathname.includes("payment")) {
      PaymentCollectLazy.preload();
      PaymentResultLazy.preload();
      PaymentAttachLazy.preload();
      SendPaymentLinkLazy.preload();
    }
  }, [pathname]);
};
