import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const ArrowIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="8" height="13" viewBox="0 0 8 13" fill="none">
      <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.49921 12C1.63081 12.0007 1.76128 11.9754 1.88311 11.9254C2.00495 11.8754 2.11577 11.8017 2.20921 11.7085L7.20921 6.68361C7.39546 6.49531 7.5 6.2406 7.5 5.9751C7.5 5.70959 7.39546 5.45488 7.20921 5.26658L2.20921 0.241655C2.01791 0.0770122 1.77183 -0.00902062 1.52015 0.000748977C1.26848 0.0105186 1.02974 0.115371 0.851644 0.294353C0.67355 0.473335 0.569217 0.713265 0.559496 0.966196C0.549775 1.21913 0.635381 1.46643 0.799208 1.65868L5.08921 5.97007L0.799207 10.2815C0.658785 10.4214 0.562814 10.6001 0.523429 10.7949C0.484044 10.9897 0.503014 11.1918 0.577939 11.3757C0.652864 11.5597 0.780381 11.7172 0.944364 11.8283C1.10835 11.9394 1.30143 11.9991 1.49921 12Z"
          fill="#8691A6"
        />
      </svg>
    </SvgIcon>
  );
};
