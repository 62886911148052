import { colors } from 'assets';

import { useStyles } from './DashedSeparator.styles';
import { DashedSeparatorProps } from './DashedSeparator.types';

export function DashedSeparator({
  dashedItemProps: { width, height = 1, color = colors.grey60 },
}: DashedSeparatorProps) {
  const styles = useStyles({ dashedItemProps: { width, height, color } });

  return <div className={styles.dashed} />;
}
