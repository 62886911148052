import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from '../theme/palette';

import { SvgIconProps } from './SvgIcon.types';

export const EyeIcon = ({ className, fontSize = 24, color = colors.primary.main }: SvgIconProps) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.1543 5.00209C8.59874 4.91321 4.86541 7.66876 2.5543 10.5132C2.02096 11.1354 2.02096 12.0243 2.5543 12.7354C4.86541 15.491 8.50985 18.3354 12.1543 18.3354C15.7987 18.4243 19.5321 15.5799 21.7543 12.7354C22.2876 12.1132 22.2876 11.2243 21.7543 10.5132C19.4432 7.66876 15.7099 4.91321 12.1543 5.00209Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5314 11.6685C15.5314 13.5352 14.0203 15.0463 12.1537 15.0463C10.287 15.0463 8.77588 13.5352 8.77588 11.6685C8.77588 9.80188 10.287 8.29077 12.1537 8.29077C14.0203 8.29077 15.5314 9.80188 15.5314 11.6685Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
