import { useState, useEffect, useCallback } from 'react';

import { useResizeDetector } from 'react-resize-detector';

export const useTabsScrollStyles = () => {
  const [isShowLeftGradient, setIsShowLeftGradient] = useState(false);
  const [isShowRightGradient, setIsShowRightGradient] = useState(false);
  const [tabsWrapperScrollLeft, setTabsWrapperScrollLeft] = useState(0);

  const { width: tabsListWidth, ref: tabsListRef } = useResizeDetector();
  const { width: tabsWrapperWidth, ref: tabsWrapperRef } = useResizeDetector();

  const onScrollTabsWrapper = useCallback(
    e => {
      setTabsWrapperScrollLeft(e.target.scrollLeft);
      if (e.target.scrollLeft >= 15) {
        setIsShowLeftGradient(true);
      } else {
        setIsShowLeftGradient(false);
      }
    },
    [setIsShowLeftGradient, setTabsWrapperScrollLeft],
  );

  useEffect(() => {
    if (!tabsWrapperWidth || !tabsListWidth) {
      return;
    }

    if (tabsListWidth + 32 > tabsWrapperWidth && tabsListWidth - tabsWrapperScrollLeft + 17 > tabsWrapperWidth) {
      setIsShowRightGradient(true);
    } else {
      setIsShowRightGradient(false);
    }
  }, [tabsWrapperWidth, tabsListWidth, tabsWrapperScrollLeft, setIsShowRightGradient]);

  return { isShowLeftGradient, isShowRightGradient, tabsListRef, tabsWrapperRef, onScrollTabsWrapper };
};
