export const routePaths = {
  home: () => "/",

  dashboard: () => "/dashboard",

  appointmentContacts: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/contacts`,
  appointmentNotes: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/notes`,
  appointmentMapTypes: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/map-types`,

  jobDetails: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details`,
  jobDetailsContacts: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/contacts`,
  jobDetailsNotesModal: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/notes-modal`,
  jobDetailsSendInvoice: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/send-invoice`,
  jobDetailsCompleteAppointment: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/complete-appointment`,
  jobDetailsAttachments: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/attachments`,
  jobDetailsConvertToJob: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/convert-to-job`,

  jobDetailsAddProduct: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/add-product`,
  jobDetailsAddProductNew: (appointmentId?: number, pricingId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/add-product/${
      pricingId || ":pricingId"
    }/add`,
  jobDetailsCart: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/cart`,
  jobDetailsCartEdit: (appointmentId?: number, productLineId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/cart/${
      productLineId || ":productLineId"
    }/edit`,

  jobDetailsCouponsAndDiscounts: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/coupons-and-discounts`,
  jobDetailsCoupons: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/coupons-and-discounts/coupons`,
  jobDetailsDiscounts: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/coupons-and-discounts/discounts`,

  jobDetailsLiabilityWaiver: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/liability-waiver`,
  jobDetailsLiabilityWaiverSignature: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/liability-waiver/signature`,

  jobDetailsValuation: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/valuation`,
  jobDetailsValuationSignature: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/valuation/signature`,

  jobDetailsBillOfLading: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/bill-of-lading`,
  jobDetailsbillOfLadingSignature: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/bill-of-lading/signature`,

  jobDetailsPreExistingDamage: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/pre-existing-damage`,
  jobDetailsPreExistingDamageSignature: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/pre-existing-damage/signature`,

  jobDetailsEditTravelHours: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/edit-travel-hours`,
  jobDetailsEditWorkHours: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/edit-work-hours`,

  jobInspectionTerms: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/job-inspection/terms`,
  jobInspectionSignature: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/job-inspection/signature`,

  jobDetailsAddendum: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/addendum`,
  jobDetailsAddendumSignature: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/addendum/signature`,

  jobDetailsPayments: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/payments`,
  jobDetailsPaymentsCollect: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/payments/collect`,
  jobDetailsPaymentsResult: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/payments/result`,
  jobDetailsSendPaymentLink: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/job-details/payments/link`,
  jobDetailsAttachPayment: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/job-details/payments/attach`,

  nasaRequirements: (appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/nasa-requirements`,
  nasaRequirementsAttachments: (appointmentId?: number) =>
    `/dashboard/${
      appointmentId || ":appointmentId"
    }/nasa-requirements/attachments`,

  training: () => "/training",
  trainingPerfectJob: () => "/training/perfectJob",
  trainingMoveJob: () => "/training/moveJob",
  trainingBinderPresentation: () => "/training/binder-presentation",
  trainingBinderSinglePresentation: () =>
    "/training/binder-presentation/single-binder-presentation",
  trainingBinderPresentationDetails: (presentationId?: number) =>
    `/training/binder-presentation/${presentationId || ":presentationId"}`,

  tasksList: () => "/tasks",
  taskDetails: (taskId?: number) => `/tasks/${taskId || ":taskId"}`,

  squarePaymentResult: () => "/payment/result",
  unprocessedChanges: () => "/unprocessed-changes",
  leaderboards: () => "/leaderboards",
  notifications: () => "/notifications",
  forms: () => "/forms",
  documents: () => "/documents",
  userProfile: () => "/user-profile",
  settings: () => "/settings",
  createTeam: () => "/create-team",
  editTeam: (teamId?: number, appointmentId?: number) =>
    `/dashboard/${appointmentId || ":appointmentId"}/edit-team/${
      teamId || ":teamId"
    }`,

  login: () => "/login",
  termsAndConditions: () => "/terms-and-conditions",
  needHelp: () => "/need-help",
  resetPasswordLink: () => "/reset-password-link",
  resetPasswordLinkSuccess: () => "/reset-password-link/success",
  restorePassword: (userId?: string, resetToken?: string) =>
    `/restore-password/${userId || ":userId"}/${resetToken || ":resetToken"}`,
  restorePasswordSuccess: () => "/restore-password/success",

  notFound: () => "/*",
};

export const loginRoutePaths = [
  routePaths.login(),
  routePaths.termsAndConditions(),
  routePaths.needHelp(),
  routePaths.resetPasswordLink(),
  routePaths.resetPasswordLinkSuccess(),
  routePaths.restorePassword(),
  routePaths.restorePasswordSuccess(),
];

export const addendumRoutePaths = [
  `${routePaths.jobDetailsAddProduct()}/*`,
  `${routePaths.jobDetailsCart()}/*`,
  `${routePaths.jobDetailsAddendum()}/*`,
];

export const ignoreScrollToTopBetweenPaths = [
  [routePaths.tasksList(), routePaths.taskDetails()],
];
