import { useMemo } from 'react';

export const useFixCaptureAttr = (capture: boolean | string | undefined) => {
  const fixedCapture = useMemo(() => {
    if (typeof capture === 'string' && capture !== 'environment' && capture !== 'user') {
      return undefined;
    }

    return capture;
  }, [capture]);

  return fixedCapture;
};
