import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  trackVertical: {
    zIndex: 20,
    position: 'absolute',
    width: '6px',
    transition: 'opacity 200ms ease 0s',
    right: '2px',
    bottom: '2px',
    top: '2px',
    borderRadius: '3px',
  },
  trackHorizontal: {
    zIndex: 20,
    position: 'absolute',
    height: '6px',
    transition: 'opacity 200ms ease 0s',
    right: '2px',
    bottom: '2px',
    left: '2px',
    borderRadius: '3px',
  },
  thumbVertical: {
    zIndex: 20,
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  thumbHorizontal: {
    zIndex: 20,
    position: 'relative',
    display: 'block',
    cursor: 'pointer',
    borderRadius: 'inherit',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
});
