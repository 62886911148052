import { createContext } from "react";

import {
  SideNavDispatchContextValue,
  SideNavStateContextValue,
} from "../types";

export const initialSideNavStateContext: SideNavStateContextValue = {
  showSideNav: false,
  showGoBackButton: false,
  goBackToUrl: "",
  pageName: "",
  actionButton: null,
  pageNameAddition: null,
  elevation: 0,
};

export const SideNavStateContext = createContext<SideNavStateContextValue>(
  initialSideNavStateContext,
);

export const SideNavDispatchContext = createContext<
  SideNavDispatchContextValue | undefined
>(undefined);
