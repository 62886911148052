import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Collapse,
  IconButton,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Button,
  colors,
  disablePageScroll,
  enablePageScrol,
  Paper,
  Scrollbars,
} from "@chhjpackages/components";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import clsx from "clsx";

import { useSideNavState } from "features/sidenav";
import { routePaths } from "shared/utils";
import { useTrainingMode } from "features/training-mode";
import { ArrowBackIcon, BellIcon, KebabMenuIcon } from "shared/assets";
import { $notifications } from "entities/notifications";

import { NavMenu, TrainingModeBar, UserInfo, UserInfoSkeleton } from "./ui";
import { useStyles } from "./assets";
import { SidebarNavProps } from "./model";

export const SidebarNav = memo(({ user, logout }: SidebarNavProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const styles = useStyles();

  const locaiton = useLocation();
  const navigate = useNavigate();

  const { filteredNotifications } = useStore($notifications);
  const {
    goBackToUrl,
    goBackAction,
    goBackOptions,
    pageName,
    showGoBackButton,
    actionButton,
    pageNameAddition,
    elevation,
  } = useSideNavState();

  const { isTrainingModeOn } = useTrainingMode();

  const [isNavOpen, setNavOpen] = useState(false);

  const isShowBackButton = useMemo(
    () => showGoBackButton || !!goBackToUrl || !!goBackAction,
    [showGoBackButton, goBackToUrl, goBackAction],
  );

  const handleOpenNav = useCallback(() => setNavOpen(true), []);

  const handleCloseNav = useCallback(() => setNavOpen(false), []);

  const handleBack = useCallback(() => {
    if (!!goBackAction) {
      goBackAction();

      return;
    }

    navigate(goBackToUrl, goBackOptions);
  }, [goBackToUrl, goBackOptions, goBackAction, navigate]);

  const handleClickTrainingModeBar = useCallback(() => {
    if (locaiton.pathname !== routePaths.settings()) {
      navigate(routePaths.settings());
    }
  }, [locaiton.pathname, navigate]);

  const handleClickNotifications = useCallback(() => {
    if (locaiton.pathname !== routePaths.notifications()) {
      navigate(routePaths.notifications());
    }
  }, [locaiton.pathname, navigate]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    if (isNavOpen) {
      disablePageScroll();
    } else {
      enablePageScrol();
    }
  }, [isMobile, isNavOpen]);

  return isMobile ? (
    <Paper elevation={elevation} className={styles.root}>
      <div
        className={clsx(
          styles.content,
          !!pageName && styles.contentWithPageName,
        )}
      >
        <div className={styles.leftSection}>
          <IconButton className="iconButtonMedium" onClick={handleOpenNav}>
            <KebabMenuIcon color={colors.grey190} />
          </IconButton>

          {isShowBackButton && (
            <IconButton className="iconButtonMedium" onClick={handleBack}>
              <ArrowBackIcon fontSize={16} color={colors.grey190} />
            </IconButton>
          )}
        </div>

        {pageName && (
          <div className={styles.centerSection}>
            <Typography variant="h4">{pageName}</Typography>
            {pageNameAddition && pageNameAddition}
          </div>
        )}

        <div className={styles.rightSection}>
          {actionButton && (
            <Button
              name={actionButton?.name}
              type={actionButton?.type}
              onClick={actionButton?.onClick}
              disabled={actionButton?.disabled}
              isLoading={actionButton?.loading}
              buttonType="text"
              color="primary"
              style={{ padding: 0 }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {actionButton.icon && actionButton.icon}
                <div style={{ marginLeft: actionButton.icon ? 8 : 0 }}>
                  {actionButton.name}
                </div>
              </div>
            </Button>
          )}

          <Badge
            badgeContent={
              <Typography variant="h6" className={styles.badgeText}>
                {filteredNotifications.length}
              </Typography>
            }
            color="error"
            invisible={!filteredNotifications.length}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            classes={{ badge: styles.badge }}
          >
            <IconButton
              className="iconButtonMedium"
              onClick={handleClickNotifications}
            >
              <BellIcon fontSize={22} />
            </IconButton>
          </Badge>
        </div>
      </div>

      <Collapse in={isTrainingModeOn} unmountOnExit timeout="auto">
        <TrainingModeBar onClick={handleClickTrainingModeBar} />
      </Collapse>

      <SwipeableDrawer
        anchor="left"
        open={isNavOpen}
        onClose={handleCloseNav}
        onOpen={handleOpenNav}
        keepMounted
        classes={{
          paper: styles.swipeableDrawerPaper,
        }}
      >
        <Scrollbars autoHide>
          <Paper roundingValue={0} elevation={0}>
            {user ? (
              <UserInfo
                avatar={user.image.url}
                name={`${user.firstName} ${user.lastName}`}
                onClick={() => {
                  handleCloseNav();
                  navigate(routePaths.userProfile());
                }}
              />
            ) : (
              <UserInfoSkeleton />
            )}

            <NavMenu
              isTrainingModeOn={isTrainingModeOn}
              onMenuItemSelect={handleCloseNav}
              logout={logout}
            />
          </Paper>
        </Scrollbars>
      </SwipeableDrawer>
    </Paper>
  ) : (
    <Paper roundingValue={0} elevation={16} className={styles.root}>
      <Scrollbars autoHide>
        <Collapse in={isTrainingModeOn} unmountOnExit timeout="auto">
          <TrainingModeBar onClick={handleClickTrainingModeBar} />
        </Collapse>

        {user ? (
          <UserInfo
            avatar={user.image.url}
            name={`${user.firstName} ${user.lastName}`}
            onClick={() => navigate(routePaths.userProfile())}
          />
        ) : (
          <UserInfoSkeleton />
        )}

        <NavMenu
          isTrainingModeOn={isTrainingModeOn}
          onMenuItemSelect={handleCloseNav}
          logout={logout}
        />
      </Scrollbars>
    </Paper>
  );
});
