import { useContext } from 'react';

import { AlertDispatchContext } from 'context/alert/AlertContextController';

export const useAlert = () => {
  const context = useContext(AlertDispatchContext);

  if (context === undefined) {
    throw new Error('useAlert must be used within an AlertProvider');
  }

  return context;
};
