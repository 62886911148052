import { DataItem } from 'types';

export type TaskRelationType = DataItem & {
  showInDropdown: boolean;
};

export enum TaskRelationTypesEnum {
  'Account' = 1,
  'Appointment' = 2,
  'Claim' = 3,
  'Lead' = 4,
}
