import React, { memo } from 'react';

import { PaperProps } from '@material-ui/core';
import clsx from 'clsx';

import { PaperStyled } from './Paper.styles';
import { PaperExtendedProps } from './Paper.types';

export const Paper: React.FC<PaperProps & PaperExtendedProps> = memo(({ roundingValue, className, ...props }) => (
  <PaperStyled {...props} className={clsx(className, roundingValue !== undefined && `borderRound_${roundingValue}`)} />
));
