import { useState } from 'react';

import ReactPlayer from 'react-player';

export const usePlayer = () => {
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [player, setPlayer] = useState<ReactPlayer>();

  const ref = (player: ReactPlayer) => {
    setPlayer(player);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleProgress = ({ played }: { played: number }) => {
    setPlayed(played);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  return {
    state: {
      playing,
      played,
      duration,
      player,
      setPlaying,
      setPlayed,
      setDuration,
      setPlayer,
    },
    controls: {
      ref,
      handlePlayPause,
      handlePlay,
      handlePause,
      handleProgress,
      handleDuration,
    },
  };
};
