import { createEffect, createEvent, createStore } from "effector";

import { Appointments } from "../types";
import {
  convertEstimateToJob,
  getAppointment,
  markAppointmentCompleted,
  markAppointmentLost,
  rescheduleAppointment,
  sendAppointmentInvoice,
  updateAppointment,
} from "../api";

export const getAppointmentFx = createEffect(getAppointment);
export const updateAppointmentFx = createEffect(updateAppointment);
export const markAppointmentCompletedFx = createEffect(
  markAppointmentCompleted,
);
export const markAppointmentLostFx = createEffect(markAppointmentLost);
export const convertEstimateToJobFx = createEffect(convertEstimateToJob);
export const rescheduleAppointmentFx = createEffect(rescheduleAppointment);
export const sendAppointmentInvoiceFx = createEffect(sendAppointmentInvoice);

export const setAppointmentEv = createEvent<Appointments>();
export const updateAppointmentEv = createEvent<unknown>();
export const resetAppointmentEv = createEvent();

export const $appointmentStore = createStore<{
  appointment: Appointments | null;
  loading: boolean;
  updating: boolean;
}>({
  appointment: null,
  loading: true,
  updating: false,
});

$appointmentStore
  .on(getAppointmentFx.doneData, (store, response) => ({
    ...store,
    appointment: response.data.appointments[0],
  }))
  .on(getAppointmentFx.failData, (store) => ({
    ...store,
    appointment: null,
  }))
  .on(getAppointmentFx.pending, (store, loading) => ({
    ...store,
    loading,
  }))
  .reset(resetAppointmentEv);

$appointmentStore
  .on(updateAppointmentFx.doneData, (store, response) => ({
    ...store,
    appointment: response.data.appointments[0],
  }))
  .on(updateAppointmentFx.pending, (store, loading) => ({
    ...store,
    updating: loading,
  }));

$appointmentStore.on(markAppointmentLostFx.doneData, (store, response) => ({
  ...store,
  appointment: response.data.appointments[0],
}));

$appointmentStore.on(
  markAppointmentCompletedFx.doneData,
  (store, response) => ({
    ...store,
    appointment: response.data.appointments[0],
  }),
);

$appointmentStore.on(setAppointmentEv, (store, appointment) => ({
  ...store,
  appointment,
}));

$appointmentStore.on(updateAppointmentEv, (store, data) => ({
  ...store,
  appointment: store.appointment
    ? { ...store.appointment, ...(typeof data === "object" ? data : {}) }
    : store.appointment,
}));
