// TODO

import { useCallback, useMemo, useState } from 'react';

import { Collapse } from '@material-ui/core';
import moment from 'moment';

import { TaskRelationTypesEnum } from 'entities/tasks';

import { TaskAccountForm } from './features';
import { TaskViewAccountProps, TaskViewSectionProps } from './model';
import { TaskAccountList, ViewSection } from './ui';

export const TaskViewAccount = ({ useNewAccountPage, watch, setValue }: TaskViewAccountProps) => {
  const relations = watch('relations');
  const accountRelations = useMemo(
    () => relations.find(relation => relation.type.value === TaskRelationTypesEnum.Account && !relation.isDeleted),
    [relations],
  );

  const [isRelated, setRelatedStatus] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [foundBy, setFoundBy] = useState<{ phone?: string; email?: string } | undefined>();

  const toggleForm = useCallback(
    () =>
      setVisibility(prev => {
        if (prev) {
          setFoundBy(undefined);
        }

        return !prev;
      }),
    [],
  );

  const action = useMemo(
    () => ({
      title: isRelated && accountRelations ? 'Pending save' : visibility ? 'Close account' : 'Add account',
      onClick: toggleForm,
      disabled: !!(isRelated && accountRelations),
    }),
    [isRelated, accountRelations, visibility, toggleForm],
  );

  // const { sources } = useStore($sourcesStore);
  // const { locationSources } = useStore($locationSourcesStore);

  // const referralSources = useMemo(() => {
  //   const filteredLocationSources = locationSources
  //     .filter(
  //       source =>
  //         !!source.active &&
  //         moment(new Date(), apiDateFormat).isBetween(source.startDate, source.endDate, 'days', '[]'),
  //     )
  //     .map(source => ({
  //       value: source.id,
  //       label: source.name,
  //     }));

  //   return sources
  //     .map(source => ({
  //       value: source.id,
  //       label: source.name,
  //     }))
  //     .concat(filteredLocationSources)
  //     .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  // }, [sources, locationSources]);

  // const { locationId } = useStore($auth);
  // const { location } = useStore($location);
  // const { foundAccounts, findingAccounts } = useStore($accounts);

  // const findAccounts = useCallback(
  //   async (payload: { phone?: string; email?: string }) => {
  //     setFoundBy(payload);

  //     if (locationId) {
  //       await findAccountsFx({ locationId, payload: payload });
  //     }
  //   },
  //   [locationId],
  // );

  // const relateAccount = useCallback(
  //   (account: Account) => {
  //     setValue('relations', [...relations, { value: account.id, type: { label: 'Account', value: 1 } }], {
  //       shouldDirty: true,
  //     });

  //     toggleForm();
  //     setRelatedStatus(true);
  //   },
  //   [relations, setValue, toggleForm],
  // );

  // const createAndRelate = useCallback(
  //   async ({
  //     firstName,
  //     lastName,
  //     phone,
  //     email,
  //     type,
  //     source,
  //   }: {
  //     firstName: string;
  //     lastName: string;
  //     phone: string;
  //     email: string;
  //     type: number;
  //     source: number;
  //   }) => {
  //     if (locationId && location?.address) {
  //       const res = await createAccountFx({
  //         locationId,
  //         payload: {
  //           firstName,
  //           lastName,
  //           phone,
  //           email,
  //           type: { id: type },
  //           source: { id: source },
  //           billingAddress: location.address,
  //         },
  //       });

  //       if (res.data.accounts.length) {
  //         relateAccount(res.data.accounts[0]);
  //       }
  //     }
  //   },
  //   [location?.address, locationId, relateAccount],
  // );

  return (
    <ViewSection title="Contact information" button={action}>
      <Collapse in={!(isRelated && accountRelations) && visibility} unmountOnExit>
        <div>
          {/* <TaskAccountForm
            loading={findingAccounts}
            referralSources={referralSources}
            findAccounts={findAccounts}
            createAndRelate={createAndRelate}
          />

          {foundBy && !findingAccounts && !!foundAccounts.length && (
            <TaskAccountList
              accounts={foundAccounts}
              foundBy={foundBy}
              relateAccount={relateAccount}
              useNewAccountPage={useNewAccountPage}
            />
          )} */}
        </div>
      </Collapse>
    </ViewSection>
  );
};
