import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  searchQueryMatch: {
    color: colors.grey190,
  },
}));
