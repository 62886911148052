import { memo, ReactNode, useCallback, useMemo, useState } from "react";

import {
  SidebarControllerProps,
  SidebarDispatchContext,
  SidebarDispatchContextValue,
  SidebarStateContext,
  SidebarStateContextValue,
} from "./model";

export const SidebarController = memo(
  ({ children }: SidebarControllerProps) => {
    const [isShowSidebar, setIsShowSidebar] = useState(false);
    const [sidebarContent, setSidebarContent] = useState<ReactNode | null>(
      null,
    );
    const [sidebarClassName, setSidebarClassName] = useState("");

    const shouldShowSidebar = useMemo(
      () => isShowSidebar && !!sidebarContent,
      [isShowSidebar, sidebarContent],
    );

    const showSidebar = useCallback(() => {
      setIsShowSidebar(true);
    }, []);

    const hideSidebar = useCallback(() => {
      setIsShowSidebar(false);
    }, []);

    const handleSetSidebarContent = useCallback((content: ReactNode) => {
      setSidebarContent(content);
    }, []);

    const clearSidebarContent = useCallback(() => {
      handleSetSidebarContent(null);
    }, [handleSetSidebarContent]);

    const handleSetSidebarClassName = useCallback((className: string) => {
      setSidebarClassName(className);
    }, []);

    const clearSidebarClassName = useCallback(() => {
      setSidebarClassName("");
    }, []);

    const state: SidebarStateContextValue = useMemo(
      () => ({
        sidebarContent,
        showSidebar: shouldShowSidebar,
        sidebarClassName,
      }),
      [sidebarContent, shouldShowSidebar, sidebarClassName],
    );

    const dispatchValues: SidebarDispatchContextValue = useMemo(() => {
      return {
        hideSidebar,
        showSidebar,
        setSidebarContent: handleSetSidebarContent,
        clearSidebarContent,
        setSidebarClassName: handleSetSidebarClassName,
        clearSidebarClassName,
      };
    }, [
      showSidebar,
      hideSidebar,
      handleSetSidebarContent,
      clearSidebarContent,
      handleSetSidebarClassName,
      clearSidebarClassName,
    ]);

    return (
      <SidebarStateContext.Provider value={state}>
        <SidebarDispatchContext.Provider value={dispatchValues}>
          {children}
        </SidebarDispatchContext.Provider>
      </SidebarStateContext.Provider>
    );
  },
);
