import React, { useMemo } from 'react';

import MaskedInput from 'react-text-mask';

import { TextMaskProps } from './TextMaskProps.types';
import { useFixCaptureAttr } from './useFixCaptureAttr';

export const AlphanumericMask = ({
  inputRef,
  capture,
  allowDigits,
  allowLetters,
  allowSpace,
  allowDash,
  allowUnderscroe,
  ...restProps
}: TextMaskProps) => {
  const fixedCapture = useFixCaptureAttr(capture);

  const regExpStr = useMemo(() => {
    let result = '^[a-zA-Z0-9';

    if (allowDigits) {
      result += '0-9';
    }

    if (allowLetters) {
      result += 'a-zA-Z';
    }

    if (allowSpace) {
      result += '\\ ';
    }

    if (allowDash) {
      result += '\\-';
    }

    if (allowUnderscroe) {
      result += '\\_';
    }

    result += ']+$';

    return result;
  }, [allowDigits, allowLetters, allowSpace, allowDash, allowUnderscroe]);

  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...restProps}
      capture={fixedCapture}
      mask={s => Array.from(s).map(() => new RegExp(regExpStr))}
      guide={false}
    />
  );
};
