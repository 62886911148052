import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Collapse, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ListStyled } from '../SidenavMenu.styles';
import { ArrowRight, colors } from 'assets';

import {
  ListItemStyled,
  ListItemTextStyled,
  NestedListItemStyled,
  NestedListItemTextStyled,
  useStyles,
} from './SidenavMenuItem.styles';
import { SidenavMenuItemProps } from './SidenavMenuItem.types';

export const SidenavMenuItem = memo(
  ({ menuItem, isSelected, isExpanded, selectedParentIndex, setParentSelected, onClick }: SidenavMenuItemProps) => {
    const styles = useStyles();

    const [nestedSelected, setNestedSelected] = useState<number | undefined>();

    const badgeElement = useMemo(() => {
      if (!menuItem.badge || menuItem.badge.visible === false) {
        return null;
      }

      const contentTypeIsString = typeof menuItem.badge.content === 'string';

      return (
        <div className={clsx(styles.badge, menuItem.badge.type)}>
          {contentTypeIsString ? (
            <Typography variant="h5" color="inherit" className={styles.badgeTitle}>
              {menuItem.badge.content}
            </Typography>
          ) : (
            menuItem.badge.content
          )}
        </div>
      );
    }, [menuItem.badge, styles]);

    const handleItemClick = useCallback(() => {
      onClick();

      if (menuItem.onClick) {
        menuItem.onClick();
      }
    }, [menuItem, onClick]);

    const handleNestedItemClick = useCallback(
      (index: number, onClick?: () => void) => {
        if (onClick) {
          onClick();
        }

        setNestedSelected(index);
        setParentSelected(menuItem.parentIndex);
      },
      [menuItem.parentIndex, setParentSelected],
    );

    useEffect(() => {
      setNestedSelected(menuItem?.nestedItems?.findIndex(el => el.isActive));
    }, [menuItem.nestedItems]);

    return (
      <>
        <ListItemStyled button selected={isSelected} onClick={handleItemClick}>
          <div className={styles.listItemContent}>
            <ListItemTextStyled primary={menuItem.element || menuItem.title} />
            {!!badgeElement && badgeElement}
          </div>
          {menuItem.nestedItems && (
            <ArrowRight
              className={clsx(styles.arrowIcon, isExpanded && styles.arrowIconDown)}
              fontSize={12}
              color={colors.basic.black}
            />
          )}
        </ListItemStyled>
        {menuItem.nestedItems && (
          <Collapse
            in={isExpanded}
            addEndListener={() => {
              if (selectedParentIndex !== menuItem.parentIndex) {
                setNestedSelected(undefined);
              }
            }}
            timeout="auto"
            unmountOnExit
            className={styles.nestedMenuCollapse}
          >
            <ListStyled disablePadding>
              {menuItem.nestedItems?.map((nestedItem, index) => (
                <Fragment key={index}>
                  <NestedListItemStyled
                    selected={index === nestedSelected && nestedItem.isActive !== false}
                    button
                    onClick={() => handleNestedItemClick(index, nestedItem.onClick)}
                  >
                    <div className={styles.listItemContent}>
                      <NestedListItemTextStyled primary={nestedItem.title} />
                      {!!badgeElement && badgeElement}
                    </div>
                  </NestedListItemStyled>

                  {nestedItem.separator && <Divider />}
                </Fragment>
              ))}
            </ListStyled>
          </Collapse>
        )}
        {menuItem.separator && <Divider />}
      </>
    );
  },
);
