import { createEffect, createEvent, createStore } from "effector";

import { addDevelopment, editDevelopment, getDevelopments } from "../api";
import { Development, ProcessStatusEnum } from "../types";
import { getDevelopmentsInfo } from "../utils";

export const getDevelopmentsFx = createEffect(getDevelopments);
export const addDevelopmentFx = createEffect(addDevelopment);
export const editDevelopmentFx = createEffect(editDevelopment);

export const recalculateDevelopmentsEv = createEvent();
export const addTimeWorkedEv = createEvent<{ seconds: number }>();
export const addBillableTimeWorkedEv = createEvent<{ seconds: number }>();
export const addTimeTravelledEv = createEvent<{ seconds: number }>();
export const addBillableTimeTravelledEv = createEvent<{ seconds: number }>();
export const resetWorkAndTravelInfo = createEvent();

export const $developments = createStore<{
  developmentsAppointmentId?: number;
  developments: Development[];
  timeWorked: number;
  billableTimeWorked: number;
  workStatus: ProcessStatusEnum;
  workDevelopmentPairs: {
    start: Development;
    end?: Development;
  }[];
  timeTravelled: number;
  billableTimeTravelled: number;
  travelStatus: ProcessStatusEnum;
  travelDevelopmentPairs: {
    start: Development;
    end?: Development;
  }[];
  loading: boolean;
}>({
  developmentsAppointmentId: undefined,
  developments: [],
  timeWorked: 0,
  billableTimeWorked: 0,
  workStatus: ProcessStatusEnum.ToBeDone,
  workDevelopmentPairs: [],
  timeTravelled: 0,
  billableTimeTravelled: 0,
  travelStatus: ProcessStatusEnum.ToBeDone,
  travelDevelopmentPairs: [],
  loading: true,
});

$developments
  .on(getDevelopmentsFx.done, (store, response) => {
    const travelInfo = getDevelopmentsInfo(
      response.result.data.developments,
      "travel",
      response.params.appointmentId,
    );
    const workInfo = getDevelopmentsInfo(
      response.result.data.developments,
      "work",
      response.params.appointmentId,
    );

    return {
      ...store,
      developmentsAppointmentId: response.params.appointmentId,
      developments: response.result.data.developments,
      timeWorked: workInfo.generalTime,
      billableTimeWorked: workInfo.billableTime,
      workStatus: workInfo.developmentStatus,
      workDevelopmentPairs: workInfo.developmentPairs,
      timeTravelled: travelInfo.generalTime,
      billableTimeTravelled: travelInfo.billableTime,
      travelStatus: travelInfo.developmentStatus,
      travelDevelopmentPairs: travelInfo.developmentPairs,
    };
  })
  .on(getDevelopmentsFx.pending, (store, loading) => ({
    ...store,
    loading,
  }));

$developments.on(addDevelopmentFx.done, (store, response) => {
  const isSameAppointmentId =
    store.developmentsAppointmentId === response.params.appointmentId;

  const newDevelopments = [
    response.result.data.developments[0],
    ...(isSameAppointmentId ? store.developments : []),
  ];

  const travelInfo = getDevelopmentsInfo(
    newDevelopments,
    "travel",
    store.developmentsAppointmentId ?? 0,
  );
  const workInfo = getDevelopmentsInfo(
    newDevelopments,
    "work",
    store.developmentsAppointmentId ?? 0,
  );

  return {
    ...store,
    developmentsAppointmentId: isSameAppointmentId
      ? store.developmentsAppointmentId
      : undefined,
    developments: newDevelopments,
    timeWorked: workInfo.generalTime,
    billableTimeWorked: workInfo.billableTime,
    workStatus: workInfo.developmentStatus,
    workDevelopmentPairs: workInfo.developmentPairs,
    timeTravelled: travelInfo.generalTime,
    billableTimeTravelled: travelInfo.billableTime,
    travelStatus: travelInfo.developmentStatus,
    travelDevelopmentPairs: travelInfo.developmentPairs,
  };
});

$developments.on(editDevelopmentFx.done, (store, response) => {
  const isSameAppointmentId =
    store.developmentsAppointmentId === response.params.appointmentId;

  const editedDevelopments = isSameAppointmentId
    ? store.developments.map((development) =>
        development.id === response.params.developmentId
          ? response.result.data.developments[0]
          : development,
      )
    : [response.result.data.developments[0]];

  const travelInfo = getDevelopmentsInfo(
    editedDevelopments,
    "travel",
    response.params.appointmentId,
  );
  const workInfo = getDevelopmentsInfo(
    editedDevelopments,
    "work",
    response.params.appointmentId,
  );

  return {
    ...store,
    developmentsAppointmentId: isSameAppointmentId
      ? store.developmentsAppointmentId
      : undefined,
    developments: editedDevelopments,
    timeWorked: workInfo.generalTime,
    billableTimeWorked: workInfo.billableTime,
    workStatus: workInfo.developmentStatus,
    workDevelopmentPairs: workInfo.developmentPairs,
    timeTravelled: travelInfo.generalTime,
    billableTimeTravelled: travelInfo.billableTime,
    travelStatus: travelInfo.developmentStatus,
    travelDevelopmentPairs: travelInfo.developmentPairs,
  };
});

$developments.on(recalculateDevelopmentsEv, (store) => {
  const travelInfo = getDevelopmentsInfo(
    store.developments,
    "travel",
    store.developmentsAppointmentId ?? 0,
  );
  const workInfo = getDevelopmentsInfo(
    store.developments,
    "work",
    store.developmentsAppointmentId ?? 0,
  );

  return {
    ...store,
    timeWorked: workInfo.generalTime,
    billableTimeWorked: workInfo.billableTime,
    workStatus: workInfo.developmentStatus,
    workDevelopmentPairs: workInfo.developmentPairs,
    timeTravelled: travelInfo.generalTime,
    billableTimeTravelled: travelInfo.billableTime,
    travelStatus: travelInfo.developmentStatus,
    travelDevelopmentPairs: travelInfo.developmentPairs,
  };
});

$developments.on(addTimeWorkedEv, (store, data) => ({
  ...store,
  timeWorked: store.timeWorked + data.seconds,
}));

$developments.on(addBillableTimeWorkedEv, (store, data) => ({
  ...store,
  billableTimeWorked: store.billableTimeWorked + data.seconds,
}));

$developments.on(addTimeTravelledEv, (store, data) => ({
  ...store,
  timeTravelled: store.timeTravelled + data.seconds,
}));

$developments.on(addBillableTimeTravelledEv, (store, data) => ({
  ...store,
  billableTimeTravelled: store.billableTimeTravelled + data.seconds,
}));

$developments.on(resetWorkAndTravelInfo, (store) => {
  if (store.developmentsAppointmentId) {
    const travelInfo = getDevelopmentsInfo(
      store.developments,
      "travel",
      store.developmentsAppointmentId,
    );
    const workInfo = getDevelopmentsInfo(
      store.developments,
      "work",
      store.developmentsAppointmentId,
    );

    return {
      ...store,
      timeWorked: workInfo.generalTime,
      billableTimeWorked: workInfo.billableTime,
      workStatus: workInfo.developmentStatus,
      workDevelopmentPairs: workInfo.developmentPairs,
      timeTravelled: travelInfo.generalTime,
      billableTimeTravelled: travelInfo.billableTime,
      travelStatus: travelInfo.developmentStatus,
      travelDevelopmentPairs: travelInfo.developmentPairs,
    };
  }

  return store;
});
