import { FC, memo, useMemo } from 'react';

import { Collapse, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { Radio } from 'components/radio/Radio';

import { FormRadioButtonGroupProps } from './FormItems.types';

export const FormRadioButtonGroup: FC<FormRadioButtonGroupProps> = memo(
  ({ row = false, variant, groups, radioItem = <Radio />, control, name, required, classes }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    return (
      <FormControl error={!!error} fullWidth>
        <RadioGroup row={row} {...field}>
          {groups?.map(radioButton => {
            const value = radioButton.id.toString();

            return (
              <>
                <FormControlLabel
                  key={radioButton.id}
                  control={radioItem}
                  label={variant === 'pill' ? null : radioButton.name}
                  value={value}
                  classes={classes}
                />

                {!!radioButton.children && (
                  <Collapse in={field.value === value} unmountOnExit>
                    {radioButton.children}
                  </Collapse>
                )}
              </>
            );
          })}
        </RadioGroup>

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
