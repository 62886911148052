import { Box, MenuItem, MenuList, Typography } from '@material-ui/core';

import { useStyles } from './OptionsList.styles';
import { OptionsListProps } from './OptionsList.types';

export const OptionsList = ({
  maxItems,
  options,
  onOptionSelect,
  getHighlightedText,
  onMouseDown,
}: OptionsListProps) => {
  const styles = useStyles({ maxItems });

  return (
    <Box className={styles.optionsContainer}>
      <MenuList className={styles.optionsList}>
        {options.map((option, i) => (
          <MenuItem
            key={i}
            className={styles.optionItem}
            onClick={() => onOptionSelect(option)}
            onMouseDown={onMouseDown}
          >
            <Typography variant="subtitle1" className={styles.optionLabel}>
              {getHighlightedText(option.label)}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};
