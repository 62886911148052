import { FormHelperText, withStyles } from '@material-ui/core';

import { colors } from 'assets';

export const HelperTxt = withStyles(() => ({
  root: {
    fontSize: 12,
    lineHeight: 2,
    color: colors.grey140,
  },
}))(FormHelperText);
