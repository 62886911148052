import { NavigationItemProps } from './navigationItem/NavigationItem.types';

const menuLink = ({ menuItem, user }: NavigationItemProps) =>
  menuItem.shouldAttachMdAgentHash && user && (menuItem.hash || menuItem.getHash)
    ? `${menuItem.link}&viewtype=${
        menuItem.hash ?? (menuItem.getHash ? menuItem.getHash(`${user.first_name} ${user.last_name}`) : '')
      }`
    : menuItem.link;

export const listItemProps = ({ id, menuItem, user }: NavigationItemProps) => {
  if (menuItem.component && menuItem.componentProps) {
    return {
      id,
      component: menuItem.component,
      ...menuItem.componentProps,
    };
  }

  if (!menuItem.isExternalLink) {
    return {
      id,
      component: menuItem.component,
      to: menuLink({ menuItem, user }),
    };
  }

  if (menuItem.isExternalLink) {
    return {
      id,
      component: 'a',
      href: menuLink({ menuItem, user }),
    };
  }

  return {
    id,
    component: 'div',
  };
};
