import { createElement, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { OxfordCapIcon } from 'assets';
import { Button } from 'components/button/Button';

import { StepGraphic, Wrapper } from './Tutorial.styles';
import { useStyles } from './Tutorial.styles';
import { TutorialProps } from './Tutorial.types';

export const Tutorial: FC<TutorialProps> = memo(({ list, onClose }) => {
  const styles = useStyles();
  const [currentId, setCurrentId] = useState(0);

  const current = useMemo(() => list[currentId], [currentId, list]);

  const isFirst = useMemo(() => currentId === 0, [currentId]);

  const isLast = useMemo(() => currentId === list.length - 1, [currentId, list.length]);

  const onPrev = useCallback(() => !isFirst && setCurrentId(currentId - 1), [currentId, isFirst]);

  const onNext = useCallback(() => (!isLast ? setCurrentId(currentId + 1) : onClose()), [currentId, isLast]);

  const [touchStartX, setTouchStartX] = useState<number>(0);
  const [touchEndX, setTouchEndX] = useState<number>(0);

  const onTouchStart = useCallback(e => {
    setTouchStartX(e.changedTouches[0].screenX);
  }, []);

  const onTouchEnd = useCallback(e => {
    setTouchEndX(e.changedTouches[0].screenX);
  }, []);

  useEffect(() => {
    if (touchStartX && touchEndX) {
      if (touchEndX < touchStartX) {
        onNext();
      }
      if (touchEndX > touchStartX) {
        onPrev();
      }

      setTouchStartX(0);
      setTouchEndX(0);
    }
  }, [touchStartX, touchEndX]);

  return (
    <Wrapper onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
      <StepGraphic>{createElement(current.img)}</StepGraphic>

      <Box className={styles.closeButton}>
        <IconButton id="close-tutorial" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <Box mt="55px">
        <Typography variant="h4" component="div" align="center" className={styles.titleFirstPart}>
          {current.titleFirstPart}
        </Typography>

        <Typography variant="h2" component="div" color="primary" align="center" className={styles.titleLastPart}>
          {current.titleLastPart}
        </Typography>
      </Box>

      <Box className={styles.details}>
        <Typography variant="body1" component="div" align="center">
          {current.details}
        </Typography>
      </Box>

      <Box className={styles.note}>
        <Box mr={3}>
          <OxfordCapIcon fontSize={54} />
        </Box>

        <Box flex={1}>
          <Typography variant="h6" component="span" style={{ fontSize: 14, lineHeight: 1.25 }}>
            {current.note}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.bulletNav}>
        <Box mr={1} className={styles.bulletButton}>
          <Button color="primary" fullWidth disabled={isFirst} onClick={onPrev}>
            Back
          </Button>
        </Box>

        <Box display="flex">
          {Array.from(Array(list.length < 10 ? list.length : 10).keys()).map((item, index) => (
            <Box
              key={index}
              className={clsx(styles.bullet, (index === currentId || (index === 9 && currentId > 9)) && 'active')}
            ></Box>
          ))}
        </Box>

        <Box ml={1} className={styles.bulletButton}>
          <Button color="primary" fullWidth onClick={onNext}>
            {isLast ? 'Close' : 'Next'}
          </Button>
        </Box>
      </Box>

      {list.length > 10 && (
        <Typography variant="h5" component="div" align="center" className={styles.bulletText}>
          STEP {currentId + 1}/{list.length}
        </Typography>
      )}
    </Wrapper>
  );
});
