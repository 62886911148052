import React, { createContext, useState, Dispatch, SetStateAction } from 'react';

import { Dialog } from 'components/dialog/Dialog';

import { DialogContextControllerProps, DialogContextStateType } from './DialogContextController.types';

export const DialogContextState = createContext<DialogContextStateType | undefined>(undefined);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const DialogContextDispatch = createContext<Dispatch<SetStateAction<DialogContextStateType>>>(() => {});

export const DialogContextController = ({ children }: DialogContextControllerProps) => {
  const [dialog, setDialog] = useState<DialogContextStateType>({ open: false });
  const {
    open,
    dialogContent,
    variant,
    title,
    onClose,
    classes,
    disablePortal,
    disableBackdropClick,
    fullScreenProps,
    hideCloseButton,
    ...rest
  } = dialog;

  const handleClose = () => {
    setDialog({ open: false });
  };

  return (
    <DialogContextState.Provider value={dialog}>
      <DialogContextDispatch.Provider value={setDialog}>
        <Dialog
          open={open}
          onClose={onClose || handleClose}
          variant={variant}
          title={title}
          classes={classes}
          disablePortal={disablePortal}
          disableBackdropClick={disableBackdropClick}
          fullScreenProps={fullScreenProps}
          hideCloseButton={hideCloseButton}
          {...rest}
        >
          {dialogContent}
        </Dialog>
        {children}
      </DialogContextDispatch.Provider>
    </DialogContextState.Provider>
  );
};
