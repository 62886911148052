import { memo, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { useStore } from "effector-react";

import { ProtectedRoute, UnprotectedRoute } from "features/auth";
import { $appointmentStore } from "features/appointment";
import {
  AppointmentStatus,
  CategoryIdEnum,
  CompletedActionsJobEnum,
} from "shared/types";
import { usePermissions } from "features/permissions";
import { getCompletedAction } from "features/completed-actions";
import { PaymentProviderEnum, usePaymentProvider } from "entities/payments";

import { routes } from "./utils";
import { usePreloadPages } from "./hooks";
import { BlockRouteEnum } from "./types";

export const AppRouting = memo(() => {
  const { appointment } = useStore($appointmentStore);

  usePreloadPages();
  const { administration } = usePermissions();
  const paymentProvider = usePaymentProvider();

  const availableRoutes = useMemo(() => {
    if (appointment?.id) {
      return routes.filter((route) => {
        let isBlockedRoute = false;

        if (route.blockRoute) {
          const results = route.blockRoute.map((blockRoute) => {
            let block: boolean[] = [];

            if (blockRoute === BlockRouteEnum.EST) {
              block.push(appointment.type === "EST");
            }
            if (blockRoute === BlockRouteEnum.JOB_CLOSED) {
              block.push(
                appointment.type === "JOB" &&
                  appointment.status.id === AppointmentStatus.Job.Closed &&
                  !administration.editProductsAfterRoyalties,
              );
            }
            if (blockRoute === BlockRouteEnum.NOT_MOVING) {
              block.push(
                appointment.category.id !== CategoryIdEnum.Moving &&
                  appointment.category.id !== CategoryIdEnum.MoveLabor,
              );
            }
            if (blockRoute === BlockRouteEnum.LW_COMPLETED) {
              block.push(
                !!getCompletedAction(
                  appointment.completedactions,
                  CompletedActionsJobEnum.FinalizeLiabilityWaiver,
                ),
              );
            }
            if (blockRoute === BlockRouteEnum.VAL_COMPLETED) {
              block.push(
                !!getCompletedAction(
                  appointment.completedactions,
                  CompletedActionsJobEnum.FinalizeValuation,
                ),
              );
            }
            if (blockRoute === BlockRouteEnum.BOL_COMPLETED) {
              block.push(
                !!getCompletedAction(
                  appointment.completedactions,
                  CompletedActionsJobEnum.FinalizeBilling,
                ),
              );
            }
            if (blockRoute === BlockRouteEnum.PED_COMPLETED) {
              block.push(
                !!getCompletedAction(
                  appointment.completedactions,
                  CompletedActionsJobEnum.FinalizePreExistingDamage,
                ),
              );
            }
            if (blockRoute === BlockRouteEnum.ADDENDUM_COMPLETED) {
              block.push(
                !!getCompletedAction(
                  appointment.completedactions,
                  CompletedActionsJobEnum.FinalizeAddendum,
                ),
              );
            }
            if (blockRoute === BlockRouteEnum.SQUARE_PAYMENT) {
              block.push(paymentProvider === PaymentProviderEnum.Square);
            }
            if (blockRoute === BlockRouteEnum.HUNKPAY_PAYMENT) {
              block.push(paymentProvider === PaymentProviderEnum.HunkPay);
            }
            if (blockRoute === BlockRouteEnum.MANUAL_PAYMENT) {
              block.push(paymentProvider === PaymentProviderEnum.Manual);
            }

            return block.length > 0 ? block.includes(true) : false;
          });

          isBlockedRoute = results.includes(true);
        }

        return !isBlockedRoute;
      });
    }

    return routes;
  }, [
    appointment?.id,
    appointment?.type,
    appointment?.category.id,
    appointment?.status.id,
    appointment?.completedactions,
    administration.editProductsAfterRoyalties,
    paymentProvider,
  ]);

  return (
    <Routes>
      {availableRoutes.map(
        ({ component, path, nestedRoutes, isProtectedRoute }, index) => (
          <Route
            key={index}
            path={path}
            element={
              isProtectedRoute ? (
                <ProtectedRoute>{component}</ProtectedRoute>
              ) : (
                <UnprotectedRoute>{component}</UnprotectedRoute>
              )
            }
          >
            {nestedRoutes?.map((route, nestedIndex) => (
              <Route
                key={`${index}:${nestedIndex}`}
                path={route.path}
                element={route.component}
              >
                {route.nestedRoutes?.map((routeItem, routeItemIndex) => (
                  <Route
                    key={`${index}:${nestedIndex}:${routeItemIndex}`}
                    path={routeItem.path}
                    element={routeItem.component}
                  />
                ))}
              </Route>
            ))}
          </Route>
        ),
      )}
    </Routes>
  );
});
