import { Theme, makeStyles } from '@material-ui/core';

import { colors, fontWeight } from 'assets';
import { InputSize } from 'components/input/Input.types';

type Props = {
  size?: Exclude<InputSize, 'large'>;
};

const getSize = ({ size }: { size?: Exclude<InputSize, 'large'> }): number => {
  if (!size || size === 'medium') {
    return 44;
  }

  return 36;
};

export const useStyles = makeStyles<Theme, Props>({
  inputWrapper: {
    border: `2px solid ${colors.grey20}`,
    outline: 'none !important',
    borderRadius: '0',
    height: getSize,
    width: 60,
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.basic.white,
  },

  rootInput: {
    // !important due to styling custom MUI input that has a lot of injected styles
    boxShadow: 'none !important',
    outline: 'none !important',
    border: 'none !important',
    borderRadius: 2,

    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },

    '& input': {
      fontSize: '16px !important',
      textAlign: 'center',
      color: `${colors.basic.black} !important`,
      fontWeight: fontWeight.bold,
      padding: ({ size }) => `${!size || size === 'medium' ? '10.5px' : '6.5px'} !important`,
    },
  },

  button: {
    lineHeight: 1,
    border: `2px solid ${colors.grey20}`,
    borderRadius: 2,
    height: getSize,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.basic.white,

    '&:focus': {
      backgroundColor: colors.basic.white,
    },

    '& svg': {
      fontSize: 12,
    },
  },

  buttonDisabled: {
    backgroundColor: `${colors.grey30} !important`,
  },

  buttonText: {
    lineHeight: 1,
    fontSize: 30,
    color: colors.secondary.main,
  },

  error: {
    border: `1px solid ${colors.functionals.alert}`,
  },
});
