import { memo } from 'react';

import { Menu } from '@material-ui/core';

import { listItemProps } from '../Navigation.utils';
import { SubNavigationItem } from '../navigationItem/subNavigationItem/SubNavigationItem';

import { useStyles } from './DropdownMenu.styles';
import { DropdownMenuProps } from './DropdownMenu.types';

export const DropdownMenu = memo(
  ({ anchorEl, open, submenuItems, user, handleSubmenuClose, ...props }: DropdownMenuProps) => {
    const styles = useStyles();

    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ list: styles.list }}
        getContentAnchorEl={null}
        onClose={handleSubmenuClose}
        open={open}
        PopoverClasses={{
          root: styles.popoverRoot,
          paper: styles.popoverPaper,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...props}
      >
        {submenuItems &&
          submenuItems?.length !== 0 &&
          submenuItems?.map((submenuItem, index) => (
            <SubNavigationItem
              listItemProps={listItemProps({ id: submenuItem.id, menuItem: submenuItem, user })}
              key={index}
              subItemName={submenuItem.name}
              badge={submenuItem.badge}
            />
          ))}
      </Menu>
    );
  },
);
