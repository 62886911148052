import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import { PencilIcon } from 'assets';
import { EditableCollapse, FormColorfulSelect, FormInput, FormNewSelect } from 'components';
import { AppointmentCategoryEnum, appointmentCategories } from 'entities/appointments';
import { USADateFormat, apiDateFormat } from 'utils';

import { useStyles } from './assets';
import { LeadFormValues, LeadFormProps, checkTargetDate, leadFormValidationSchema } from './model';

export const LeadForm = memo(({ lead, categories, lostReasons, separator = true, onSubmit }: LeadFormProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const [initialLead] = useState(lead);
  const [isTargetDateEditing, setIsTargetDateEditing] = useState(false);
  const [isReasonEditing, setIsReasonEditing] = useState(false);
  const [isEstimateValueEditing, setIsEstimateValueEditing] = useState(false);

  const defaultValues = useMemo(
    () => ({
      category: initialLead.category.id,
      targetDate: checkTargetDate(initialLead.targetDate) ? moment(initialLead.targetDate).format(USADateFormat) : '',
      reason: initialLead.noSaleReason.id
        ? {
            value: initialLead.noSaleReason.id,
            label: initialLead.noSaleReason.name,
          }
        : null,
      lowEstimate: initialLead.estimatedValue.low ? Number(initialLead.estimatedValue.low) : 0,
      highEstimate: initialLead.estimatedValue.high ? Number(initialLead.estimatedValue.high) : 0,
    }),
    [initialLead],
  );

  const { control, formState, watch, setValue, trigger, clearErrors } = useForm<LeadFormValues>({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(leadFormValidationSchema),
  });

  const [category, targetDate, reason, lowEstimate, highEstimate] = watch([
    'category',
    'targetDate',
    'reason',
    'lowEstimate',
    'highEstimate',
  ]);

  const categoryOptions = useMemo(() => categories.filter(ctgr => ctgr.id in AppointmentCategoryEnum), [categories]);

  const lostReasonOptions = useMemo(
    () =>
      lostReasons
        .sort((a, b) => (Number(a.order) || 0) - (Number(b.order) || 0))
        .map(item => ({
          value: item.id,
          label: item.friendlyName,
        })),
    [lostReasons],
  );

  const handleEdit = useCallback((type: 'date' | 'reason' | 'value-estimate') => {
    setIsTargetDateEditing(false);
    setIsReasonEditing(false);
    setIsEstimateValueEditing(false);

    switch (type) {
      case 'date': {
        setIsTargetDateEditing(true);
        break;
      }

      case 'reason': {
        setIsReasonEditing(true);
        break;
      }

      case 'value-estimate': {
        setIsEstimateValueEditing(true);
        break;
      }
    }
  }, []);

  const handleClickAway = useCallback(
    (type: 'date' | 'reason' | 'value-estimate') => {
      switch (type) {
        case 'date': {
          if (isTargetDateEditing) {
            if (formState.errors.targetDate) {
              setValue('targetDate', defaultValues.targetDate);
              setTimeout(() => {
                clearErrors('targetDate');
              }, 0);
            }

            setIsTargetDateEditing(false);
          }

          break;
        }

        case 'reason': {
          if (isReasonEditing) {
            setIsReasonEditing(false);
          }

          break;
        }

        case 'value-estimate': {
          if (isEstimateValueEditing) {
            setIsEstimateValueEditing(false);
          }

          if (formState.errors.lowEstimate) {
            setValue('lowEstimate', defaultValues.lowEstimate);
            setTimeout(() => {
              clearErrors('lowEstimate');
            }, 0);
          }

          if (formState.errors.highEstimate) {
            setValue('highEstimate', defaultValues.highEstimate);
            setTimeout(() => {
              clearErrors('highEstimate');
            }, 0);
          }

          break;
        }
      }
    },
    [
      defaultValues,
      isEstimateValueEditing,
      isReasonEditing,
      isTargetDateEditing,
      formState.errors.targetDate,
      formState.errors.lowEstimate,
      formState.errors.highEstimate,
      setValue,
      clearErrors,
    ],
  );

  const handleBlur = useCallback(() => {
    onSubmit({
      category: !formState.errors.category && category ? category : initialLead.category.id,
      targetDate: !formState.errors.targetDate
        ? moment(targetDate, 'MMDDYYYY').format(apiDateFormat)
        : initialLead.targetDate,
      reason: !formState.errors.reason && reason ? reason : defaultValues.reason,
      lowEstimate: !formState.errors.lowEstimate ? lowEstimate : initialLead.estimatedValue.low,
      highEstimate: !formState.errors.highEstimate ? highEstimate : initialLead.estimatedValue.high,
    });
  }, [category, targetDate, reason, lowEstimate, highEstimate, formState.errors, defaultValues, initialLead, onSubmit]);

  useEffect(() => {
    trigger('highEstimate');
  }, [lowEstimate]);

  useEffect(() => {
    trigger('lowEstimate');
  }, [highEstimate]);

  return (
    <>
      {separator && <Divider className={styles.divider} />}

      <Grid container spacing={2} component="form" onBlur={handleBlur}>
        <Grid item xs={isMobile ? 6 : undefined} className={clsx(!isMobile && 'gridItem-35')}>
          <Typography component="div" variant="subtitle1" color="secondary">
            Category
          </Typography>

          <FormColorfulSelect
            control={control}
            name="category"
            size="small"
            fitContent
            customPallete={appointmentCategories[category]?.color}
            options={categoryOptions}
            classes={{ root: styles.categorySelectRoot }}
          />
        </Grid>

        <Grid item xs={isMobile ? 6 : undefined} className={clsx(!isMobile && 'gridItem-35')}>
          <EditableCollapse
            isEditing={isTargetDateEditing}
            onClickAway={() => handleClickAway('date')}
            displayContent={
              <>
                <Typography component="div" variant="subtitle1" color="secondary">
                  Target date
                </Typography>

                <div className={styles.contentItem}>
                  {checkTargetDate(targetDate, 'MMDDYYYY') ? (
                    <Typography component="div">{moment(targetDate, 'MMDDYYYY').format(USADateFormat)}</Typography>
                  ) : (
                    '-'
                  )}

                  <IconButton
                    className="iconButtonMedium"
                    onClick={e => {
                      e.stopPropagation();
                      handleEdit('date');
                    }}
                  >
                    <PencilIcon fontSize={18} />
                  </IconButton>
                </div>
              </>
            }
            editContent={
              <div>
                <FormInput
                  control={control}
                  name="targetDate"
                  label="Select a date"
                  mask="date"
                  dateFormat="MM/DD/YYYY"
                  minDate="2000-01-01"
                  calendarAdornment
                  className={styles.editingInput}
                />
              </div>
            }
          />
        </Grid>

        <Grid item xs={6} md={5}>
          <EditableCollapse
            isEditing={isReasonEditing}
            onClickAway={() => handleClickAway('reason')}
            displayContent={
              <>
                <Typography component="div" variant="subtitle1" color="secondary">
                  Reason not booked
                </Typography>

                <div className={styles.contentItem}>
                  <Typography component="div" className={styles.noSaleReason}>
                    {reason?.label || '-'}
                  </Typography>

                  <IconButton
                    className="iconButtonMedium"
                    onClick={e => {
                      e.stopPropagation();
                      handleEdit('reason');
                    }}
                  >
                    <PencilIcon fontSize={18} />
                  </IconButton>
                </div>
              </>
            }
            editContent={
              <div>
                <FormNewSelect
                  control={control}
                  name="reason"
                  label="Reason not booked"
                  virtualize={false}
                  options={lostReasonOptions}
                />
              </div>
            }
          />
        </Grid>

        <Grid item xs={6} md={12}>
          <EditableCollapse
            isEditing={isEstimateValueEditing}
            onClickAway={() => handleClickAway('value-estimate')}
            displayContent={
              <>
                <Typography component="div" variant="subtitle1" color="secondary">
                  Value estimate
                </Typography>

                <div className={styles.contentItem}>
                  <Typography component="div">
                    ${lowEstimate} - {highEstimate}
                  </Typography>

                  <IconButton
                    className="iconButtonMedium"
                    onClick={e => {
                      e.stopPropagation();
                      handleEdit('value-estimate');
                    }}
                  >
                    <PencilIcon fontSize={18} />
                  </IconButton>
                </div>
              </>
            }
            editContent={
              <div className={clsx(styles.contentItem, styles.alignStart)}>
                <FormInput
                  control={control}
                  name="lowEstimate"
                  label="Low range estimate"
                  mask="positive-two-decimal"
                  className={styles.editingInput}
                />

                <FormInput
                  control={control}
                  name="highEstimate"
                  label="High range estimate"
                  mask="positive-two-decimal"
                  className={styles.editingInput}
                />
              </div>
            }
          />
        </Grid>
      </Grid>
    </>
  );
});
