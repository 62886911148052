import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, TooltipProps, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ConfirmationTooltip } from '../../confirmationTooltip/confirmationTooltip';
import { NewSelect } from '../../newSelect/NewSelect';
import { colors } from 'assets';
import { ArrowDown } from 'assets';

import { ToggleWrapper, useStyles } from './TooltipSelect.styles';

type ListItem = { name: string; id: number };

type Props = {
  disabled?: boolean;
  selected: ListItem;
  list: ListItem[];
  onChange: (payload: ListItem) => void;
  toggleVariant?: 'outlined' | 'text';
  typographyVariant?: 'h2' | 'h4' | 'inherit';
  placeholder?: string;
  placement?: TooltipProps['placement'];
  classes?: {
    toggleWrapper?: string;
    title?: string;
  };
};

export const TooltipSelect: FC<Props> = memo(
  ({
    disabled,
    selected,
    list,
    onChange,
    toggleVariant = 'text',
    typographyVariant = 'h2',
    placeholder,
    placement,
    classes,
  }) => {
    const styles = useStyles();
    const [value, setValue] = useState<{ label: string; value: number } | null>({
      label: selected.name,
      value: selected.id,
    });

    const options = useMemo(() => list.map(item => ({ label: item.name, value: item.id })), [list]);

    useEffect(() => {
      setValue({ label: selected.name, value: selected.id });
    }, [selected]);

    const onConfirm = useCallback(() => {
      if (value) {
        onChange({
          name: value.label,
          id: value.value,
        });
      }
    }, [onChange, value]);

    const onClose = useCallback(() => {
      setValue({ label: selected.name, value: selected.id });
    }, [selected]);

    return (
      <ConfirmationTooltip
        disabled={disabled}
        placement={placement}
        toggle={
          <ToggleWrapper
            className={clsx(
              toggleVariant === 'text' && styles.text,
              toggleVariant === 'outlined' && styles.outlined,
              disabled && styles.disabled,
              classes?.toggleWrapper,
            )}
          >
            <Box mr={1}>
              <Typography
                color={toggleVariant === 'text' ? 'initial' : disabled ? 'textSecondary' : 'primary'}
                variant={toggleVariant === 'text' ? typographyVariant : 'subtitle1'}
                className={classes?.title}
              >
                {selected.name}
              </Typography>
            </Box>

            <ArrowDown color={disabled ? colors.grey140 : colors.primary.main} />
          </ToggleWrapper>
        }
        onConfirm={onConfirm}
        onClose={onClose}
      >
        <NewSelect
          value={value}
          error={false}
          isSearchable={false}
          onChange={val => setValue(val)}
          placeholder={placeholder}
          name="list"
          options={options}
          virtualize={false}
        />
      </ConfirmationTooltip>
    );
  },
);
