import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  root: {
    color: colors.basic.white,
    borderRadius: 8,
    backgroundColor: colors.functionals.disabled,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  zeroRounded: {
    borderRadius: 0,
  },
  rootOutlined: {
    color: colors.functionals.disabled,
    border: `1px solid ${colors.grey30}`,
    backgroundColor: colors.grey10,
  },
  title: {
    whiteSpace: 'pre-wrap',
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
  successType: {
    backgroundColor: colors.functionals.success,

    '&$rootOutlined': {
      color: colors.functionals.success,
      border: `1px solid ${colors.functionals.success}`,
      backgroundColor: colors.functionals.successLight,
    },
  },
  errorType: {
    backgroundColor: colors.functionals.alert,

    '&$rootOutlined': {
      color: colors.functionals.alert,
      border: `1px solid ${colors.functionals.alert}`,
      backgroundColor: colors.functionals.alertLight,
    },
  },
  infoType: {
    backgroundColor: colors.functionals.info,

    '&$rootOutlined': {
      color: colors.functionals.info,
      border: `1px solid ${colors.functionals.info}`,
      backgroundColor: colors.functionals.infoLight,
    },
  },
  warningType: {
    backgroundColor: colors.functionals.warning,

    '&$rootOutlined': {
      color: colors.functionals.warning,
      border: `1px solid ${colors.functionals.warning}`,
      backgroundColor: colors.functionals.warningLight,
    },
  },
}));
