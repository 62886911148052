import { makeStyles, Theme, createStyles, withStyles, List } from '@material-ui/core';

import { colors } from 'assets';

export const ListStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #ECEFF3',
      padding: 0,
      '& .MuiListItem-root:nth-child(even)': {
        backgroundColor: colors.grey10,
      },
    },
  }),
)(List);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noAlertsContainer: {
      padding: '20px 0 12px',
      '& .MuiTypography-root': {
        color: colors.functionals.alert,
      },
    },
  }),
);
