import { useCallback, useEffect, useState } from 'react';

import { ConfirmationDialog } from 'components/confirmationDialog/ConfirmationDialog';
import { useDialogDispatch } from 'hooks/useDialog/useDialog';

import { ConfirmationTitle } from './Confirmation.title';
import { CancelFnc, ConfirmFnc, UseConfirmDialogParams } from './ConfirmationDialog.types';

export const useConfirmationDialog = ({
  title,
  titleType,
  message,
  confirmButtonText,
  cancelButtonText,
  confirmationButtonType,
  cancelButtonType,
  confirmationButtonColor,
  cancelButtonColor,
  hideConfirmButton,
  hideCancelButton,
  maxWidth,
  hideDialogCloseButton,
}: UseConfirmDialogParams) => {
  const setDialog = useDialogDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>();
  const [onConfirm, setConfirmFnc] = useState<ConfirmFnc | null>(null);
  const [onCancel, setCancelFnc] = useState<CancelFnc>();

  const onClose = useCallback(
    (type: 'cancel' | 'closeDialog' = 'cancel') => {
      setConfirmFnc(null);
      setIsConfirmationDialogOpen(false);

      setDialog({ open: false, variant: 'custom' });

      if (type !== 'closeDialog' && onCancel) {
        onCancel();
      }
    },
    [onCancel, setDialog],
  );

  const openConfirmation = useCallback((confirmFnc: ConfirmFnc, cancelFnc?: CancelFnc) => {
    setIsConfirmationDialogOpen(true);
    setConfirmFnc(() => confirmFnc);
    setCancelFnc(() => cancelFnc);
  }, []);

  useEffect(() => {
    if (isConfirmationDialogOpen) {
      setDialog({
        open: true,
        variant: 'basic',
        title: <ConfirmationTitle title={title} titleType={titleType} />,
        dialogContent: (
          <ConfirmationDialog
            onConfirm={onConfirm as ConfirmFnc}
            onClose={onClose}
            message={message}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            confirmationButtonType={confirmationButtonType}
            cancelButtonType={cancelButtonType}
            confirmationButtonColor={confirmationButtonColor}
            cancelButtonColor={cancelButtonColor}
            isLoading={isLoading}
            hideConfirmButton={hideConfirmButton}
            hideCancelButton={hideCancelButton}
            maxWidth={maxWidth}
          />
        ),
        onClose,
        disableBackdropClick: true,
        hideCloseButton: hideDialogCloseButton,
      });
    } else if (isConfirmationDialogOpen === false) {
      onClose('closeDialog');
    }
  }, [
    title,
    titleType,
    cancelButtonText,
    confirmButtonText,
    confirmationButtonType,
    cancelButtonType,
    confirmationButtonColor,
    cancelButtonColor,
    hideCancelButton,
    hideConfirmButton,
    hideDialogCloseButton,
    maxWidth,
    message,
    isConfirmationDialogOpen,
    isLoading,
    onClose,
    onConfirm,
    setDialog,
  ]);

  return { closeConfirmation: onClose, openConfirmation, setLoader: setLoading, isOpen: isConfirmationDialogOpen };
};
