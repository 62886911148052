import { FC, memo, useMemo } from 'react';

import { FormControl } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { TextEditor } from 'components/textEditor/TextEditor';
import { TextEditorProps } from 'components/textEditor/TextEditor.types';

import { FormInputProps } from './FormItems.types';

export const FormTextEditor: FC<FormInputProps & Partial<TextEditorProps>> = memo(
  ({ control, name, required, ...rest }) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    const helperText = useMemo(() => {
      if (error && rest.error !== false) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, rest.error, required]);

    return (
      <FormControl error={rest.error ?? !!error} fullWidth>
        <TextEditor {...rest} {...field} error={rest.error ?? !!error} />

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
