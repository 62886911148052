import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

import { SvgIconProps } from './SvgIcon.types';

export const PencilIcon = ({ className, color = colors.primary.main, fontSize }: SvgIconProps) => {
  return (
    <SvgIcon
      className={className}
      style={{ fontSize, fill: 'none' }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      fontSize="inherit"
    >
      <path
        d="M18.1239 1.87611C17.5664 1.31858 16.8496 1 16.0531 1C15.2566 1 14.5398 1.31858 13.9823 1.87611L2.43363 13.4248L1 18.9204L6.49558 17.4867L18.1239 6.0177C18.6814 5.46018 19 4.74336 19 3.9469C19 3.15044 18.6814 2.43363 18.1239 1.87611Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6641 2.27435L17.726 6.3363"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.43359 13.4248L6.49554 17.4868"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
