import React from 'react';

import clsx from 'clsx';

import { Loader } from '../loader/Loader';

import { ButtonStyled, useStyles } from './Button.styles';
import { ButtonProps } from './Button.types';

export const Button: React.FC<ButtonProps> = React.forwardRef(
  ({ buttonType, isLoading, children, color, size = 'medium', labelClasses, ...props }, ref) => {
    const styles = useStyles(size, isLoading);
    let variant: 'text' | 'outlined' | 'contained' | undefined;
    let calculatedColor: 'inherit' | 'default' | 'primary' | 'secondary' | undefined = color;

    switch (buttonType) {
      case 'twoTone':
        break;
      case 'filled':
        variant = 'contained';
        calculatedColor = 'secondary';
        break;
      case 'outlined':
        variant = 'outlined';
        break;
      case 'outlinedError':
        variant = 'outlined';
        break;
      case 'text':
        variant = 'text';
        break;
      default:
        variant = 'text';
    }

    return (
      <div className={clsx(styles.buttonContainer, props.className)}>
        <ButtonStyled
          {...props}
          className={clsx(
            buttonType === 'twoTone' ? styles.twoTone : '',
            buttonType === 'outlinedError' ? styles.outlinedError : '',
          )}
          variant={variant}
          type={props.type ? props.type : 'button'}
          color={calculatedColor}
          ref={ref}
          size={size}
          disableRipple
          disableElevation
          classes={{
            ...props,
            root: clsx(styles.root, props.classes?.root),
          }}
        >
          <div
            className={clsx(
              labelClasses,
              buttonType === 'twoTone' && styles.twoToneContent,
              buttonType === 'twoTone' && size === 'large' && styles.twoToneContentLarge,
              buttonType === 'twoTone' && size === 'small' && styles.twoToneContentSmall,
            )}
          >
            {children}
          </div>
        </ButtonStyled>

        {!!isLoading && (
          <div className={styles.loader}>
            <Loader size="small" />
          </div>
        )}
      </div>
    );
  },
);
