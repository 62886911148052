import { DataItem, InfoModify } from 'types/common';

export interface TaskSwimlane {
  id: number;
  name: string;
  order: number;
  canBeDeleted?: boolean;
  canBeEdited?: boolean;
  type: DataItem;
  created?: InfoModify;
  showTasks?: boolean;
}

export enum TaskSwimlaneTypesEnum {
  ToDo = 1,
  InProgress = 2,
  Done = 3,
}
