import { useCallback, useMemo, useState, useEffect } from 'react';

import { Pagination as MuiPagination, PaginationItem, Skeleton } from '@material-ui/lab';

import { useStyles } from './PaginateList.styles';
import { PaginateListProps } from './PaginateList.types';

export function usePaginateList<T>({ list, loading, perPage = 10, onChange, ...props }: PaginateListProps<T>) {
  const styles = useStyles();

  const [currentPage, setCurrentPage] = useState(1);

  const currentList = useMemo(
    () => list.slice((currentPage - 1) * perPage, (currentPage - 1) * perPage + perPage),
    [currentPage, list, perPage],
  );

  const count = useMemo(() => Math.ceil(list.length / perPage), [list, perPage]);

  useEffect(() => {
    if (count > 0 && currentPage > count) {
      setCurrentPage(count);
    }
  }, [count, currentPage]);

  const renderPaginate = useCallback(() => {
    return (
      <div className={styles.paginationBox}>
        {!loading && count > 1 ? (
          <MuiPagination
            {...props}
            page={currentPage}
            count={count}
            color="primary"
            onChange={(_, page) => {
              setCurrentPage(page);

              if (onChange) {
                onChange(page);
              }
            }}
            renderItem={item => <PaginationItem className={styles.paginationItem} {...item} />}
          />
        ) : (
          ''
        )}

        {loading && (
          <div className={styles.skeletonRoot}>
            <Skeleton variant="circle" width={20} height={20} />
            <Skeleton variant="circle" width={32} height={32} />
            <Skeleton variant="circle" width={32} height={32} />
            <Skeleton variant="circle" width={32} height={32} />
            <Skeleton variant="circle" width={20} height={20} />
          </div>
        )}
      </div>
    );
  }, [loading, props, count, currentPage, onChange]);

  return { currentPage, list: currentList, count, renderPaginate, setCurrentPage };
}
