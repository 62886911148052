import React from 'react';

import MaskedInput from 'react-text-mask';

import { TextMaskProps } from './TextMaskProps.types';
import { useFixCaptureAttr } from './useFixCaptureAttr';

// used 'r' for name simplify. It's regex for only letters and numbers.
const r = /^[a-zA-Z0-9]$/;

export const CorporateCodeMask = ({ inputRef, capture, ...restProps }: TextMaskProps) => {
  const fixedCapture = useFixCaptureAttr(capture);

  return (
    <MaskedInput
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...restProps}
      capture={fixedCapture}
      mask={[r, r, '-', r, r, '-', r, r, '-', r, r]}
    />
  );
};
