import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const ClockIcon = ({ className, color = colors.primary.main }: { className?: string; color?: string }) => {
  return (
    <SvgIcon
      className={className}
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      fontSize="inherit"
      style={{ width: 18, height: 18 }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 16.2C12.9765 16.2 16.2 12.9765 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 12.9765 5.02355 16.2 9 16.2ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0001 5.1C9.49715 5.1 9.9001 5.50294 9.9001 6V9C9.9001 9.49706 9.49715 9.9 9.0001 9.9C8.50304 9.9 8.1001 9.49706 8.1001 9V6C8.1001 5.50294 8.50304 5.1 9.0001 5.1Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3637 8.3636C8.71517 8.01213 9.28502 8.01213 9.63649 8.3636L13.3865 12.1136C13.738 12.4651 13.738 13.0349 13.3865 13.3864C13.035 13.7379 12.4652 13.7379 12.1137 13.3864L8.3637 9.63639C8.01223 9.28492 8.01223 8.71507 8.3637 8.3636Z"
        fill={color}
      />
    </SvgIcon>
  );
};
