import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';

import { Box, FormControl, IconButton, Typography } from '@material-ui/core';
import { useController } from 'react-hook-form';

import { PencilIcon, colors } from 'assets';
import { HelperTxt } from 'components/helperTxt/HelperTxt';
import { TypographyInput } from 'components/typographyInput/TypographyInput';
import { TypographyInputProps } from 'components/typographyInput/TypographyInput.types';

import { FormItem } from './FormItems.types';

export const FormTypographyInput: FC<FormItem & TypographyInputProps> = memo(
  ({ control, className, name, required, ...rest }) => {
    const {
      field: { onBlur, ...field },
      fieldState: { error },
    } = useController({
      name,
      control,
      defaultValue: undefined,
    });

    const helperText = useMemo(() => {
      if (error) {
        return error.message;
      }

      if (required) {
        return '*required';
      }

      return '';
    }, [error, required]);

    const ref = useRef<HTMLInputElement | null>();
    const [editMode, setEditMode] = useState(false);

    const editModeOn = useCallback(() => {
      setEditMode(true);

      setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
        }
      }, 0);
    }, []);

    const editModeOff = useCallback(() => {
      setEditMode(false);
    }, []);

    return (
      <FormControl error={!!error} fullWidth className={className}>
        <Box display={editMode ? 'flex' : 'none'} alignItems="center" minHeight={42}>
          <TypographyInput
            {...rest}
            {...field}
            inputRef={ref}
            required={required}
            error={!!error}
            autoFocus
            onBlur={() => {
              editModeOff();
              onBlur();
            }}
          />
        </Box>

        <Box
          display={!editMode ? 'flex' : 'none'}
          alignItems="center"
          minHeight={42}
          style={{ wordBreak: 'break-word' }}
        >
          <Typography variant="h4" component="span">
            {field.value || <Box style={{ opacity: 0.5 }}>{rest.placeholder}</Box>}
          </Typography>

          <Box ml={1} display="inline-block">
            <IconButton onClick={editModeOn} disabled={rest.disabled}>
              <PencilIcon color={rest.disabled ? colors.grey60 : colors.primary.main} fontSize={18} />
            </IconButton>
          </Box>
        </Box>

        {helperText && <HelperTxt>{helperText}</HelperTxt>}
      </FormControl>
    );
  },
);
