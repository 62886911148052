import { FC, memo, useCallback, useState } from 'react';

import { Box, IconButton } from '@material-ui/core';

import { NewDropdownMenu } from '../../dropdownMenu/newDropdownMenu/NewDropdownMenu';
import { GeneralTopbarProps } from '../GeneralTopbar.types';
import { colors, KebabMenuIcon } from 'assets';

import { useStyles } from './ActionMenus.styles';

export const KebabMenu: FC<Pick<GeneralTopbarProps, 'kebabMenuItems' | 'handleKebabMenuMenuItemClick'>> = memo(
  ({ kebabMenuItems, handleKebabMenuMenuItemClick }) => {
    const styles = useStyles();
    const [menuPosition, setMenuPosition] = useState<HTMLButtonElement | null>(null);

    const handleDropdownMenuOpen = useCallback(
      (event: { currentTarget: HTMLButtonElement }) => {
        setMenuPosition(event.currentTarget);
      },
      [setMenuPosition],
    );

    const handleKebubMenuItemsClose = useCallback(() => {
      setMenuPosition(null);
    }, [setMenuPosition]);

    const handleNestedMenuItemClick = useCallback(
      (event: React.MouseEvent) => {
        handleKebabMenuMenuItemClick && handleKebabMenuMenuItemClick(event);
        setMenuPosition(null);
      },
      [setMenuPosition, handleKebabMenuMenuItemClick],
    );

    return (
      <Box>
        <IconButton edge="end" onClick={handleDropdownMenuOpen} className={styles.iconButtonMediumVertMargin}>
          <KebabMenuIcon className={styles.toggleIcon} color={colors.primary.main} />
        </IconButton>

        <NewDropdownMenu
          isNestedMenu
          open={!!menuPosition}
          anchorEl={menuPosition}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          menuItems={kebabMenuItems}
          handleNestedMenuItemClick={handleNestedMenuItemClick}
          onClose={handleKebubMenuItemsClose}
        />
      </Box>
    );
  },
);
