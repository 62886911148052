import { memo, useCallback, useEffect, useRef } from 'react';

import clsx from 'clsx';
import { Moment } from 'moment';

import { Loader } from 'components/loader/Loader';

import { CalendarPickerStyled, useStyles } from './CalendarPicker.styles';
import { CalendarPickerProps } from './CalendarPicker.types';
import { weekDays } from './CalendarPicker.utils';

export const CalendarPicker = memo(
  ({ className, hideArrows, hideSelectedDay, onClickMonthName, onClickWeekDayName, ...props }: CalendarPickerProps) => {
    const styles = useStyles({
      hideArrows,
      hideSelectedDay,
      isMonthNameClickable: !!onClickMonthName,
      isWeekDayClickable: !!onClickWeekDayName,
    });

    const calendarContainerRef = useRef<HTMLDivElement | null>(null);

    const handleClickMonthName = useCallback(
      (event: Event) => {
        if (onClickMonthName) {
          onClickMonthName(event, props.date as Moment);
        }
      },
      [props.date, onClickMonthName],
    );

    const handleClickWeekDayName = useCallback(
      (event: Event) => {
        if (onClickWeekDayName) {
          const weekDay = weekDays.findIndex(weekDay => weekDay === (event.target as HTMLElement).innerText);
          if (weekDay < 0) {
            return;
          }

          onClickWeekDayName(event, props.date as Moment, weekDay);
        }
      },
      [props.date, onClickWeekDayName],
    );

    useEffect(() => {
      if (!onClickMonthName || !calendarContainerRef.current) {
        return;
      }

      const monthNameElement = calendarContainerRef.current.getElementsByClassName(
        'MuiPickersCalendarHeader-transitionContainer',
      )[0];

      if (!monthNameElement) {
        return;
      }

      monthNameElement.addEventListener('click', handleClickMonthName);

      return () => {
        monthNameElement.removeEventListener('click', handleClickMonthName);
      };
    }, [handleClickMonthName]);

    useEffect(() => {
      if (!onClickWeekDayName || !calendarContainerRef.current) {
        return;
      }

      const weekDayNames = Array.from(
        calendarContainerRef.current.getElementsByClassName('MuiPickersCalendarHeader-dayLabel'),
      );

      weekDayNames.forEach(weekDayName => {
        weekDayName.addEventListener('click', handleClickWeekDayName);
      });

      return () => {
        weekDayNames.forEach(weekDayName => {
          weekDayName.removeEventListener('click', handleClickWeekDayName);
        });
      };
    }, [handleClickWeekDayName]);

    return (
      <div ref={calendarContainerRef} className={clsx(styles.calendarPicker, !!className && className)}>
        <CalendarPickerStyled {...props} loadingIndicator={<Loader size="medium" />} />
      </div>
    );
  },
);
