import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  toggleIcon: {
    width: 16,
    height: 16,
  },
  iconButtonMediumVertMargin: {
    marginTop: -12,
    marginBottom: -12,
  },
});
