import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const CalendarIcon = ({ className, color = colors.primary.main }: { className?: string; color?: string }) => {
  return (
    <SvgIcon className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" fontSize="inherit">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5447 1.63636H14.7265V0H13.0901V1.63636H4.90829V0H3.27193V1.63636H2.45375C1.55375 1.63636 0.817383 2.37273 0.817383 3.27273V16.3636C0.817383 17.2636 1.55375 18 2.45375 18H15.5447C16.4447 18 17.181 17.2636 17.181 16.3636V3.27273C17.181 2.37273 16.4447 1.63636 15.5447 1.63636ZM15.5447 16.3636H2.45375V5.72727H15.5447V16.3636Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};
