import { createEffect, createStore } from "effector";

import { getLocation } from "../api";
import { Location } from "../types";

type LocationStore = {
  location: Location | null;
  loading: boolean;
};

export const $location = createStore<LocationStore>({
  location: null,
  loading: true,
});

export const getLocationFx = createEffect(getLocation);

$location
  .on(getLocationFx.doneData, (store, response) => ({
    ...store,
    location: response.data.locations[0],
  }))
  .on(getLocationFx.pending, (store, loading) => ({
    ...store,
    loading: loading,
  }));
