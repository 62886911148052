import { DataItem, InfoModify } from "shared/types";

export type Swimlane = {
  id: number;
  name: string;
  order: number;
  canBeDeleted?: boolean;
  canBeEdited?: boolean;
  type: DataItem;
  created?: InfoModify;
  showTasks?: boolean;
};

export enum SwimlaneTypesEnum {
  ToDo = 1,
  InProgress = 2,
  Done = 3,
}
