import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 16,
  },
  root: {
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },
  actionButton: {
    marginRight: -12,
  },
}));
