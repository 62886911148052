import { createStyles, fade, makeStyles, Theme, withStyles, Select } from '@material-ui/core';

import { fontWeight } from 'assets';
import { colors } from 'assets/theme/palette';

export const SelectStyled = withStyles((theme: Theme) =>
  createStyles({
    select: {
      textAlign: 'initial',
    },
    nativeInput: {
      display: 'none',
    },
  }),
)(Select);

export const useStyles = makeStyles(() => ({
  helperText: {
    '& *': {
      fontSize: 12,
    },
  },
  root: {
    '& .MuiInputBase-root': {
      color: colors.grey190,
      '& .MuiSelect-root:focus': {
        background: 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      right: '4px',
      fontWeight: fontWeight.normal,
      color: colors.secondary.main,
    },
    '& .MuiInputLabel-root': {
      fontWeight: fontWeight.bold,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      '&.MuiInputLabel-shrink': {
        fontWeight: fontWeight.normal,
      },
    },
    '& .select-placeholder': {
      fontWeight: fontWeight.bold,
    },
    '&:not($rootError)': {
      '& .MuiInputLabel-root': {
        color: fade(colors.grey190, 0.6),
      },
      '& .select-placeholder': {
        color: fade(colors.grey190, 0.6),
      },
    },
  },
  rootSmall: {
    '& .MuiSelect-label': {
      fontSize: '14px',
    },
    '& .MuiSelect-root': {
      fontSize: '14px',
      padding: '3px 16px 3px 10px',
    },
    '& .MuiSelect-icon': {
      fontSize: '14px',
      margin: '6px 0 6px 6px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      '&:not(.MuiInputLabel-shrink)': {
        transform: 'translate(6px, 24px) scale(1)',
        width: 'calc(100% - 22px)',
      },
    },
  },
  rootMedium: {
    '& .MuiInputLabel-root + .MuiInput-root input': {
      fontSize: '16px',
      margin: '0 5px',
      paddingTop: '10px',
    },
    '& .MuiSelect-label': {
      fontSize: '16px',
    },
    '& .MuiSelect-root': {
      fontSize: '16px',
      padding: '6px 29px 6px 14px',
    },
    '& .MuiSelect-icon': {
      fontSize: '16px',
      margin: '4px 0 4px 4px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      '&:not(.MuiInputLabel-shrink)': {
        transform: 'translate(8px, 25px) scale(1)',
        width: 'calc(100% - 26px)',
      },
    },
  },
  rootLarge: {
    '& .MuiSelect-label': {
      fontSize: '18px',
    },
    '& .MuiSelect-root': {
      padding: '9px 32px 9px 14px',
      fontSize: '18px',
    },
    '& .MuiSelect-icon': {
      fontSize: '18px',
      margin: '2px 0 2px 2px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '18px',
      '&:not(.MuiInputLabel-shrink)': {
        transform: 'translate(10px, 26px) scale(1)',
        width: 'calc(100% - 30px)',
      },
    },
  },
  rootPill: {
    lineHeight: 1,
    color: colors.primary.main,
    minHeight: 'unset',
    '&:not($rootError) .MuiSelect-root': {
      color: colors.primary.main,
    },
    '&:not($rootError) .select-placeholder': {
      color: colors.primary.main,
    },
    '&:not($rootError) .MuiSelect-icon': {
      color: colors.primary.main,
    },
    '&:not($rootError) .MuiInputLabel-root': {
      color: colors.primary.main,
    },
    '&:not($rootError) .MuiOutlinedInput-root:hover': {
      backgroundColor: colors.primary.superlight,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.functionals.alert,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary.main,
      },
      '&.MuiOutlinedInput-root.Mui-focused': {
        // backgroundColor: colors.primary.superlight,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.primary.dark,
          borderWidth: '1px',
        },
      },
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      background: colors.basic.white,
      padding: '0 7px',
    },
    '&$rootSmall': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiInputLabel-root + .MuiInput-root input': {
        fontSize: '14px',
        margin: '0 4px',
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(6px, -4px) scale(0.8)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(10px, 8px) scale(1)',
          width: 'calc(100% - 26px)',
        },
      },
    },
    '&$rootMedium': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiSelect-icon': {
        marginRight: 6,
      },
      '& .MuiInputLabel-root + .MuiInput-root input': {
        fontSize: '16px',
        margin: '0 5px',
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(10px, -5px) scale(0.7)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(14px, 10px) scale(1)',
          width: 'calc(100% - 31px)',
        },
      },
    },
    '&$rootLarge': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiSelect-icon': {
        marginRight: 8,
      },
      '& .MuiInputLabel-root + .MuiInput-root input': {
        fontSize: '18px',
        margin: '0 6px',
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.6)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(18px, 12px) scale(1)',
          width: 'calc(100% - 36px)',
        },
      },
    },
  },
  rootOutlined: {
    background: colors.basic.white,
    border: `1px solid ${colors.grey20}`,
    borderRadius: '4px',
    transition: 'border-color 200ms ease 0s, box-shadow 200ms ease 0s ',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px!important',
    },
    '&:focus-within': {
      borderColor: colors.primary.main,
      boxShadow: `0px 0px 8px ${colors.primary.main}3d`,
    },
    '& .MuiInputLabel-root + .MuiOutlinedInput-root': {
      marginTop: '20px',
    },
    '& .MuiInputLabel-root': {
      color: fade(colors.grey190, 0.6),
    },
    '& .MuiInputBase-root': {
      color: colors.secondary.main,
      fontWeight: fontWeight.bold,
    },
    '& .MuiSelect-root span:not(.select-placeholder)': {
      color: colors.secondary.main,
      fontWeight: fontWeight.bold,
    },
    '&$rootSmall': {
      '& .MuiInputLabel-root + .MuiInputBase-root': {
        '& .MuiSelect-icon': {
          margin: '-1px 0 0 6px',
        },
        '& input': {
          fontSize: '14px',
          margin: '0 4px',
        },
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(10px, 6px) scale(0.8)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(10px, 16px) scale(1)',
          width: 'calc(100% - 26px)',
        },
      },
    },
    '&$rootMedium': {
      '& .MuiInputLabel-root + .MuiInputBase-root': {
        '& .MuiSelect-icon': {
          margin: '-3px 0 0 4px',
        },
        '& input': {
          fontSize: '16px',
          margin: '0 5px',
        },
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(13px, 7px) scale(0.7)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(13px, 18px) scale(1)',
          width: 'calc(100% - 31px)',
        },
      },
    },
    '&$rootLarge': {
      '& .MuiInputLabel-root + .MuiInputBase-root': {
        '& .MuiSelect-icon': {
          margin: '-5px 0 0 2px',
        },
        '& input': {
          fontSize: '18px',
          margin: '0 6px',
        },
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(16px, 8px) scale(0.6)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(16px, 20px) scale(1)',
          width: 'calc(100% - 36px)',
        },
      },
    },
  },
  rootClean: {
    lineHeight: 1,
    color: colors.primary.main,
    minHeight: 'unset',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:not($rootError) .MuiSelect-root': {
      // color: colors.primary.main,
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
    },
    '&:not($rootError) .select-placeholder': {
      color: colors.primary.main,
    },
    '&:not($rootError) .MuiSelect-icon': {
      color: colors.primary.main,
    },
    '&:not($rootError) .MuiInputLabel-root': {
      color: colors.primary.main,
    },
    '&:not($rootError) .MuiOutlinedInput-root:hover': {
      backgroundColor: colors.grey20,
      // background: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.functionals.alert,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary.main,
      },
      '&.MuiOutlinedInput-root.Mui-focused': {
        // backgroundColor: colors.primary.superlight,
        background: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.primary.dark,
          borderWidth: '1px',
        },
      },
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      background: colors.basic.white,
      padding: '0 7px',
    },
    '&$rootSmall': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiSelect-icon': {
        marginRight: 4,
      },
      '& .MuiInputLabel-root + .MuiInput-root input': {
        fontSize: '14px',
        margin: '0 4px',
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(6px, -4px) scale(0.8)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(10px, 8px) scale(1)',
          width: 'calc(100% - 26px)',
        },
      },
    },
    '&$rootMedium': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiSelect-icon': {
        marginRight: 6,
      },
      '& .MuiInputLabel-root + .MuiInput-root input': {
        fontSize: '16px',
        margin: '0 5px',
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(10px, -5px) scale(0.7)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(14px, 10px) scale(1)',
          width: 'calc(100% - 31px)',
        },
      },
    },
    '&$rootLarge': {
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
      '& .MuiSelect-icon': {
        marginRight: 8,
      },
      '& .MuiInputLabel-root + .MuiInput-root input': {
        fontSize: '18px',
        margin: '0 6px',
      },
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.6)',
        },
        '&:not(.MuiInputLabel-shrink)': {
          transform: 'translate(18px, 12px) scale(1)',
          width: 'calc(100% - 36px)',
        },
      },
    },
  },
  rootDisabled: {
    backgroundColor: colors.grey20,
  },
  rootError: {
    borderColor: colors.functionals.alert,
    '&  .MuiSelect-root': {
      color: colors.functionals.alert,
    },
    '& .select-placeholder': {
      color: colors.functionals.alert,
    },
    '& .MuiInputLabel-root': {
      color: colors.functionals.alert,
    },
    '& .MuiSelect-icon': {
      color: colors.functionals.alert,
    },
  },
}));
