import { useState, useRef, useCallback, useEffect, useMemo, memo } from 'react';

import { Paper, MenuItem, Popper, Fade, InputAdornment } from '@material-ui/core';
import { Check as CheckIcon, Search as SearchIcon } from '@material-ui/icons';
import clsx from 'clsx';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';

import { Input } from 'components/input/Input';
import { regExpSpecialSymbols } from 'utils';

import { useStyles } from './AddressAutocomplete.styles';
import { AddressAutocompleteProps } from './AddressAutocomplete.types';
import { createAddress } from './AddressAutocomplete.utils';

/**
 * IMPORTANT:
 * To use this component, you are going to need to load Google Maps JavaScript API
 */
export const AddressAutocomplete = memo(
  ({
    inputValue: initialInputValue = '',
    onSelect,
    withSearchIcon,
    checkFullness,
    searchOptions: searchOptionsProp,
    ...inputProps
  }: AddressAutocompleteProps) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const styles = useStyles({ dropdownWidth: anchorRef.current?.offsetWidth });

    const [inputValue, setInputValue] = useState<string>(initialInputValue);
    const [isAddressSelected, setIsAddressSelected] = useState(!!inputValue);
    const [isAddressFull, setIsAddressFull] = useState(!!inputValue);

    const searchOptions = useMemo(
      () => ({
        componentRestrictions: { country: ['us', 'ca'] },
        types: ['address'],
        ...searchOptionsProp,
      }),
      [searchOptionsProp],
    );

    const getHighlightedText = useCallback(
      (text: Suggestion['formattedSuggestion']) => {
        const regExpValue = inputValue.replace(regExpSpecialSymbols, '\\$&');
        const parts = text.mainText.split(new RegExp(`(${regExpValue})`, 'gi'));
        const highlightIndex = parts.findIndex(part => part.toLowerCase() === inputValue.toLowerCase());

        return (
          <span>
            {parts.map((part, i) => (
              <span key={i} className={clsx(i === highlightIndex && styles.searchQueryMatch, styles.mainText)}>
                {part}
              </span>
            ))}
            {', '}
            {text.secondaryText}
          </span>
        );
      },
      [inputValue, styles],
    );

    const handleChange = useCallback(
      (value: string) => {
        setInputValue(value);

        if (inputProps.onInputChange) {
          inputProps.onInputChange(value);
        }

        if (isAddressSelected) {
          onSelect(null);
          setIsAddressSelected(false);
        }
      },
      [inputProps, isAddressSelected, onSelect],
    );

    const handleSelect = useCallback(
      async (value: string) => {
        setInputValue(value);

        const fullAddress = await createAddress(value);

        const isBroken = Object.keys(fullAddress).some(key => key !== 'address2' && fullAddress[key] === '');
        setIsAddressFull(!isBroken);

        onSelect({ inputValue: value, fullAddress });
        setIsAddressSelected(true);

        inputRef.current?.blur();
      },
      [onSelect],
    );

    useEffect(() => {
      setInputValue(initialInputValue);
    }, [initialInputValue]);

    return (
      <div ref={anchorRef}>
        <PlacesAutocomplete
          value={inputValue}
          searchOptions={searchOptions}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            const { onBlur: aCInputOnBlur, ...restAcInputProps } = getInputProps();
            const { ref, error, onBlur, className, ...restProps } = inputProps;

            return (
              <>
                <Input
                  startAdornment={
                    withSearchIcon && (
                      <InputAdornment position="start">
                        <SearchIcon color="secondary" />
                      </InputAdornment>
                    )
                  }
                  endAdornment={
                    isAddressSelected &&
                    !error &&
                    (!checkFullness || isAddressFull) && (
                      <InputAdornment position="end">
                        <CheckIcon className={styles.checkIcon} />
                      </InputAdornment>
                    )
                  }
                  {...restProps}
                  {...restAcInputProps}
                  error={error}
                  variant="outlined"
                  size="medium"
                  inputRef={inputRef}
                  className={clsx(className, styles.input)}
                  onBlur={e => {
                    aCInputOnBlur(e);
                    onBlur && onBlur(e);
                  }}
                />

                <Popper
                  transition
                  placement="bottom"
                  open={!!suggestions.length}
                  anchorEl={anchorRef.current}
                  className={styles.dropdown}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <Paper elevation={1}>
                        {loading && (
                          <MenuItem className={clsx(styles.dropdownItem, styles.mainText)}>Loading...</MenuItem>
                        )}

                        {suggestions.map(suggestion => (
                          <MenuItem
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.placeId}
                            className={clsx(styles.dropdownItem, suggestion.active && styles.activeDropdownItem)}
                          >
                            {getHighlightedText(suggestion.formattedSuggestion)}
                          </MenuItem>
                        ))}
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </>
            );
          }}
        </PlacesAutocomplete>
      </div>
    );
  },
);
