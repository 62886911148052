import { useCallback } from 'react';

import moment from 'moment-timezone';

import { USADateTimeFormat, apiDateTimeFormat } from 'utils';

export const useLocationTimezone = (timeZone: string) => {
  const utcToZone = useCallback(
    (dateTime: string, format?: string) => {
      const date = moment(dateTime);

      if (date.isValid()) {
        return moment
          .utc(dateTime)
          .tz(timeZone)
          .format(format || USADateTimeFormat);
      }

      throw new Error('Invalid date');
    },
    [timeZone],
  );

  const zoneToUTC = useCallback((dateTime: string) => {
    const date = moment(dateTime);

    if (date.isValid()) {
      return moment(dateTime).utc().format(apiDateTimeFormat);
    }

    throw new Error('Invalid date');
  }, []);

  return { utcToZone, zoneToUTC };
};
