import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const CalendarFlipIcon = ({
  className,
  color = colors.grey100,
  fontSize = 16,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => {
  return (
    <SvgIcon
      className={className}
      style={{ fill: 'none', fontSize }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      fontSize="inherit"
    >
      <path
        d="M15.75 2.25C16.5784 2.25 17.25 2.92157 17.25 3.75V6H0.75V3.75C0.75 2.92157 1.42157 2.25 2.25 2.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.75C9.82843 3.75 10.5 3.07843 10.5 2.25C10.5 1.42157 9.82843 0.75 9 0.75C8.17157 0.75 7.5 1.42157 7.5 2.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3.75C15.0784 3.75 15.75 3.07843 15.75 2.25C15.75 1.42157 15.0784 0.75 14.25 0.75C13.4216 0.75 12.75 1.42157 12.75 2.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.5 2.25H12.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.75 3.75C4.57843 3.75 5.25 3.07843 5.25 2.25C5.25 1.42157 4.57843 0.75 3.75 0.75C2.92157 0.75 2.25 1.42157 2.25 2.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.25 2.25H7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.25 2.25C1.4226 2.25247 0.752471 2.9226 0.75 3.75V15.75C0.752471 16.5774 1.4226 17.2475 2.25 17.25H12.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 2.25C16.5774 2.25247 17.2475 2.9226 17.25 3.75V12.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 17.25V12.75H17.25L12.75 17.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 9H3.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75 9H7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 12H3.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75 12H7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 15H3.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75 15H7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 9H11.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.25 9H15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};
