import { fade, makeStyles, Theme } from '@material-ui/core';

import { colors } from 'assets';

const OPTION_ITEM_HEIGHT = 38;

type Props = {
  maxItems: number;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  optionsContainer: {
    position: 'absolute',
    top: 'calc(100% + 1px)',
    width: '100%',
    backgroundColor: colors.basic.white,
    zIndex: 1000,
  },
  optionsList: {
    padding: 0,
    maxHeight: ({ maxItems }) => maxItems * OPTION_ITEM_HEIGHT,
    overflowY: 'scroll',
  },
  optionItem: {
    backgroundColor: colors.basic.white,
    padding: '5px 12px',
    '&:nth-child(odd)': {
      backgroundColor: colors.grey10,
    },
  },
  optionLabel: {
    color: fade(colors.grey190, 0.6),
  },
}));
