import { useStore } from "effector-react";
import { useMemo } from "react";

import { $location } from "features/auth";
import { PaymentProviderEnum, PaymentVendorEnum } from "../types";
import { isHunkPayEnabled } from "shared/config";
import { $settings, PaymentPlatformEnum } from "features/settings";

export const usePaymentProvider = (): PaymentProviderEnum | null => {
  const { location } = useStore($location);
  const { paymentPlatform } = useStore($settings);

  const paymentProvider = useMemo(() => {
    if (!location) {
      return null;
    }

    if (paymentPlatform.value !== PaymentPlatformEnum.Default) {
      return paymentPlatform.value as PaymentProviderEnum;
    }

    if (!isHunkPayEnabled) {
      return PaymentProviderEnum.Square;
    } else if (location.paymentVendor.id === PaymentVendorEnum.HunkPay) {
      return PaymentProviderEnum.HunkPay;
    } else if (location.paymentVendor.id === PaymentVendorEnum.Square) {
      return PaymentProviderEnum.Square;
    } else {
      const isSquareValid =
        location.integrations.square.active &&
        location.integrations.square.valid;
      const isHunkPayValid = location.integrations.hunkpay.valid;

      if (isSquareValid && isHunkPayValid) {
        return PaymentProviderEnum.HunkPay;
      } else if (isHunkPayValid) {
        return PaymentProviderEnum.HunkPay;
      } else if (isSquareValid) {
        return PaymentProviderEnum.Square;
      } else {
        return PaymentProviderEnum.Manual;
      }
    }
  }, [location, paymentPlatform.value]);

  return paymentProvider;
};
