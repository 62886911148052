import { memo } from 'react';

import { default as MUIImage, ImageProps as MUIImageProps } from 'material-ui-image';

import { Loader } from 'components/loader/Loader';

import { imageStyle, style } from './Image.styles';

export const Image = memo(
  ({ animationDuration, style: styleProp, imageStyle: imageStyleProp, loading, ...rest }: MUIImageProps) => (
    <MUIImage
      animationDuration={animationDuration ?? 1000}
      style={{ ...style, ...styleProp }}
      imageStyle={{ ...imageStyle, ...imageStyleProp }}
      loading={loading ?? <Loader />}
      {...rest}
    />
  ),
);
