import { memo, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { TasksGroup, UserTaskCard, UserTaskCardSkeleton } from 'entities';

import { TasksGroupSkeleton } from 'entities/tasks/ui/tasks-group/skeleton';
import { usePaginateList } from 'hooks';
import { groupBy } from 'utils';

import { useStyles } from './assets';
import { TasksGroupsListProps } from './model';

export const TasksGroupsList = memo(({ tasks, isMobile, loading, onTaskClick }: TasksGroupsListProps) => {
  const styles = useStyles();

  const {
    list: paginatedTasks,
    count: pagesCount,
    renderPaginate,
  } = usePaginateList({
    list: tasks,
    loading,
    perPage: 20,
  });

  const paginatedTasksByCategory = useMemo(
    () => groupBy(paginatedTasks, task => task.swimlane.type.name),
    [paginatedTasks],
  );

  const thereAreNoTasks = useMemo(() => !paginatedTasks.length, [paginatedTasks.length]);

  return (
    <div className={styles.root}>
      {loading ? (
        <>
          <TasksGroupSkeleton renderCard={() => <UserTaskCardSkeleton isMobile={isMobile} />} />
          <TasksGroupSkeleton renderCard={() => <UserTaskCardSkeleton isMobile={isMobile} />} />
        </>
      ) : (
        <>
          {!thereAreNoTasks ? (
            Object.entries(paginatedTasksByCategory).map(([title, tasks]) => (
              <TasksGroup
                key={title}
                title={title}
                tasks={tasks}
                renderCard={task => (
                  <UserTaskCard key={task.id} task={task} isMobile={isMobile} onClick={onTaskClick} />
                )}
              />
            ))
          ) : (
            <Typography variant="body1">There are no tasks for display.</Typography>
          )}

          {pagesCount > 1 && <>{renderPaginate()}</>}
        </>
      )}
    </div>
  );
});
