export const style = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: 56,
  paddingTop: 0,
  backgroundColor: 'inherit',
};

export const imageStyle = {
  position: 'relative',
  width: 'inherit',
  height: 'inherit',
};
