import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import { colors } from 'assets';

export const AccordionStyled = withStyles(() =>
  createStyles({
    root: {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: colors.functionals.warningLight,
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  }),
)(Accordion);

export const AccordionSummaryStyled = withStyles(() =>
  createStyles({
    root: {
      order: 2,
      minHeight: '24px',
      maxHeight: '24px',
      '&.Mui-expanded': {
        minHeight: '24px',
        maxHeight: '24px',
      },
    },
    content: {
      flexGrow: 0,
    },
  }),
)(AccordionSummary);

export const AccordionDetailsStyled = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: colors.white,
      display: 'block',
      paddingTop: 0,
    },
  }),
)(AccordionDetails);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white,
      '& .MuiGrid-spacing-xs-3': {
        width: 'calc(100% + 25px)',
      },
      '& .MuiGrid-spacing-xs-4': {
        width: 'calc(100% + 33px)',
      },
      border: `1px solid ${colors.grey30}`,
      overflow: 'hidden',
    },
    desktopRoot: {
      padding: '24px 24px 24px 48px',
    },
    link: {
      textDecoration: 'none',
      display: 'block',
    },
    orangeHoverEffect: {
      width: '100%',
      maxWidth: 'fit-content',
      '&:hover': {
        borderRadius: '4px',
        padding: '0 4px',
        margin: '0 4px 0 -4px',
        backgroundColor: colors.primary.superlight,

        '& .MuiTypography-root': {
          color: colors.primary.main,
        },
      },
    },
    orangeHoverEffectPhone: {
      '&:hover': {
        '& path': {
          fill: colors.primary.main,
        },
      },
    },
    orangeHoverEffectEmail: {
      '&:hover': {
        '& path': {
          stroke: colors.primary.main,
        },
      },
    },
    dayOfJob: {
      cursor: 'pointer',
    },
    infoBlock: {
      flex: 1,
    },
    appointmentInfo: {
      width: '33.65%',
      minWidth: 'fit-content',
    },
    accountAndDateInfo: {
      flexGrow: 1,
      flexBasis: 0,
    },
    accountInfo: {
      width: '230px',
      flex: 1,
    },
    dateInfo: {
      minWidth: 'fit-content',
      flex: 1,
    },
    franchiseInfo: {
      flex: 1,
    },
    categoryContainer: {
      [theme.breakpoints.up('sm')]: {
        marginTop: '4px',
      },
      width: 'fit-content',
    },
    statusContainer: {
      marginTop: '8px',
    },
    statusTooltipContainer: {
      '& .MuiTypography-root': {
        marginRight: '4px',
      },
    },
    accountInfoItem: {
      marginTop: '15px',
    },
    accountInfoNameContainer: {
      cursor: 'pointer',
      width: 'fit-content',
    },
    accountInfoName: {
      color: colors.secondary.main,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'right',
      },
    },
    phoneIcon: {
      width: '20px',
      height: '20px',
    },
    mailIcon: {
      width: '20px',
      height: '15px',
    },
    navigationPinIcon: {
      width: '13px',
      height: '20px,',
      marginTop: '4px',
    },
    clockIcon: {
      width: '18px',
      height: '18px',
    },
    clockIconMargin: {
      marginTop: '5px',
    },
    calendarIcon: {
      width: '18px',
      height: '18px',
    },
    plusIcon: {
      width: '16px',
      height: '16px',
    },
    kebabMenuIcon: {
      width: '16px',
      height: '16px',
    },
    accountEmailContainer: {
      width: 'calc(100% - 30px)',
    },
    emailNameContainer: {
      overflow: 'hidden',
    },
    icon: {
      display: 'block',
    },
    pencilIcon: {
      fontSize: '14px',
    },
    statusSelect: {
      '& .MuiTypography-root': {
        textTransform: 'uppercase',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'bold',
      },
    },
    schedulesContainer: {
      marginTop: '1px',
    },
    timeContainer: {
      position: 'relative',
      '& p:nth-child(n+3)': {
        '&:after': {
          content: "''",
          display: 'block',
          width: '100%',
          height: '27px',
          position: 'absolute',
          bottom: 0,
          background: `linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)`,
        },
      },
    },
    timeContainerNotExpand: {
      position: 'relative',
      '& p:nth-child(n+3)': {
        '&:after': {
          content: "''",
          display: 'block',
          width: '100%',
          height: '17px',
          position: 'absolute',
          bottom: 0,
          background: `linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)`,
        },
      },
    },
    eyeIconButton: {
      marginTop: '-8px',
      marginBottom: '-12px',
    },
    setScheduleButton: {
      '& .MuiButton-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    iconButtonMediumMargin: {
      margin: '-12px',
      display: 'block',
    },
    iconButtonMediumVertMargin: {
      marginTop: '-12px',
      marginBottom: '-12px',
    },
    mobileRoot: {
      padding: '16px 16px 12px',
    },
    accordionContainer: {
      margin: '0 -16px -12px',
    },
    statusSelectContainer: {
      width: 'fit-content',
      padding: '16px 0',
    },
    accountInfoMobileItem: {
      marginTop: '10px',
    },
    accordionDetailsItem: {
      padding: '16px 0',
    },
    customerInfoContainer: {
      margin: '0 -16px -16px',
    },
    categoryAndNameBlock: {
      marginTop: '4px',
    },
    categoryItem: {
      minWidth: 'fit-content',
    },
    dateAndTimeBlock: {
      marginTop: '19px',
      marginBottom: '12px',
    },
    dateAndTimeBlockMultiDayJob: {
      marginTop: '11px',
      marginBottom: '12px',
    },
    mobileDayOfJobContainer: {
      marginTop: '8px',
      width: 'fit-content',
    },

    alignSelf: {
      alignSelf: 'center',
    },

    muiGridContVertPaddingNone: {
      '& .MuiGrid-item': {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },

    idTypeContainer: {
      width: 'fit-content',
    },

    jobTypesTooltip: {
      width: 105,
    },
    jobTypesTooltipTitle: {
      fontWeight: 'bold',
      color: colors.grey140,
      fontSize: '18px',
    },

    convertedJobLink: {
      color: colors.primary.main,
      marginLeft: 8,
    },

    locationName: {
      cursor: 'pointer',

      '&:hover': {
        color: colors.primary.main,
      },
    },
  }),
);
