import { Box, Typography } from '@material-ui/core';

import { colors } from 'assets';

export const NasaBar = () => {
  return (
    <Box
      bgcolor={colors.secondary.main}
      color={colors.white}
      px={{ xs: 2, md: 4 }}
      py={{ xs: 0.5, md: 1 }}
      borderRadius="4px 4px 0 0"
    >
      <Typography variant="h4" color="inherit">
        NASA Partner
      </Typography>
    </Box>
  );
};
