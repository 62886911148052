export const USADateFormat = 'MM/DD/YYYY';
export const USADateFormatShort = 'M/D/YY';

export const USATimeFormatShort = 'hh:mm A';
export const USATimeFormatAmPm = 'h:mm A';

export const USADateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const USADateTimeFormatAmPm = 'MM/DD/YYYY h:mm A';

export const apiDateFormat = 'YYYY-MM-DD';
export const apiDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
