import { makeStyles } from '@material-ui/core';

import { colors, RiftFont } from 'assets';

export type StyleProps = {
  hashForLetter: number;
};

const arrOfBgColors = [
  colors.complementary.blue.shade02,
  colors.complementary.red.shade02,
  colors.complementary.green.shade02,
  colors.complementary.yellow.shade02,
];

const getColor = (arr: string[], hash: number) => {
  return arr[hash % arr.length];
};

export const useStyles = makeStyles({
  root: {
    margin: '6px 0px',
    padding: '8px 4px',
    borderRadius: 4,
  },
  hover: {
    color: colors.primary.main,
    backgroundColor: colors.primary.superlight,

    '& $arrowDown': {
      color: colors.primary.main,
    },
  },
  arrowDown: {
    color: colors.grey80,
  },
  avatar: {
    width: 32,
    height: 32,
    border: `2px solid ${colors.grey40}`,
  },
  expandMoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  colorDefault: (props: StyleProps) => ({
    backgroundColor: getColor(arrOfBgColors, props.hashForLetter),
    color: colors.white,
    fontFamily: RiftFont,
    fontSize: 24,
    fontWeight: 'bold',
  }),
});
