import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { SvgIconProps } from './SvgIcon.types';

export const NavigationPinIcon = ({ className, fontSize = 20 }: SvgIconProps) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="15" height="22" viewBox="0 0 15 22" fill="none">
      <svg width="15" height="22" viewBox="0 0 15 22" fill="none">
        <path
          d="M7.45278 1C11.0972 1 14.1194 4.02222 14.1194 7.66667C14.1194 10.6889 9.58611 18.3333 7.98611 20.7333C7.89722 20.9111 7.63056 21 7.45278 21C7.18611 21 7.00833 20.9111 6.91944 20.7333C5.31944 18.2444 0.875 10.6889 0.875 7.57778C0.875 3.93333 3.80833 1 7.45278 1Z"
          stroke="#A0ADC2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.45297 10.9555C9.31964 10.9555 10.8308 9.4444 10.8308 7.57773C10.8308 5.71106 9.31964 4.19995 7.45297 4.19995C5.58631 4.19995 4.0752 5.71106 4.0752 7.57773C4.0752 9.4444 5.6752 10.9555 7.45297 10.9555Z"
          stroke="#A0ADC2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
