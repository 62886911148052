import { makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = () => {
  const style = makeStyles({
    message: {},
    loader: {
      height: '100%',
      width: '100%',
    },
    loaderXS: {
      '& $container': {
        width: '10px',
        height: '10px',
      },
      '& $quarter': {
        borderTopWidth: '2px',
        borderLeftWidth: '2px',
        borderRadius: '5px 0 0  0',
        width: '5px',
        height: '5px',
      },
      '& $message': {
        fontSize: '8px',
        marginTop: '8px',
        fontWeight: 1,
      },
    },
    loaderSmall: {
      '& $container': {
        width: '20px',
        height: '20px',
      },
      '& $quarter': {
        borderTopWidth: '2px',
        borderLeftWidth: '2px',
        borderRadius: '10px 0 0  0',
        width: '10px',
        height: '10px',
      },
      '& $message': {
        fontSize: '10px',
        marginTop: '10px',
        fontWeight: 1,
      },
    },
    loaderMedium: {
      '& $container': {
        width: '40px',
        height: '40px',
      },
      '& $quarter': {
        borderTopWidth: '4px',
        borderLeftWidth: '4px',
        borderRadius: '20px 0 0  0',
        width: '20px',
        height: '20px',
      },
      '& $message': {
        fontSize: '13px',
        marginTop: '13px',
        fontWeight: 1,
      },
    },
    loaderLarge: {
      '& $container': {
        width: '56px',
        height: '56px',
      },
      '& $quarter': {
        borderTopWidth: '6px',
        borderLeftWidth: '6px',
        borderRadius: '28px 0 0  0',
        width: '28px',
        height: '28px',
      },
      '& $message': {
        fontSize: '16px',
        marginTop: '16px',
        fontWeight: 1,
      },
    },
    container: {
      position: 'relative',
    },
    circle: {
      animation: '$rotation 1s infinite linear',
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
    quarter: {
      border: `1px solid ${colors.primary.main}`,
      borderRightWidth: '0',
      borderBottomWidth: '0',
      position: 'relative',
      boxSizing: 'content-box',
    },
    '@keyframes rotation': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(359deg)' },
    },
  });

  return style();
};
