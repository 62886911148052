import { InputMask } from './Input.types';
import { NumberFormat, dateFormat, dateRangeFormat, humanDateFormat } from './numberFormat/NumberFormat';
import { NumberFormatProps } from './numberFormat/NumberFormat.types';
import { AlphanumericMask } from './textMask/AlphanumericMask';
import { CorporateCodeMask } from './textMask/CorporateCodeMask';
import { DollarAmountMask } from './textMask/DollarAmountMask';
import { PhoneMask } from './textMask/PhoneMask';
import { PostalMask } from './textMask/PostalMask';
import { TextMaskProps } from './textMask/TextMaskProps.types';

export const MaskComponent = (mask: InputMask) => {
  if (Array.isArray(mask)) {
    return (props: TextMaskProps) =>
      AlphanumericMask({
        ...props,
        allowDigits: mask.includes('digits'),
        allowLetters: mask.includes('letters'),
        allowSpace: mask.includes('space'),
        allowDash: mask.includes('dash'),
        allowUnderscroe: mask.includes('underscore'),
      });
  }

  switch (mask) {
    case 'postal':
      return (props: TextMaskProps) => PostalMask(props);
    case 'dollars':
      return (props: TextMaskProps) => DollarAmountMask(props);
    case 'phone':
      return (props: TextMaskProps) => PhoneMask(props);
    case 'corporate-code':
      return (props: TextMaskProps) => CorporateCodeMask(props);
    case 'integer':
      return (props: NumberFormatProps) => NumberFormat({ ...props, decimalScale: 0 });
    case 'decimal':
      return (props: NumberFormatProps) => NumberFormat({ ...props, decimalScale: 1 });
    case 'two-decimal':
      return (props: NumberFormatProps) => NumberFormat({ ...props, decimalScale: 2 });
    case 'positive-integer':
      return (props: NumberFormatProps) => NumberFormat({ ...props, decimalScale: 0, allowNegative: false });
    case 'positive-integer-with-zero':
      return (props: NumberFormatProps) =>
        NumberFormat({ ...props, decimalScale: 0, allowNegative: false, allowLeadingZeros: true });
    case 'positive-decimal':
      return (props: NumberFormatProps) => NumberFormat({ ...props, decimalScale: 1, allowNegative: false });
    case 'positive-two-decimal':
      return (props: NumberFormatProps) => NumberFormat({ ...props, decimalScale: 2, allowNegative: false });
    case 'date':
      return (props: NumberFormatProps) => NumberFormat({ ...props, format: dateFormat });
    case 'date-range':
      return (props: NumberFormatProps) => NumberFormat({ ...props, format: dateRangeFormat });
    /* For now, human-date mask works only for inputs connected with <Controller /> (react-hook-form) component,
      cause onChange prop shouldn't be passed, that's because in date input, user cannot change value from keyboard,
      only by setting it manually by setValue (react-hook-form) */
    case 'human-date':
      return (props: NumberFormatProps) => NumberFormat({ ...props, isNumericString: true, format: humanDateFormat });
  }
};
