import { useState, useMemo } from 'react';

import { Grid, IconButton, Menu, MenuItem, SvgIcon, Typography } from '@material-ui/core';
import moment from 'moment';

import { colors } from 'assets';
import { integerRange } from 'utils';

import { useStyles } from './Header.styles';
import { CalendarRangePickerHeaderProps } from './Header.types';

export const CalendarRangePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: CalendarRangePickerHeaderProps) => {
  const styles = useStyles();

  const [yearAnchorEl, setYearAnchorEl] = useState<null | HTMLElement>(null);
  const [monthAnchorEl, setMonthAnchorEl] = useState<null | HTMLElement>(null);

  const integerRangeValue = useMemo(
    () =>
      integerRange(2000, moment(new Date()).get('year'))
        .sort((a, b) => b - a)
        .map(year => (
          <MenuItem
            key={year}
            value={year}
            onClick={() => {
              changeYear(year);
              setYearAnchorEl(null);
            }}
            selected={year === moment(date).get('year')}
          >
            {year}
          </MenuItem>
        )),
    [changeYear, date],
  );

  return (
    <Grid container alignItems="center" direction="row" wrap="nowrap" justify="space-between">
      <Grid item>
        <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className={styles.monthButton}>
          <SvgIcon width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
              strokeWidth="2"
              stroke={colors.primary.main}
            />
          </SvgIcon>
        </IconButton>
      </Grid>

      <Grid item>
        <Grid container item alignItems="center" direction="row" wrap="nowrap">
          <Typography
            variant="h6"
            onClick={event => setMonthAnchorEl(event.currentTarget)}
            color="primary"
            className={styles.headerSelect}
          >
            {moment(date).format('MMMM')}
          </Typography>
          <Menu anchorEl={monthAnchorEl} open={Boolean(monthAnchorEl)} onClose={() => setMonthAnchorEl(null)}>
            {integerRange(1, 12).map(monthNumber => (
              <MenuItem
                key={monthNumber}
                onClick={() => {
                  changeMonth(monthNumber - 1);
                  setMonthAnchorEl(null);
                }}
                selected={monthNumber === moment(date).get('month') + 1}
              >
                {moment(monthNumber, 'M').format('MMMM')}
              </MenuItem>
            ))}
          </Menu>
          <Typography
            variant="h6"
            onClick={event => setYearAnchorEl(event.currentTarget)}
            color="primary"
            className={styles.headerSelect}
          >
            {moment(date).get('year')}
          </Typography>
          <Menu anchorEl={yearAnchorEl} open={Boolean(yearAnchorEl)} onClose={() => setYearAnchorEl(null)}>
            {integerRangeValue}
          </Menu>
        </Grid>
      </Grid>

      <Grid item>
        <IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled} className={styles.monthButton}>
          <SvgIcon width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
              strokeWidth="2"
              stroke={colors.primary.main}
            />
          </SvgIcon>
        </IconButton>
      </Grid>
    </Grid>
  );
};
