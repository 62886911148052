import { Grid, IconButton, SvgIcon, Typography } from '@material-ui/core';
import moment from 'moment';

import { colors } from 'assets';

import { useStyles } from './Header.styles';
import { CalendarRangePickerHeaderProps } from './Header.types';

export const CalendarRangePickerYearHeader = ({
  date,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: CalendarRangePickerHeaderProps) => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" direction="row" wrap="nowrap" justify="space-around">
      <Grid item>
        <IconButton onClick={decreaseYear} disabled={prevMonthButtonDisabled} className={styles.yearButton}>
          <SvgIcon width="18" height="12" viewBox="0 0 18 12">
            <path
              d="M18 6C18 5.44772 17.5523 5 17 5H3.83L6.70647 2.11549C7.09518 1.7257 7.09474 1.09474 6.70549 0.705492C6.31586 0.315859 5.68414 0.315859 5.29451 0.705492L0.707107 5.29289C0.316583 5.68342 0.316583 6.31658 0.707107 6.70711L5.29451 11.2945C5.68414 11.6841 6.31586 11.6841 6.70549 11.2945C7.09474 10.9053 7.09518 10.2743 6.70647 9.88451L3.83 7H17C17.5523 7 18 6.55228 18 6Z"
              color={colors.primary.main}
            />
          </SvgIcon>
        </IconButton>
      </Grid>

      <Grid item>
        <Grid container item alignItems="center" direction="row" wrap="nowrap">
          <Typography variant="h6">{moment(date).get('year')}</Typography>
        </Grid>
      </Grid>

      <Grid item>
        <IconButton onClick={increaseYear} disabled={nextMonthButtonDisabled} className={styles.yearButton}>
          <SvgIcon width="18" height="12" viewBox="0 0 18 12">
            <path
              d="M5.24537e-07 6C4.76254e-07 6.55228 0.447716 7 1 7L14.17 7L11.2935 9.88451C10.9048 10.2743 10.9053 10.9053 11.2945 11.2945C11.6841 11.6841 12.3159 11.6841 12.7055 11.2945L17.2929 6.70711C17.6834 6.31658 17.6834 5.68342 17.2929 5.29289L12.7055 0.705492C12.3159 0.315859 11.6841 0.315858 11.2945 0.705491C10.9053 1.09474 10.9048 1.7257 11.2935 2.11549L14.17 5L0.999999 5C0.447714 5 5.72819e-07 5.44771 5.24537e-07 6Z"
              color={colors.primary.main}
            />
          </SvgIcon>
        </IconButton>
      </Grid>
    </Grid>
  );
};
