import { useCallback } from 'react';

import { Table } from '@tanstack/react-table';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import generateExcel from 'zipcelx';

import { colors } from 'assets';

export const useExportData = <T>({ table }: { table: Table<T> }) => {
  const exportCsv = useCallback(() => {
    const columns = table
      .getHeaderGroups()
      .map(headerGroup => {
        const headerRow: string[] = [];

        if (headerGroup.headers.length) {
          headerGroup.headers.forEach(header => {
            headerRow.push(header.column.columnDef.header as string);
          });
        }

        return headerRow;
      })
      .flat();

    const rows = table.getSortedRowModel().rows.map(row => {
      const dataRow: string[] = [];

      row.getVisibleCells().forEach(cell => {
        dataRow.push(cell.renderValue() as string);
      });

      return dataRow;
    });

    const th = `${columns.join(',')}`;
    const td = rows.map(row => row.join('","')).join('"\n"');
    const content = `${th}\n"${td}"`;

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content);
    hiddenElement.download = 'table-data-list.csv';
    hiddenElement.click();
  }, [table]);

  const exportExcel = useCallback(() => {
    const config = {
      filename: 'accounts-list',
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    table.getHeaderGroups().forEach(headerGroup => {
      const headerRow: { value: string; type: 'string' }[] = [];

      if (headerGroup.headers.length) {
        headerGroup.headers.forEach(header => {
          headerRow.push({
            value: header.column.columnDef.header as string,
            type: 'string',
          });
        });
      }

      dataSet.push(headerRow as never);
    });

    table.getSortedRowModel().rows.forEach(row => {
      const dataRow: { value: string; type: 'string' }[] = [];

      row.getVisibleCells().forEach(cell => {
        dataRow.push({
          value: cell.renderValue() as string,
          type: 'string',
        });
      });

      dataSet.push(dataRow as never);
    });

    return generateExcel(config);
  }, [table]);

  const exportPdf = useCallback(() => {
    const doc = new jsPDF('landscape');

    const columns = table
      .getHeaderGroups()
      .map(headerGroup => {
        const headerRow: string[] = [];
        if (headerGroup.headers.length) {
          headerGroup.headers.forEach(header => {
            headerRow.push(header.column.columnDef.header as string);
          });
        }

        return headerRow;
      })
      .flat();

    const rows = table.getSortedRowModel().rows.map(row => {
      const dataRow: string[] = [];
      row.getVisibleCells().forEach(cell => {
        dataRow.push(cell.renderValue() as string);
      });

      return dataRow;
    });

    autoTable(doc, {
      columns: columns,
      body: rows,
      theme: 'striped',
      horizontalPageBreak: true,
      headStyles: { fillColor: colors.primary.main, fontStyle: 'normal', halign: 'center' },
      styles: { fontSize: 10, cellWidth: 'auto' },
    });

    doc.save('accounts-list.pdf');
  }, [table]);

  return { exportExcel, exportCsv, exportPdf };
};
