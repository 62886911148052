import { Box, Typography } from "@material-ui/core";
import { ComponentType, memo } from "react";
import { FallbackProps } from "react-error-boundary";

export const ErrorFallback: ComponentType<FallbackProps> = memo(({ error }) => {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box mb={3} maxWidth={600}>
        <Typography variant="h1" color="primary" align="center">
          Error {error.name}
        </Typography>
      </Box>
      <Box mb={3} maxWidth={600}>
        <Typography variant="h4" color="secondary" align="center">
          {error.message}
        </Typography>
      </Box>

      {error.cause && (
        <div>
          <Typography variant="subtitle1" align="center">
            Server response
          </Typography>

          <Typography align="center">{error.cause}</Typography>
        </div>
      )}
    </Box>
  );
});
