import React from 'react';

import { DialogContextState, DialogContextDispatch } from 'context/dialog/DialogContextController';

export const useDialogState = () => {
  const context = React.useContext(DialogContextState);

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogContextController');
  }

  return context;
};

export const useDialogDispatch = () => {
  const context = React.useContext(DialogContextDispatch);

  if (context === undefined) {
    throw new Error('useSetDialog must be used within a DialogContextController');
  }

  return context;
};

// export { useDialogState, useDialogDispatch };
