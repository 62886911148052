import { memo, useCallback, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { FormInput, FormNewSelect } from 'components';
import { isEmail } from 'utils';

import { TaskAccountFormProps, TaskAccountFormValues } from './types';
import { accountFormShema, typeOptions } from './utils';

export const TaskAccountForm = memo(
  ({ findAccounts, createAndRelate, loading, referralSources }: TaskAccountFormProps) => {
    const {
      control,
      formState: { isDirty, isValid, isValidating },
      watch,
    } = useForm<TaskAccountFormValues>({
      resolver: yupResolver(accountFormShema),
      mode: 'all',
    });

    const { firstName, lastName, phone, email, type, source } = watch();

    const timer = useRef<ReturnType<typeof setTimeout> | null>();
    const findAccountsTimer = useCallback(
      (args: { firstName?: string; lastName?: string; phone?: string; email?: string }) => {
        if (!isDirty) {
          return;
        }

        if (timer.current) {
          clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {
          findAccounts(args);
        }, 300);
      },
      [findAccounts, isDirty],
    );

    useEffect(() => {
      if (phone && !phone.includes('_')) {
        findAccountsTimer({ phone, email });
      }

      if (!phone || phone === '') {
        findAccountsTimer({ phone: '', email });
      }
    }, [phone]);

    useEffect(() => {
      if (email && isEmail(email)) {
        findAccountsTimer({ email, phone });
      }

      if (!email || email === '') {
        findAccountsTimer({ email: '', phone });
      }
    }, [email]);

    useEffect(() => {
      if (isValid && !isValidating && firstName && lastName && phone && email && type && source) {
        createAndRelate({
          firstName,
          lastName,
          phone,
          email,
          type: type.value,
          source: source.value,
        });
      }
    }, [isValid, isValidating]);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormInput control={control} name="firstName" label="First name" required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput control={control} name="lastName" label="Last name" required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput control={control} name="phone" label="Phone" mask="phone" disabled={loading} required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInput control={control} name="email" label="Email" disabled={loading} required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormNewSelect control={control} name="type" label="Account type" options={typeOptions} required />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormNewSelect control={control} name="source" label="Referral source" options={referralSources} required />
        </Grid>
      </Grid>
    );
  },
);
