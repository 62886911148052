import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Dialog as MaterialDialog, Grid, IconButton, Typography, Box } from '@material-ui/core';
import CloseIconMui from '@material-ui/icons/Close';
import clsx from 'clsx';

import { Button } from '../button/Button';
import { DashedSeparator } from '../dashedSeparator/DashedSeparator';
import { ArrowBackLong, colors, CloseIcon } from 'assets';
import { disablePageScroll, enablePageScrol } from 'utils';

import { useStyles } from './Dialog.styles';
import { DialogProps } from './Dialog.types';

const classesMap = (styles: Record<string, string>) => ({
  custom: {
    paper: styles.estimatePaper,
    paperScrollBody: styles.customPaperScrollBody,
    scrollBody: styles.backdropBodyNonScrollbable,
  },
  estimate: {
    paper: styles.estimatePaper,
    paperScrollBody: styles.estimatePaperScrollBody,
    scrollBody: styles.backdropBodyNonScrollbable,
  },
  schedule: {
    paper: styles.paperDefault,
  },
  basic: {
    paper: styles.paperDefault,
  },
  withSeparator: {
    paper: styles.paperDefault,
  },
  fullScreen: {
    paper: styles.fullScreenPaper,
  },
});

export const Dialog = ({
  open,
  onClose,
  children,
  title,
  classes,
  muiDialogClasses,
  disablePortal = false,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  variant = 'basic',
  fullScreenProps,
  maxWidth = 'sm',
  hideCloseButton,
  fullScreen,
  ...props
}: DialogProps) => {
  const [isShowScrollToTop, setIsShowScrollToTop] = useState(false);

  const innerRef = useRef<HTMLDivElement>(null);

  const styles = useStyles({
    fullScreenBackgroundColor: fullScreenProps?.backgroundColor ? fullScreenProps.backgroundColor : colors.basic.white,
    isShowScrollToTop: isShowScrollToTop,
  });

  const dialogClasses = useMemo(() => {
    return classesMap(styles)[variant];
  }, [styles, variant]);

  const handleClose = (event: Record<string, never>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (onClose) {
      if (disableBackdropClick && reason === 'backdropClick') {
        return;
      }

      if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
        return;
      }

      onClose();
    }
  };

  const scrollToTop = () => {
    if (variant === 'fullScreen' && innerRef.current) {
      innerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const onScroll = () => {
    if (variant === 'fullScreen' && fullScreenProps?.isChangeBackToTop && innerRef.current) {
      if (innerRef.current.scrollTop >= 100 && !isShowScrollToTop) {
        setIsShowScrollToTop(true);
      } else if (innerRef.current.scrollTop < 100 && isShowScrollToTop) {
        setIsShowScrollToTop(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      disablePageScroll();
    } else {
      enablePageScrol();
    }
  }, [open]);

  return (
    <MaterialDialog
      disablePortal={disablePortal}
      open={open}
      onClose={handleClose}
      classes={{ ...dialogClasses, ...muiDialogClasses }}
      maxWidth={variant === 'fullScreen' ? false : maxWidth}
      fullScreen={variant === 'fullScreen' || fullScreen}
      data-testid={props['data-testid']}
      scroll={['estimate', 'custom'].includes(variant) ? 'body' : undefined}
      {...props}
    >
      <div
        className={clsx(
          classes?.wrapper,
          variant === 'schedule' && styles.scheduleContent,
          variant === 'estimate' && styles.estimateContent,
          variant === 'basic' && styles.basicContent,
          variant === 'withSeparator' && styles.withSeparatorContent,
          variant === 'fullScreen' && styles.fullScreenContent,
        )}
        id={variant === 'fullScreen' ? 'fullScreenDialogContent' : undefined}
        ref={innerRef}
        onScroll={onScroll}
      >
        {variant === 'schedule' && (
          <>
            {onClose && !hideCloseButton && (
              <div className={styles.scheduleCloseBtnContainer}>
                <Button buttonType="twoTone" size="medium" onClick={onClose}>
                  <CloseIconMui className={styles.scheduleCloseIcon} />
                </Button>
              </div>
            )}
            {children}
          </>
        )}

        {variant === 'estimate' && (
          <Grid container wrap="nowrap" direction="column" spacing={3}>
            {
              <Grid item>
                <Grid container wrap="nowrap" spacing={3} alignItems="center">
                  {title && (
                    <Grid item>
                      <Typography variant="h5" color="textSecondary" className={styles.estimateTitle}>
                        {title}
                      </Typography>
                    </Grid>
                  )}

                  {onClose && !hideCloseButton && (
                    <Grid item className={styles.estimateCloseBtnContainer}>
                      <IconButton
                        id="close-modal"
                        color="secondary"
                        onClick={onClose}
                        className={styles.estimateCloseBtn}
                      >
                        <CloseIcon color={colors.secondary.main} fontSize={18} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            }

            <Grid item>{children}</Grid>
          </Grid>
        )}

        {variant === 'basic' && (
          <Grid container wrap="nowrap" direction="column" style={{ height: '100%' }}>
            <Grid item>
              <Grid container wrap="nowrap" className={styles.basicTitleContainer} alignItems="center">
                {title && (
                  <Grid item>
                    <Typography variant="h4" color="textSecondary">
                      {title}
                    </Typography>
                  </Grid>
                )}

                {onClose && !hideCloseButton && (
                  <Grid item className={styles.closeBtnContainer}>
                    <IconButton id="close-modal" onClick={onClose} className={styles.closeBtn}>
                      <CloseIcon fontSize={12} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item className={styles.basicChildrenContainer}>
              {children}
            </Grid>
          </Grid>
        )}

        {variant === 'withSeparator' && (
          <Grid container wrap="nowrap" direction="column">
            <Grid item>
              <Grid container wrap="nowrap" className={styles.withSeparatorTitleContainer} alignItems="center">
                {title && (
                  <Grid item>
                    <Typography variant="h3" color="textSecondary">
                      {title}
                    </Typography>
                  </Grid>
                )}

                {onClose && !hideCloseButton && (
                  <Grid item className={styles.closeBtnContainer}>
                    <IconButton id="close-modal" onClick={onClose} className={styles.closeBtn}>
                      <CloseIcon fontSize={12} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid className={styles.separatorContainer}>
              <DashedSeparator dashedItemProps={{ width: 4 }} />
            </Grid>

            <Grid item className={styles.withSeparatorChildrenContainer}>
              {React.cloneElement(children as React.ReactElement, { onClose })}
            </Grid>
          </Grid>
        )}

        {variant === 'custom' && children}

        {variant === 'fullScreen' && (
          <Box>
            <Box className={styles.fullScreenHeader}>
              <Grid container justify="space-between" alignItems="center" wrap="nowrap">
                <Grid item>
                  <Button
                    buttonType="text"
                    onClick={isShowScrollToTop ? scrollToTop : onClose}
                    className={styles.fullScreenBackButton}
                  >
                    <Grid container alignItems="center" spacing={1} wrap="nowrap">
                      <Grid item className={styles.fullScreenArrowBackContainer}>
                        <ArrowBackLong fontSize={18} />
                      </Grid>

                      <Grid item>
                        <Typography variant="button" className={styles.fullScreenBackText}>
                          {isShowScrollToTop ? 'Top' : fullScreenProps?.backText ? fullScreenProps.backText : 'Back'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>

                {(onClose || fullScreenProps?.onCloseIconClick) && !hideCloseButton && (
                  <Grid item>
                    <IconButton
                      id="close-modal"
                      onClick={fullScreenProps?.onCloseIconClick ?? onClose}
                      className={styles.fullScreenCloseButton}
                    >
                      {fullScreenProps?.closeIcon ?? <CloseIcon fontSize={19} color={colors.grey100} />}
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box>{children}</Box>
          </Box>
        )}
      </div>
    </MaterialDialog>
  );
};
