import { memo } from 'react';

import { SvgIcon } from '@material-ui/core';

import { colors } from 'assets/theme';

import { SvgIconProps } from './SvgIcon.types';

export const VisibilityOffIcon = memo(({ className, color = colors.primary.main, fontSize = 22 }: SvgIconProps) => {
  return (
    <SvgIcon className={className} style={{ fontSize }} width="22" height="18" viewBox="0 0 22 18" fill="none">
      <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.42053 16.4002L18.8374 1.60001"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.50244 14.7975C9.31054 15.0485 10.1526 15.173 10.9987 15.1667C14.3699 15.2235 17.7887 12.8538 19.8986 10.5318C20.1248 10.2807 20.25 9.95466 20.25 9.61666C20.25 9.27867 20.1248 8.95266 19.8986 8.70152C19.1342 7.86302 18.2874 7.10356 17.371 6.43463"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9828 4.29854C12.334 4.13709 11.6673 4.05917 10.9988 4.06667C7.68434 4.01158 4.25646 6.32945 2.10139 8.69994C1.87518 8.95107 1.75 9.27709 1.75 9.61508C1.75 9.95307 1.87518 10.2791 2.10139 10.5302C2.75665 11.2475 3.47183 11.9077 4.23919 12.5036"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.91553 9.61675C7.91542 9.21181 7.9951 8.81081 8.15001 8.43667C8.30493 8.06253 8.53205 7.72258 8.81838 7.43624C9.10472 7.1499 9.44467 6.92279 9.81881 6.76787C10.193 6.61296 10.5939 6.53328 10.9989 6.53339"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0823 9.61597C14.0824 10.0209 14.0027 10.422 13.8478 10.7962C13.6929 11.1704 13.4658 11.5104 13.1795 11.7968C12.8931 12.0832 12.5532 12.3104 12.179 12.4654C11.8049 12.6204 11.4039 12.7002 10.9989 12.7002"
          stroke={color}
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
});
