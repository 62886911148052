import { useRef, useEffect, useMemo, useState } from 'react';

import { useResizeDetector } from 'react-resize-detector';

export const useEmailStyles = (email: string) => {
  const fullEmailRef = useRef<HTMLElement>();
  const abbreviatedEmailRef = useRef<HTMLElement>();
  const phoneRef = useRef<HTMLElement>();
  const { width: accountInfoWidth, ref: accountInfoRef } = useResizeDetector();
  const [abbreviatedEmailWidth, setAbbreviatedEmailWidth] = useState(0);
  const [fullEmailWidth, setFullEmailWidth] = useState(0);
  const [phoneWidth, setPhoneWidth] = useState(0);

  const isAbbreviatedEmailDisplays = useMemo(() => {
    return abbreviatedEmailWidth !== 0 && email.split('@')[0].length > 6 && fullEmailWidth > Number(accountInfoWidth);
  }, [abbreviatedEmailWidth, fullEmailWidth, accountInfoWidth, email]);

  useEffect(() => {
    if (abbreviatedEmailWidth === 0 && abbreviatedEmailRef.current) {
      setAbbreviatedEmailWidth(24 + 30 + 9 + abbreviatedEmailRef.current.scrollWidth);
    }
  }, [abbreviatedEmailWidth]);

  useEffect(() => {
    if (fullEmailWidth === 0 && fullEmailRef.current) {
      setFullEmailWidth(20 + 8 + 8 + fullEmailRef.current.scrollWidth);
    }
  }, [fullEmailWidth]);

  useEffect(() => {
    if (phoneWidth === 0 && phoneRef.current) {
      setPhoneWidth(32 + 24 + 9 + phoneRef.current.scrollWidth);
    }
  }, [phoneWidth]);

  return {
    isAbbreviatedEmailDisplays,
    accountInfoRef,
    abbreviatedEmailWidth,
    abbreviatedEmailRef,
    fullEmailRef,
    phoneRef,
    phoneWidth,
  };
};
