import { FileIcon, FileImageIcon, FilePdfIcon } from 'assets/icons';

const icons = [
  {
    extensions: ['jpg', 'jpeg', 'png', 'svg', 'gif'],
    icon: FileImageIcon,
  },
  {
    extensions: ['pdf'],
    icon: FilePdfIcon,
  },
];

export const getFileIcon = ({ fileName }: { fileName: string }) => {
  const extension = fileName.split('.').pop();
  const result = icons.find(el => extension && el.extensions.includes(extension));

  return result ? result.icon : FileIcon;
};
