import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import { useStyles } from './GeneralTopbar.styles';
import { GeneralTopbarDesktopSkeleton, GeneralTopbarMobileSkeleton } from './views';

export const GeneralTopbarSkeleton = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={styles.root} borderRadius={4}>
      {!isMobile ? <GeneralTopbarDesktopSkeleton /> : <GeneralTopbarMobileSkeleton />}
    </Box>
  );
};
