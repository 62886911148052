import React, { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';

import MomentUtils from '@date-io/moment';
import { IconButton, InputAdornment, InputLabel } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import moment from 'moment';

import { CalendarIconMain } from '../../assets';
import { CalendarPicker } from '../pickers/calendarPicker/CalendarPicker';
import { CalendarRangePicker } from '../pickers/calendarRangePicker/CalendarRangePicker';
import { Popover } from '../popover/Popover';
import { USADateFormat } from 'utils';

import { InputStyled, useStyles } from './Input.styles';
import { InputProps, InputCalendarAdornmentProps } from './Input.types';
import { MaskComponent } from './MaskComponent';

const InputCheckAdornment = memo(() => {
  const styles = useStyles();

  return (
    <InputAdornment position="end" className={styles.checkAdornment}>
      <CheckIcon />
    </InputAdornment>
  );
});

const InputCalendarAdornment = memo(
  ({
    value,
    dateFormat,
    disabled,
    className,
    onChange,
    type = 'date',
    minDate,
    maxDate,
    popoverProps,
  }: InputCalendarAdornmentProps) => {
    const [openPopover, setOpenPopover] = useState(false);
    const [currentDate, setCurrentDate] = useState<Date>(moment().toDate());
    const [startDate, setStartDate] = useState<Date>(moment().toDate());
    const [endDate, setEndDate] = useState<Date>(moment().toDate());

    const handleOpenPopover = useCallback(() => {
      setOpenPopover(true);
    }, []);

    const handleClosePopover = useCallback(() => {
      setOpenPopover(false);
    }, []);

    const handleDateChange = useCallback(
      (date?: Date) => {
        if (date && dateFormat) {
          handleClosePopover();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange({ target: { value: moment(date).format(dateFormat) } });
          setCurrentDate(date);
        }
      },
      [dateFormat, onChange, setCurrentDate, handleClosePopover],
    );

    const handleRangeDateChange = useCallback(
      (dates?: Date[]) => {
        if (dates && dateFormat) {
          const [firstDate, secondDate] = dates;

          if (!!firstDate && !!secondDate) {
            handleClosePopover();
          }

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target: {
              value: `${moment(firstDate || null).format(dateFormat)} - ${
                secondDate ? moment(secondDate).format(dateFormat) : ''
              }`,
            },
          });
          setStartDate(firstDate);
          setEndDate(secondDate);
        }
      },
      [dateFormat, onChange, setStartDate, setEndDate, handleClosePopover],
    );

    useEffect(() => {
      if (type === 'date' && value && dateFormat) {
        setCurrentDate(moment(value as number, USADateFormat).toDate());
      }
    }, [dateFormat, type, value, disabled]);

    return (
      <InputAdornment position="end" className={className}>
        <Popover
          variant="click"
          open={openPopover}
          disabled={disabled}
          popoverProps={popoverProps}
          onOpen={handleOpenPopover}
          onClose={handleClosePopover}
          toggle={
            <IconButton size="small" disabled={disabled}>
              <CalendarIconMain />
            </IconButton>
          }
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {type === 'date' &&
              (() => {
                const currentDateMoment = moment(currentDate, dateFormat);
                const dateValue = currentDateMoment.isValid() ? currentDateMoment : moment();
                const minDateValue = minDate ? moment(minDate) : undefined;
                const maxDateValue = maxDate ? moment(maxDate) : undefined;

                return (
                  <CalendarPicker
                    date={dateValue}
                    onChange={date => handleDateChange(date?.toDate())}
                    minDate={minDateValue}
                    maxDate={maxDateValue}
                  />
                );
              })()}

            {type === 'date-range' &&
              (() => {
                const minDateValue = minDate ? new Date(minDate) : undefined;
                const maxDateValue = maxDate ? new Date(maxDate) : undefined;

                return (
                  <CalendarRangePicker
                    isRange
                    onChange={dates => handleRangeDateChange(dates)}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDateValue}
                    maxDate={maxDateValue}
                  />
                );
              })()}
          </MuiPickersUtilsProvider>
        </Popover>
      </InputAdornment>
    );
  },
);

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        size = 'large',
        label,
        labelProps,
        placeholder,
        variant = 'standard',
        className,
        mask,
        checkAdornment,
        calendarAdornment,
        calendarAdornmentPopoverProps,
        dateFormat,
        minDate,
        maxDate,
        ...props
      }: InputProps,
      ref,
    ) => {
      const styles = useStyles();

      const InputComponent = useMemo(() => {
        if (!mask) {
          return;
        }

        return MaskComponent(mask);
      }, [mask]);

      return (
        <div
          data-testid={props['data-testid']}
          className={clsx(
            className,
            styles.root,
            variant === 'outlined' && styles.rootOutlined,
            size === 'small' && styles.rootSmall,
            size === 'medium' && styles.rootMedium,
            size === 'large' && styles.rootLarge,
            props.error && styles.rootError,
            props.disabled && styles.rootDisabled,
          )}
        >
          {label && (
            <InputLabel htmlFor={props.name} {...labelProps}>
              {label}
            </InputLabel>
          )}

          {!mask && (
            <InputStyled
              placeholder={!label ? placeholder : ''}
              {...props}
              endAdornment={
                checkAdornment ? (
                  <InputCheckAdornment />
                ) : calendarAdornment ? (
                  <InputCalendarAdornment
                    dateFormat={dateFormat}
                    type={mask}
                    value={props.value}
                    onChange={props.onChange}
                    className={label ? styles.calendarAdornment : ''}
                    disabled={props.disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    popoverProps={calendarAdornmentPopoverProps}
                  />
                ) : (
                  props.endAdornment
                )
              }
              ref={ref}
            />
          )}

          {mask && (
            <InputStyled
              placeholder={!label ? placeholder : ''}
              {...props}
              endAdornment={
                checkAdornment ? (
                  <InputCheckAdornment />
                ) : calendarAdornment ? (
                  <InputCalendarAdornment
                    dateFormat={dateFormat}
                    value={props.value}
                    type={mask}
                    onChange={props.onChange}
                    className={label ? styles.calendarAdornment : ''}
                    disabled={props.disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    popoverProps={calendarAdornmentPopoverProps}
                  />
                ) : (
                  props.endAdornment
                )
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent={InputComponent as any}
              ref={ref}
            />
          )}
        </div>
      );
    },
  ),
);
