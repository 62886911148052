import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const OPTION_ITEM_HEIGHT = 33;
export const OPTION_ITEM_HEIGHT_MOBILE = 41.25;

export const useStyles = makeStyles(theme => ({
  optionsList: {
    padding: 0,
    maxHeight: () => 5 * OPTION_ITEM_HEIGHT,
    overflowY: 'scroll',
  },
  optionItem: {
    '&:hover': {
      backgroundColor: colors.grey10,
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: OPTION_ITEM_HEIGHT_MOBILE,
    },
  },
  optionItemEven: {
    backgroundColor: colors.grey10,
  },
  optionItemOdd: {
    backgroundColor: colors.basic.white,
  },
  optionRootContainer: {
    overflow: 'hidden',
  },
  optionUsernameContainer: {
    overflow: 'hidden',
  },
  optionUsername: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
