import { createStyles, makeStyles } from '@material-ui/core';

import { colors } from 'assets/theme/palette';

export const useStyles = makeStyles(
  createStyles({
    monthButton: {
      color: colors.secondary.main,
    },
    yearButton: {
      color: colors.primary.main,
    },
    headerSelect: {
      cursor: 'pointer',
      color: colors.secondary.main,
      '& + $headerSelect': {
        marginLeft: '7px',
      },
    },
  }),
);
