import { memo } from 'react';

import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Paper } from 'components';

import { useStyles } from './styles';

export const TaskCardMobileSkeleton = memo(() => {
  const styles = useStyles();

  return (
    <Paper variant="elevation" elevation={24} className={styles.root}>
      <div className={styles.taskIdSection}>
        <Typography variant="body1" color="primary">
          <Skeleton variant="text" width={75} />
        </Typography>
      </div>

      <Typography variant="body1" className={styles.summary}>
        <Skeleton variant="text" width="70%" />
      </Typography>

      <div className={styles.boardSwimlaneSection}>
        <Typography variant="body1" className={styles.swimlane}>
          <Skeleton variant="text" width={44} />
        </Typography>

        <Skeleton variant="rect" width={100} height={21.5} style={{ borderRadius: 60 }} />
      </div>

      <Typography variant="body1" className={styles.dueDateTime}>
        <Skeleton variant="text" width={121} />
      </Typography>
    </Paper>
  );
});
