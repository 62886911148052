import { Address } from 'types';

export const validateAddress = (address?: Partial<Address> | null) => {
  if (!address) return '*required';
  if (!address.address) return '*address is missing';
  if (!address.city) return '*city is missing';
  if (!address.state) return '*state is missing';
  if (!address.country) return '*country is missing';
  if (!address.postal) return '*postal code is missing';

  return undefined;
};
