import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('xs')]: {
      gap: 8,
    },

    [theme.breakpoints.up('md')]: {
      gap: 32,
    },

    [theme.breakpoints.up('lg')]: {
      gap: 48,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  taskIdSection: {
    minWidth: 75,
  },
  summarySection: {
    flexBasis: '30%',
    overflow: 'hidden',
    color: colors.grey120,
  },
  dueDateSection: {
    minWidth: 84,
  },
  dueTimeSection: {
    flex: 0.5,
    color: colors.grey140,
    minWidth: 75,
  },
  boardSwimlaneSection: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('xs')]: {
      gap: 8,
    },

    [theme.breakpoints.up('md')]: {
      gap: 32,
    },

    [theme.breakpoints.up('lg')]: {
      gap: 48,
    },
  },
  boardItem: {
    display: 'flex',
    flex: 1,
    order: 0,
  },
  swimlaneItem: {
    flex: 1,
  },
  swimlane: {
    color: colors.grey140,
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  flagItem: {
    width: 18,
  },
}));
