import { memo, useCallback } from 'react';

import { Box, Typography } from '@material-ui/core';

import { colors } from 'assets';
import { Paper, UserIdentifier } from 'components';

export const CommentAddButton = memo(
  ({ onClick, userImage, userName }: { onClick: () => void; userImage?: string; userName: string }) => {
    const handleClick = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
      },
      [onClick],
    );

    return (
      <Paper variant="outlined" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <Box px={2} py={1.5} display="flex" alignItems="center">
          <Box mr={1}>
            <UserIdentifier imageUrl={userImage} isDisplayName={false} name={userName} size="large" />
          </Box>

          <Box color={colors.grey140}>
            <Typography variant="subtitle1" color="inherit">
              Add a comment
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  },
);
