import React, { useCallback, useEffect, useState } from 'react';

import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { Paper } from '../paper/Paper';

import { AccordionStyled, AccordionSummaryStyled, AccordionDetailsStyled } from './Accordion.styles';
import { AccordionProps } from './Accordion.types';

export const Accordion = ({
  expanded,
  disabled,
  separator = 'dashed',
  header,
  paperElevation,
  expandIconColor,
  children,
  onChange,
  hideToggle,
  hideSeparator,
  accordionClasses,
}: AccordionProps) => {
  const [isExpanded, setExpanded] = useState(!!expanded);

  const handleChange = useCallback(
    (event, expanded) => {
      if (onChange) {
        onChange(event, expanded);
      } else {
        setExpanded(expanded);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setExpanded(!!expanded);
  }, [expanded]);

  return (
    <Paper variant={paperElevation ? 'elevation' : 'outlined'} elevation={paperElevation}>
      <AccordionStyled
        expanded={isExpanded}
        disabled={disabled}
        onChange={handleChange}
        classes={accordionClasses?.accordion}
      >
        <AccordionSummaryStyled
          expandIcon={!hideToggle && <ExpandMore color={expandIconColor ?? 'primary'} />}
          aria-controls="accordion-content"
          id="accordion-summary"
          classes={accordionClasses?.accordionSummary}
        >
          {header}
        </AccordionSummaryStyled>

        <AccordionDetailsStyled
          className={clsx(!hideSeparator && separator && separator)}
          classes={accordionClasses?.accordionDetails}
        >
          {isExpanded && (
            <div style={{ width: '100%', marginTop: 8 }} className={accordionClasses?.contentRoot}>
              {children}
            </div>
          )}
        </AccordionDetailsStyled>
      </AccordionStyled>
    </Paper>
  );
};
