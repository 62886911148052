export enum BlockRouteEnum {
  EST,
  JOB_CLOSED,
  NOT_MOVING,
  LW_COMPLETED,
  VAL_COMPLETED,
  BOL_COMPLETED,
  PED_COMPLETED,
  ADDENDUM_COMPLETED,
  SQUARE_PAYMENT,
  HUNKPAY_PAYMENT,
  MANUAL_PAYMENT,
}
