import { FC, memo, useMemo } from 'react';

import { Box, IconButton, Link, Typography } from '@material-ui/core';

import { TrashIcon, colors } from 'assets';
import { Paper } from 'components';

import { RelationCardProps } from './types';

export const RelationCard: FC<RelationCardProps> = memo(({ disabled, disabledLink, relation, getLink, onDelete }) => {
  const link = useMemo(() => {
    if (disabledLink) {
      return;
    }

    return getLink(relation);
  }, [disabledLink, relation, getLink]);

  return (
    <Box mb={2}>
      <Paper
        roundingValue={4}
        elevation={16}
        style={{
          backgroundColor: disabled ? colors.grey20 : colors.basic.white,
        }}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" flex={1} p={2}>
            <Box color={colors.grey120}>
              <Typography color="inherit">{relation.type.label} ID:</Typography>
            </Box>

            <Box ml={1} color={colors.grey120}>
              <Link
                href={link}
                color={disabledLink ? 'inherit' : 'primary'}
                underline={disabledLink ? 'none' : 'always'}
                target="_blank"
              >
                <Typography color="inherit" style={{ wordBreak: 'break-word' }}>
                  <b>{relation.value}</b>
                </Typography>
              </Link>
            </Box>
          </Box>

          {!!onDelete && (
            <Box mr={0.5}>
              {!disabled && (
                <IconButton onClick={onDelete}>
                  <TrashIcon fontSize={20} />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
});
