import { makeStyles } from '@material-ui/core';

import { colors } from '../../assets';

export const useStyles = makeStyles({
  showMoreIcon: {
    color: colors.grey80,
    transition: 'transform 150ms ease-out',
  },
  showLessIcon: {
    transform: 'rotateZ(-180deg)',
  },
});
