import { geocodeByAddress } from 'react-places-autocomplete';

import { Address } from 'types';

export const createAddress = async (value: string) => {
  const { address_components } = await geocodeByAddress(value).then(results => results[0]);

  const address: Address = {
    address: '',
    address2: '',
    city: '',
    state: '',
    postal: '',
    country: '',
  };

  address_components.forEach(component => {
    if (component.types.includes('country')) {
      address.country = component.short_name;

      return;
    }

    if (component.types.includes('locality')) {
      address.city = component.short_name;

      return;
    }

    // Proper US order of address1 should be ${street_number} ${route}
    if (component.types.includes('street_number')) {
      address.address = [component.short_name, address.address].filter(Boolean).join(' ');

      return;
    }

    if (component.types.includes('route')) {
      address.address = [address.address, component.short_name].filter(Boolean).join(' ');

      return;
    }

    if (component.types.includes('postal_code')) {
      address.postal = component.short_name;

      return;
    }

    if (component.types.includes('administrative_area_level_1')) {
      address.state = component.short_name;

      return;
    }

    if (component.types.includes('administrative_area_level_3')) {
      if (!address.city) {
        address.city = component.short_name;
      }

      return;
    }
  });

  return address;
};
