import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  sidebarNavContainer: {
    zIndex: 100,

    [theme.breakpoints.up("lg")]: {
      position: "sticky",
      top: 0,
      height: "100vh",
    },
  },
  sidebarContainer: {
    [theme.breakpoints.down("md")]: {
      order: 2,
      width: "initial",
    },

    [theme.breakpoints.up("lg")]: {
      order: 3,
      width: 250,
      top: 0,
      position: "sticky",
      height: "100vh",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    order: 2,
    minWidth: 0,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      order: 3,
    },
  },
}));
