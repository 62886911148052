import React from 'react';

import { Typography, Grid, Box, IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';

import { Button } from 'components/button/Button';
import { Loader } from 'components/loader/Loader';
import { Scrollbars } from 'components/scrollbars/Scrollbars';

import { useStyles } from './TimePicker.styles';
import { TimePickerProps } from './TimePicker.types';

export function TimePicker({
  period,
  schedule,
  isLoading,
  onTimeSelected,
  onChangePeriod,
  className,
  alwaysShowScrollbar,
}: TimePickerProps) {
  const styles = useStyles();

  return (
    <Grid container direction="column" className={clsx(styles.container, className)}>
      <Grid item container justify="center" xs="auto">
        <Box display="flex" alignItems="center" mb={3} mr={onChangePeriod ? 0.875 : 0}>
          {onChangePeriod && (
            <Box m={-1.25}>
              <IconButton size="small" onClick={() => onChangePeriod('decrease')}>
                <KeyboardArrowLeft color="primary" />
              </IconButton>
            </Box>
          )}
          <Box minWidth={153} mr={onChangePeriod ? 1 : 0} ml={onChangePeriod ? 1 : 0} textAlign="center">
            <Typography variant="overline" component="p" color="secondary" className={styles.title}>
              {period}
            </Typography>
          </Box>
          {onChangePeriod && (
            <Box m={-1.25}>
              <IconButton size="small" onClick={() => onChangePeriod('add')}>
                <KeyboardArrowRight color="primary" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs className={styles.timeContainer}>
        {isLoading && <Loader />}
        {!isLoading && schedule.length !== 0 && (
          <Scrollbars
            autoHide={!alwaysShowScrollbar}
            autoHideTimeout={800}
            autoHideDuration={200}
            className={clsx(alwaysShowScrollbar && styles.alwaysShowScrollbar)}
          >
            {schedule.map((time, index) => (
              <div className={styles.timeButton} key={`${index}-${time.period}`}>
                <Button
                  className={clsx(time.selected && styles.selected)}
                  fullWidth
                  buttonType="twoTone"
                  onClick={() => onTimeSelected(time)}
                >
                  {time.friendly}
                </Button>
              </div>
            ))}
          </Scrollbars>
        )}
        {!isLoading && schedule.length === 0 && (
          <Typography variant="body1" className={styles.noAvailability}>
            No Availability
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
