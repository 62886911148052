import { Theme, makeStyles } from '@material-ui/core';

import { colors } from '../../assets';

type Props = {
  zIndex: number;
};

export const useStyles = makeStyles<Theme, Props>({
  popper: {
    zIndex: ({ zIndex }) => zIndex,
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.basic.white,
    color: colors.grey180,
    margin: 0,
    maxWidth: 'initial',
    boxShadow: '0px 4px 30px rgb(0 0 0 / 15%) ',

    '&.palcementRight': {
      marginLeft: -12,
    },

    '&.palcementLeft': {
      marginRight: -12,
    },

    '&.palcementTop': {
      marginBottom: -12,
    },

    '&.palcementBottom': {
      marginTop: -12,
    },
  },
  tooltipArrow: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: 16,
    top: '50%',
  },
  arrow: {
    fontSize: '2em',
    '&:before': {
      backgroundColor: colors.basic.white,
    },
  },
});
