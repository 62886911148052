import { colors } from '../../assets';

export const getComplementaryColorByString = (string: string): string => {
  const colorsName = Object.keys(colors.complementary);

  if (string) {
    return colors.complementary[colorsName[Math.abs(string[0].toLowerCase().charCodeAt(0) - 97) % colorsName.length]]
      .shade02;
  } else {
    return colors.complementary[colorsName[0]].shade02;
  }
};
