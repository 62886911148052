import { useEffect, useState, useCallback, memo } from 'react';

import { ListStyled, useStyles } from './SidenavMenu.styles';
import { MenuItem, SidenavMenuProps } from './SidenavMenu.types';
import { SidenavMenuItem } from './SidenavMenuItem/SidenavMenuItem';

export const SidenavMenu = memo(({ menuItems }: SidenavMenuProps) => {
  const styles = useStyles();

  const [selected, setSelected] = useState<number | undefined>();
  const [expanded, setExpanded] = useState<number | undefined>();

  const onSetParentSelected = useCallback((index: number) => {
    setSelected(index);
  }, []);

  const handleItemClick = useCallback(
    (index: number, nestedItems?: MenuItem[]) => {
      if (
        selected !== index &&
        (!nestedItems || nestedItems?.length === 0) &&
        menuItems[index].isActive === undefined
      ) {
        setSelected(index);
        setExpanded(undefined);
      } else if (selected !== index && nestedItems?.length !== 0 && expanded === undefined) {
        setExpanded(index);
      } else {
        setExpanded(expanded === index ? undefined : index);
      }
    },
    [expanded, selected, menuItems],
  );

  useEffect(() => {
    setSelected(
      (menuItems.map((menuItem, i) => ({ isActive: menuItem.isActive, i: i + 1 })).find(menuItem => menuItem.isActive)
        ?.i ||
        menuItems
          .map((menuItem, i) => ({ parentIndex: i + 1, nestedItems: menuItem?.nestedItems }))
          .find(menuItem => menuItem.nestedItems?.find(menuItem => menuItem.isActive))?.parentIndex ||
        0) - 1,
    );
  }, [menuItems]);

  useEffect(() => {
    setExpanded(selected && menuItems[selected]?.nestedItems ? selected : undefined);
  }, [selected, menuItems]);

  return (
    <ListStyled className={styles.parentList}>
      {menuItems.map((menuItem, index) => (
        <SidenavMenuItem
          key={index}
          menuItem={{ ...menuItem, parentIndex: index }}
          isSelected={index === selected && menuItem.isActive !== false}
          isExpanded={index === expanded}
          selectedParentIndex={selected}
          setParentSelected={onSetParentSelected}
          onClick={() => handleItemClick(index, menuItem.nestedItems)}
        />
      ))}
    </ListStyled>
  );
});
