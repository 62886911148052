import { createStyles, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { fontWeight } from 'assets/theme';
import { colors } from 'assets/theme/palette';

export const AlertStyled = withStyles(() =>
  createStyles({
    root: {
      padding: '16px 24px',
      color: colors.basic.white,
      fontSize: '18px',
      minWidth: 300,
      textAlign: 'left',
      maxWidth: 500,
      fontWeight: fontWeight.bold,
      borderRadius: '4px',
      cursor: 'pointer',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    },
    message: {
      padding: 0,
    },
    filledError: {
      color: colors.functionals.alertLight,
    },

    filledWarning: {
      color: colors.functionals.warningLight,
    },

    filledInfo: {
      color: colors.functionals.infoLight,
    },

    filledSuccess: {
      color: colors.functionals.successLight,
    },
  }),
)(Alert);
