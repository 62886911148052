import moment from 'moment';
import * as yup from 'yup';

import { USADateFormat } from 'utils';

export const checkTargetDate = (date?: string, format?: string) =>
  !!date && moment(date, format).isAfter(moment({ year: 2000, month: 0, day: 1 }));

export const leadFormValidationSchema = yup.object({
  targetDate: yup
    .date()
    .min(moment({ year: 2000, month: 0, day: 2 }).toDate(), 'Date must be after 01/01/2000')
    .typeError('Date must be in MM/DD/YYYY format')
    .transform((_, originalValue) => {
      if ((originalValue?.length ?? 0) < 8) {
        return null;
      }

      const date = moment(originalValue, USADateFormat).toDate();

      return date.getTime() ? date : undefined;
    }),
  lowEstimate: yup
    .number()
    .min(0)
    .transform(value => (isNaN(value) ? undefined : value))
    .required('Low range estimate is required')
    .when('highEstimate', (highEstimate: string | string[], schema) => {
      if (!Array.isArray(highEstimate) && highEstimate && Number(highEstimate)) {
        schema.max(Number(highEstimate), 'High range estimate must be greater than low range estimate');
      }

      return schema;
    }),
  highEstimate: yup
    .number()
    .min(0)
    .transform(value => (isNaN(value) ? undefined : value))
    .required('High range estimate is required'),
  reason: yup.object({ label: yup.string().required(), value: yup.string().required() }).nullable().required(''),
});
