import React, { useState, useRef, useCallback, memo, useMemo } from 'react';

import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import moment from 'moment';

import { Button } from 'components/button/Button';

import { useStyles } from './HoursPicker.styles';
import { nearestMinutes } from './HoursPicker.utils';

type HoursPickerDialogContentProps = {
  onClose: () => void;
} & TimePickerProps;

const defaultMinutesStep = 15;

const HoursPickerDialogContentRaw = ({
  onClose,
  value,
  onChange,
  onAccept,
  ...props
}: HoursPickerDialogContentProps) => {
  const styles = useStyles();
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const [areMinutesSelected, setAreMinutesSelected] = useState(false);
  const minutesStep = useMemo(() => props.minutesStep ?? defaultMinutesStep, [props.minutesStep]);
  /*
   * If value is null or undefined (initial HourPicker state, without Date),
   * then set internalValue (date in dialog) to NOW, but with nearestMinutes check
   * like if NOW date is 12:42, then initial internalValue will be 12:45
   */
  const [internalValue, setInternalValue] = useState<MaterialUiPickersDate>(
    value ? moment(value) : nearestMinutes(minutesStep, moment()),
  );

  const checkIfMinutesAreSelected = useCallback(() => {
    setTimeout(() => {
      if (!dialogRef.current) {
        return;
      }

      // minutesElement is toolbarTxt which is right on the right of separator (:)
      const minutesElement = dialogRef.current.querySelector(
        '.MuiPickersTimePickerToolbar-hourMinuteLabel .MuiPickersTimePickerToolbar-separator + button .MuiPickersToolbarText-toolbarTxt',
      );
      const isMinutesElementActive = !!minutesElement?.classList.contains('MuiPickersToolbarText-toolbarBtnSelected');
      setAreMinutesSelected(isMinutesElementActive);
    }, 0);
  }, [dialogRef]);

  const handleAccept = useCallback(() => {
    onChange(internalValue);
    onAccept && onAccept(internalValue);
    onClose();
  }, [internalValue, onChange, onAccept, onClose]);

  return (
    <div
      ref={dialogRef}
      onClick={checkIfMinutesAreSelected}
      className={clsx(
        styles.pickerDialog,
        areMinutesSelected && minutesStep === 15 && styles.disabledMinutesNonDivisibleBy15,
      )}
    >
      <TimePicker
        {...props}
        variant="static"
        value={internalValue}
        onChange={setInternalValue}
        minutesStep={minutesStep}
      />

      <div className={styles.buttonsWrapper}>
        <Button onClick={onClose} fullWidth buttonType="text" color="primary" size="medium">
          Cancel
        </Button>

        <Button onClick={handleAccept} fullWidth buttonType="twoTone" size="medium">
          Ok
        </Button>
      </div>
    </div>
  );
};

export const HoursPickerDialogContent = memo(HoursPickerDialogContentRaw);
