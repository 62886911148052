import { BrowserRouter } from "react-router-dom";
import {
  AlertProvider,
  CalendarPickerProvider,
  CollegeHunksTheme,
  DialogContextController,
  Loader,
} from "@chhjpackages/components";
import { FC, Suspense } from "react";

import { ErrorBoundary } from "features/error-boundary";
import { ScrollToTop } from "widgets";
import { ignoreScrollToTopBetweenPaths } from "shared/utils";

export const withProviders = (WrappedComponent: FC) => () =>
  (
    <CollegeHunksTheme>
      <ErrorBoundary>
        <CalendarPickerProvider>
          <AlertProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            preventDuplicate
          >
            <BrowserRouter>
              <DialogContextController>
                <Suspense fallback={<Loader size="large" />}>
                  <ScrollToTop
                    ignoreBetweenPaths={ignoreScrollToTopBetweenPaths}
                  />
                  <WrappedComponent />
                </Suspense>
              </DialogContextController>
            </BrowserRouter>
          </AlertProvider>
        </CalendarPickerProvider>
      </ErrorBoundary>
    </CollegeHunksTheme>
  );
