import { useCallback, useMemo, useState } from 'react';

import { useAlert } from 'hooks/useAlert/useAlert';
import { getFlooredFixed } from 'utils';

import { FilePickerProps } from './useFilePicker.types';

export const useFilePicker = ({ inputProps, fileSizeValidation, onFilesSelected }: FilePickerProps) => {
  const { showAlert } = useAlert();

  const [files, setFiles] = useState<File[]>([]);

  const accept = useMemo(() => inputProps?.accept?.filter(item => !!item).join(', ') ?? '', [inputProps?.accept]);

  const multiple = useMemo(() => inputProps?.multiple ?? false, [inputProps?.multiple]);

  const handleChangeFile = useCallback(
    (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (!target.files || !target.files.length) {
        setFiles([]);

        return;
      }

      const files = Array.from(target.files);
      const filteredFiles = files.filter(file => {
        const fileSizeKb = file.size / 1024;

        if (!!fileSizeValidation && fileSizeKb >= fileSizeValidation.maxFileSizeKb) {
          if (fileSizeValidation.showAlert) {
            const maxFileSizeMb = fileSizeValidation.maxFileSizeKb / 1024;
            const limitOfMb = Number.isInteger(maxFileSizeMb) ? maxFileSizeMb : getFlooredFixed(maxFileSizeMb, 1);

            showAlert(
              fileSizeValidation.alert ?? `Error! File is too large. There is a maximum limit of ${limitOfMb}MB.`,
              {
                variant: fileSizeValidation.alertType ?? 'error',
              },
            );
          }

          return false;
        }

        return true;
      });

      setFiles(filteredFiles);

      if (onFilesSelected) {
        onFilesSelected(filteredFiles);
      }
    },
    [fileSizeValidation, onFilesSelected, showAlert],
  );

  const openFilePicker = useCallback(() => {
    const input = document.createElement('input');
    input.style.display = 'none';

    document.body.appendChild(input);

    input.type = 'file';
    input.accept = accept;
    input.multiple = multiple;
    input.addEventListener('change', e => {
      handleChangeFile(e);
      document.body.removeChild(input);
    });

    input.dispatchEvent(new MouseEvent('click'));
  }, [accept, multiple, handleChangeFile]);

  return { files, openFilePicker };
};
