import { Navigate } from "react-router-dom";
import { Suspense } from "react";

import { NotFoundPage } from "pages/not-found";
import { lazyWithPreload, routePaths } from "shared/utils";
import { PageLoading } from "widgets";
import { BlockRouteEnum, PageRoute } from "../types";
import { isDigitalPaperworkEnabled } from "shared/config";

// Login
export const LoginLazy = lazyWithPreload(() =>
  import("pages/login/login").then((module) => ({
    default: module.Login,
  })),
);

export const NeedHelpLazy = lazyWithPreload(() =>
  import("pages/login/need-help").then((module) => ({
    default: module.NeedHelp,
  })),
);

export const ResetPasswordLinkLazy = lazyWithPreload(() =>
  import("pages/login/reset-password-link").then((module) => ({
    default: module.ResetPasswordLink,
  })),
);

export const ResetPasswordLinkSuccessLazy = lazyWithPreload(() =>
  import("pages/login/reset-password-link-success").then((module) => ({
    default: module.ResetPasswordLinkSuccess,
  })),
);

export const RestorePasswordLazy = lazyWithPreload(() =>
  import("pages/login/restore-password").then((module) => ({
    default: module.RestorePassword,
  })),
);

export const RestorePasswordSuccessLazy = lazyWithPreload(() =>
  import("pages/login/restore-password-success").then((module) => ({
    default: module.RestorePasswordSuccess,
  })),
);

export const TermsAndConditionsLazy = lazyWithPreload(() =>
  import("pages/login/terms-and-conditions").then((module) => ({
    default: module.TermsAndConditions,
  })),
);

// Dashboard
export const DashboardLazy = lazyWithPreload(() =>
  import("pages/dashboard/dashboard").then((module) => ({
    default: module.Dashboard,
  })),
);

export const AttachmentsLazy = lazyWithPreload(() =>
  import("pages/appointment/attachments").then((module) => ({
    default: module.Attachments,
  })),
);

export const CompleteAppointmentLazy = lazyWithPreload(() =>
  import("pages/appointment/complete-appointment").then((module) => ({
    default: module.CompleteAppointment,
  })),
);

export const SendInvoiceLazy = lazyWithPreload(() =>
  import("pages/appointment/send-invoice").then((module) => ({
    default: module.SendInvoice,
  })),
);

export const ContactsLazy = lazyWithPreload(() =>
  import("pages/dashboard/contacts").then((module) => ({
    default: module.Contacts,
  })),
);

export const MapTypesDialogLazy = lazyWithPreload(() =>
  import("pages/dashboard/map-types-dialog").then((module) => ({
    default: module.MapTypesDialog,
  })),
);

export const NotesDialogLazy = lazyWithPreload(() =>
  import("pages/dashboard/notes-dialog").then((module) => ({
    default: module.NotesDialog,
  })),
);

// User Profile
export const UserProfileLazy = lazyWithPreload(() =>
  import("pages/user-profile").then((module) => ({
    default: module.UserProfile,
  })),
);

// Unprocessed Changes
export const UnprocessedChangesLazy = lazyWithPreload(() =>
  import("pages/unprocessed-changes/uprocessed-changes").then((module) => ({
    default: module.UnprocessedChanges,
  })),
);

// Training
export const TrainingLazy = lazyWithPreload(() =>
  import("pages/training/training").then((module) => ({
    default: module.Training,
  })),
);

export const PerfectJobStepsLazy = lazyWithPreload(() =>
  import("pages/training/perfect-job-steps").then((module) => ({
    default: module.PerfectJobSteps,
  })),
);

export const MoveJobStepsLazy = lazyWithPreload(() =>
  import("pages/training/move-job-steps").then((module) => ({
    default: module.MoveJobSteps,
  })),
);

export const BinderPresentationListLazy = lazyWithPreload(() =>
  import("pages/training/binder-presentation-list").then((module) => ({
    default: module.BinderPresentationList,
  })),
);

export const BinderPresentationDetailsLazy = lazyWithPreload(() =>
  import("pages/training/binder-presentation-details").then((module) => ({
    default: module.BinderPresentationDetails,
  })),
);

// Notifications
export const NotificationsLazy = lazyWithPreload(() =>
  import("pages/notifications/list").then((module) => ({
    default: module.Notifications,
  })),
);

// Tasks
export const TasksListLazy = lazyWithPreload(() =>
  import("pages/tasks/list").then((module) => ({
    default: module.TasksList,
  })),
);

export const TaskDetailsLazy = lazyWithPreload(() =>
  import("pages/tasks/details").then((module) => ({
    default: module.TaskDetails,
  })),
);

// Settings
export const SettingsLazy = lazyWithPreload(() =>
  import("pages/settings").then((module) => ({
    default: module.Settings,
  })),
);

// Create Team
export const CreateTeamLazy = lazyWithPreload(() =>
  import("pages/create-team").then((module) => ({
    default: module.CreateTeam,
  })),
);

// Leaderboard
export const LeaderBoardLazy = lazyWithPreload(() =>
  import("pages/leaderboard").then((module) => ({
    default: module.LeaderBoard,
  })),
);

// Job Details
export const JobDetailsLazy = lazyWithPreload(() =>
  import("pages/job-details/job-details").then((module) => ({
    default: module.JobDetails,
  })),
);

// Nasa Requirements
export const NasaRequirementsLazy = lazyWithPreload(() =>
  import("pages/nasa-requirements").then((module) => ({
    default: module.NasaRequirements,
  })),
);

// Estimate Detail
export const EstimateDetailLazy = lazyWithPreload(() =>
  import("pages/estimate-detail/estimate-detail").then((module) => ({
    default: module.EstimateDetail,
  })),
);

// Payments
export const PaymentsLazy = lazyWithPreload(() =>
  import("pages/payments/payments").then((module) => ({
    default: module.Payments,
  })),
);

export const PaymentCollectLazy = lazyWithPreload(() =>
  import("pages/payments/collect").then((module) => ({
    default: module.PaymentCollect,
  })),
);

export const PaymentResultLazy = lazyWithPreload(() =>
  import("pages/payments/result").then((module) => ({
    default: module.PaymentResult,
  })),
);

export const PaymentAttachLazy = lazyWithPreload(() =>
  import("pages/payments/attach").then((module) => ({
    default: module.PaymentAttach,
  })),
);

export const SendPaymentLinkLazy = lazyWithPreload(() =>
  import("pages/payments/send-payment-link").then((module) => ({
    default: module.SendPaymentLink,
  })),
);

// Coupons And Discounts
export const CouponsAndDiscountsLazy = lazyWithPreload(() =>
  import("pages/coupons-and-discounts/coupons-and-discounts").then(
    (module) => ({
      default: module.CouponsAndDiscounts,
    }),
  ),
);

export const CouponsLazy = lazyWithPreload(() =>
  import("pages/coupons-and-discounts/coupons").then((module) => ({
    default: module.Coupons,
  })),
);

export const DiscountsLazy = lazyWithPreload(() =>
  import("pages/coupons-and-discounts/discounts").then((module) => ({
    default: module.Discounts,
  })),
);

// Cart
export const CartLazy = lazyWithPreload(() =>
  import("pages/cart/cart").then((module) => ({
    default: module.Cart,
  })),
);

export const CartEditLazy = lazyWithPreload(() =>
  import("pages/cart/cart-edit").then((module) => ({
    default: module.CartEdit,
  })),
);

// Add Product
export const PricingsLazy = lazyWithPreload(() =>
  import("pages/add-product/pricings").then((module) => ({
    default: module.Pricings,
  })),
);

export const PricingsAddLazy = lazyWithPreload(() =>
  import("pages/add-product/pricings-add").then((module) => ({
    default: module.PricingsAdd,
  })),
);

// Developments
export const EditTravelDevelopmentsLazy = lazyWithPreload(() =>
  import("pages/developments/edit-travel-developments").then((module) => ({
    default: module.EditTravelDevelopments,
  })),
);

export const EditWorkDevelopmentsLazy = lazyWithPreload(() =>
  import("pages/developments/edit-work-developments").then((module) => ({
    default: module.EditWorkDevelopments,
  })),
);

// Bill Of Lading
export const BillOfLadingEstimateLazy = lazyWithPreload(() =>
  import("pages/bill-of-lading/estimate").then((module) => ({
    default: module.BillOfLadingEstimate,
  })),
);

export const BillOfLadingSignatureLazy = lazyWithPreload(() =>
  import("pages/bill-of-lading/signature").then((module) => ({
    default: module.BillOfLadingSignature,
  })),
);

// Job Inspection
export const JobInspectionTermsLazy = lazyWithPreload(() =>
  import("pages/job-inspection/terms").then((module) => ({
    default: module.JobInspectionTerms,
  })),
);

export const JobInspectionSignatureLazy = lazyWithPreload(() =>
  import("pages/job-inspection/signature").then((module) => ({
    default: module.JobInspectionSignature,
  })),
);

// Valuation
export const ValuationLazy = lazyWithPreload(() =>
  import("pages/valuation/valuation").then((module) => ({
    default: module.Valuation,
  })),
);

export const ValuationSignatureLazy = lazyWithPreload(() =>
  import("pages/valuation/signature").then((module) => ({
    default: module.ValuationSignature,
  })),
);

// Pre Existing Damage
export const PreExistingDamageLazy = lazyWithPreload(() =>
  import("pages/pre-existing-damage/pre-existing-damage").then((module) => ({
    default: module.PreExistingDamage,
  })),
);

export const PreExistingDamageSignatureLazy = lazyWithPreload(() =>
  import("pages/pre-existing-damage/signature").then((module) => ({
    default: module.PreExistingDamageSignature,
  })),
);

// Liability Waiver
export const LiabilityWaiverLazy = lazyWithPreload(() =>
  import("pages/liability-waiver/liability-waiver").then((module) => ({
    default: module.LiabilityWaiver,
  })),
);

export const LiabilityWaiverSignatureLazy = lazyWithPreload(() =>
  import("pages/liability-waiver/signature").then((module) => ({
    default: module.LiabilityWaiverSignature,
  })),
);

// Addendum
export const AddendumLazy = lazyWithPreload(() =>
  import("pages/addendum/addendum").then((module) => ({
    default: module.Addendum,
  })),
);

export const AddendumSignatureLazy = lazyWithPreload(() =>
  import("pages/addendum/signature").then((module) => ({
    default: module.AddendumSignature,
  })),
);

export const routes: PageRoute[] = [
  {
    path: routePaths.home(),
    component: <Navigate to={routePaths.dashboard()} replace />,
    isProtectedRoute: true,
  },
  {
    path: routePaths.dashboard(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <DashboardLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.appointmentContacts(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <ContactsLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.appointmentNotes(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <NotesDialogLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.appointmentMapTypes(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <MapTypesDialogLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.jobDetails(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <JobDetailsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.jobDetailsNotesModal(),
        component: (
          <Suspense fallback={<></>}>
            <NotesDialogLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.jobDetailsContacts(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <ContactsLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.jobDetailsSendInvoice(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <SendInvoiceLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.jobDetailsCompleteAppointment(),
        blockRoute: [BlockRouteEnum.JOB_CLOSED],
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <CompleteAppointmentLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.jobDetailsAttachments(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <AttachmentsLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.leaderboards(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <LeaderBoardLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.notifications(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <NotificationsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.tasksList(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <TasksListLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.taskDetails(),
        component: (
          <Suspense fallback={<PageLoading />}>
            <TaskDetailsLazy />
          </Suspense>
        ),
        isProtectedRoute: true,
      },
    ],
  },
  {
    path: routePaths.training(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <TrainingLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingPerfectJob(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PerfectJobStepsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingMoveJob(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <MoveJobStepsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingBinderPresentation(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <BinderPresentationListLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.trainingBinderPresentationDetails(),
    component: (
      <Suspense fallback={<PageLoading isDialog />}>
        <BinderPresentationDetailsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsConvertToJob(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <EstimateDetailLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsAddProduct(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PricingsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.JOB_CLOSED],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsAddProductNew(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <PricingsAddLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.jobDetailsCart(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <CartLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.JOB_CLOSED],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsCartEdit(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <CartEditLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.jobDetailsCouponsAndDiscounts(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <CouponsAndDiscountsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.JOB_CLOSED],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsCoupons(),
        component: (
          <Suspense fallback={<PageLoading />}>
            <CouponsLazy />
          </Suspense>
        ),
      },
      {
        path: routePaths.jobDetailsDiscounts(),
        component: (
          <Suspense fallback={<PageLoading />}>
            <DiscountsLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.jobDetailsPayments(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PaymentsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST],
    nestedRoutes: [
      {
        path: routePaths.jobDetailsSendPaymentLink(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <SendPaymentLinkLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.jobDetailsPaymentsCollect(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PaymentCollectLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST, BlockRouteEnum.SQUARE_PAYMENT],
  },
  {
    path: routePaths.jobDetailsPaymentsResult(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PaymentResultLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST],
  },
  {
    path: routePaths.jobDetailsAttachPayment(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PaymentAttachLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.HUNKPAY_PAYMENT,
      BlockRouteEnum.MANUAL_PAYMENT,
    ],
  },
  {
    path: routePaths.jobDetailsEditTravelHours(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <EditTravelDevelopmentsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsEditWorkHours(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <EditWorkDevelopmentsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  ...(isDigitalPaperworkEnabled
    ? [
        {
          path: routePaths.jobDetailsLiabilityWaiver(),
          component: (
            <Suspense fallback={<PageLoading />}>
              <LiabilityWaiverLazy />
            </Suspense>
          ),
          isProtectedRoute: true,
          blockRoute: [
            BlockRouteEnum.EST,
            BlockRouteEnum.NOT_MOVING,
            BlockRouteEnum.JOB_CLOSED,
          ],
        },
        {
          path: routePaths.jobDetailsLiabilityWaiverSignature(),
          component: (
            <Suspense fallback={<PageLoading />}>
              <LiabilityWaiverSignatureLazy />
            </Suspense>
          ),
          isProtectedRoute: true,
          blockRoute: [
            BlockRouteEnum.EST,
            BlockRouteEnum.NOT_MOVING,
            BlockRouteEnum.JOB_CLOSED,
          ],
        },
      ]
    : []),
  ...(isDigitalPaperworkEnabled
    ? [
        {
          path: routePaths.jobDetailsValuation(),
          component: (
            <Suspense fallback={<PageLoading />}>
              <ValuationLazy />
            </Suspense>
          ),
          isProtectedRoute: true,
          blockRoute: [
            BlockRouteEnum.EST,
            BlockRouteEnum.NOT_MOVING,
            BlockRouteEnum.JOB_CLOSED,
            BlockRouteEnum.VAL_COMPLETED,
          ],
        },
        {
          path: routePaths.jobDetailsValuationSignature(),
          component: (
            <Suspense fallback={<PageLoading />}>
              <ValuationSignatureLazy />
            </Suspense>
          ),
          isProtectedRoute: true,
          blockRoute: [
            BlockRouteEnum.EST,
            BlockRouteEnum.NOT_MOVING,
            BlockRouteEnum.JOB_CLOSED,
          ],
        },
      ]
    : []),
  {
    path: routePaths.jobDetailsBillOfLading(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <BillOfLadingEstimateLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
      BlockRouteEnum.BOL_COMPLETED,
    ],
  },
  {
    path: routePaths.jobDetailsbillOfLadingSignature(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <BillOfLadingSignatureLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  ...(isDigitalPaperworkEnabled
    ? [
        {
          path: routePaths.jobDetailsPreExistingDamage(),
          component: (
            <Suspense fallback={<PageLoading />}>
              <PreExistingDamageLazy />
            </Suspense>
          ),
          isProtectedRoute: true,
          blockRoute: [
            BlockRouteEnum.EST,
            BlockRouteEnum.NOT_MOVING,
            BlockRouteEnum.JOB_CLOSED,
            BlockRouteEnum.PED_COMPLETED,
          ],
        },
        {
          path: routePaths.jobDetailsPreExistingDamageSignature(),
          component: (
            <Suspense fallback={<PageLoading />}>
              <PreExistingDamageSignatureLazy />
            </Suspense>
          ),
          isProtectedRoute: true,
          blockRoute: [
            BlockRouteEnum.EST,
            BlockRouteEnum.NOT_MOVING,
            BlockRouteEnum.JOB_CLOSED,
          ],
        },
      ]
    : []),
  {
    path: routePaths.jobInspectionTerms(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <JobInspectionTermsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.jobDetailsAddendum(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <AddendumLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
      BlockRouteEnum.ADDENDUM_COMPLETED,
    ],
  },
  {
    path: routePaths.jobDetailsAddendumSignature(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <AddendumSignatureLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [
      BlockRouteEnum.EST,
      BlockRouteEnum.NOT_MOVING,
      BlockRouteEnum.JOB_CLOSED,
    ],
  },
  {
    path: routePaths.jobInspectionSignature(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <JobInspectionSignatureLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.squarePaymentResult(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <PaymentResultLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    blockRoute: [BlockRouteEnum.EST],
  },
  {
    path: routePaths.nasaRequirements(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <NasaRequirementsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
    nestedRoutes: [
      {
        path: routePaths.nasaRequirementsAttachments(),
        component: (
          <Suspense fallback={<PageLoading isDialog />}>
            <AttachmentsLazy />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routePaths.unprocessedChanges(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <UnprocessedChangesLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.settings(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <SettingsLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.userProfile(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <UserProfileLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.createTeam(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <CreateTeamLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.editTeam(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <CreateTeamLazy />
      </Suspense>
    ),
    isProtectedRoute: true,
  },
  {
    path: routePaths.login(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <LoginLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    path: routePaths.termsAndConditions(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <TermsAndConditionsLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    path: routePaths.needHelp(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <NeedHelpLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    path: routePaths.resetPasswordLink(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <ResetPasswordLinkLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    path: routePaths.resetPasswordLinkSuccess(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <ResetPasswordLinkSuccessLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    path: routePaths.restorePassword(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <RestorePasswordLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    path: routePaths.restorePasswordSuccess(),
    component: (
      <Suspense fallback={<PageLoading />}>
        <RestorePasswordSuccessLazy />
      </Suspense>
    ),
    isProtectedRoute: false,
  },
  {
    component: <NotFoundPage />,
    path: routePaths.notFound(),
    isProtectedRoute: false,
  },
];
