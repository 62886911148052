import React, { useState, useEffect, useMemo } from 'react';

import { Menu, MenuItem, Popover } from '@material-ui/core';
import clsx from 'clsx';

import { DashedSeparator } from '../../dashedSeparator/DashedSeparator';
import { NestedMenuItem } from '../nestedMenuItem/NestedMenuItem';
import { colors } from 'assets';

import { useStyles } from './NewDropdownMenu.styles';
import { INewDropdownMenuProps } from './NewDropdownMenu.types';

export const NewDropdownMenu = ({
  isNestedMenu = false,
  className,
  open,
  menuItems,
  onMenuItemClick,
  handleNestedMenuItemClick,
  onClose,
  ...props
}: INewDropdownMenuProps & {
  className?: string;
}) => {
  const classes = useStyles();

  const [parentMenuShow, setParentMenuShow] = useState(true);

  const headerMenuItems = useMemo(() => menuItems?.filter(menuItem => menuItem.type === 'header'), [menuItems]);
  const bodyMenuItems = useMemo(() => menuItems?.filter(menuItem => menuItem.type !== 'header'), [menuItems]);
  const thereNestedItems = useMemo(() => menuItems?.find(menuItem => menuItem.items), [menuItems]);

  useEffect(() => {
    if (open) {
      setParentMenuShow(true);
    }
  }, [open]);

  return (
    <Popover
      open={open}
      classes={{
        root: clsx(classes.popoverRoot, open ? classes.animateOpenPopover : classes.animateClosePopover),
        paper: classes.popoverPaper,
      }}
      anchorEl={props.anchorEl}
    >
      <Menu
        className={clsx([className])}
        open={open}
        classes={{ paper: classes.menuWrapper, list: classes.list }}
        keepMounted={open}
        onClose={onClose}
        style={{ display: parentMenuShow ? 'block' : 'none' }}
        {...props}
      >
        {headerMenuItems &&
          headerMenuItems.map(menuItem => (
            <div key={menuItem.id} className="listItem">
              <MenuItem
                className={clsx(classes.menuItem, {
                  [classes.divider]: menuItem?.isDivider,
                })}
                onClick={e => {
                  menuItem.onMenuItemClick && menuItem.onMenuItemClick();
                  onClose && onClose(e, 'escapeKeyDown');
                }}
                disabled={menuItem.disabled}
              >
                {menuItem.name}
              </MenuItem>
            </div>
          ))}
        {headerMenuItems && headerMenuItems.length > 0 && (
          <DashedSeparator dashedItemProps={{ width: 7, color: colors.grey80 }} />
        )}
        {bodyMenuItems &&
          bodyMenuItems.map(menuItem =>
            menuItem.items ? (
              <div key={menuItem.id} className="listItem">
                <NestedMenuItem
                  menuItemName={menuItem.name}
                  subMenuItems={menuItem?.items}
                  handleNestedMenuItemClick={handleNestedMenuItemClick}
                  setParentMenuShow={setParentMenuShow}
                />
              </div>
            ) : (
              <div key={menuItem.id} className="listItem">
                <MenuItem
                  className={clsx(classes.menuItem, {
                    [classes.divider]: menuItem?.isDivider,
                    [classes.menuItemIfThereNested]: thereNestedItems,
                  })}
                  onClick={e => {
                    menuItem.onMenuItemClick && menuItem.onMenuItemClick();
                    onClose && onClose(e, 'escapeKeyDown');
                  }}
                  disabled={menuItem.disabled}
                >
                  {menuItem.name}
                </MenuItem>
              </div>
            ),
          )}
      </Menu>
    </Popover>
  );
};
