import MaskedInput, { PipeConfig } from 'react-text-mask';

import { applyPhoneMask } from 'utils';

import { TextMaskProps } from './TextMaskProps.types';
import { useFixCaptureAttr } from './useFixCaptureAttr';

export const PhoneMask = ({ inputRef, capture, ...restProps }: TextMaskProps) => {
  const fixedCapture = useFixCaptureAttr(capture);

  return (
    <MaskedInput
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      {...restProps}
      capture={fixedCapture}
      pipe={(conformedValue: string, config: PipeConfig) => {
        if (
          !config.rawValue.includes('(') &&
          !config.rawValue.includes(')') &&
          !config.rawValue.includes('-') &&
          config.rawValue.length > 10
        ) {
          return applyPhoneMask(config.rawValue);
        }

        return conformedValue;
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};
