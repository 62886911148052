import React, { ChangeEvent, useCallback } from 'react';

import { FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';

import { CheckboxCircleIcon } from '../../assets';
import { CheckboxIcon } from 'assets/icons/CheckboxIcon';

import { CheckboxStyled } from './Checkbox.styles';
import { useStyles } from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ variant = 'standard', label, value, onChange, checked, className, name, ...props }: CheckboxProps, ref) => {
    const styles = useStyles();

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.checked);
        e.target.blur();
      },
      [onChange],
    );

    return (
      <FormControlLabel
        className={clsx(styles.label, props.size === 'small' && styles.labelSmall, className)}
        control={
          <CheckboxStyled
            {...props}
            name={name}
            icon={
              variant === 'outline' ? (
                <CheckboxIcon size={props.size} />
              ) : variant === 'circle' ? (
                <CheckboxCircleIcon size={props.size} />
              ) : undefined
            }
            checkedIcon={
              variant === 'outline' ? (
                <CheckboxIcon size={props.size} checked />
              ) : variant === 'circle' ? (
                <CheckboxCircleIcon size={props.size} checked />
              ) : undefined
            }
            value={value}
            onChange={handleChange}
            checked={checked}
            ref={ref}
          />
        }
        label={label}
      />
    );
  },
);
