import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  divider: {
    margin: '16px 0px',
  },
  contentItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    width: 'fit-content',
    minHeight: 27,
  },
  editingInput: {
    maxWidth: 165,
    width: '100%',
  },
  categorySelectRoot: {
    fontSize: '14px !important',
    font: 'inherit !important',
  },
  noSaleReason: {
    wordBreak: 'break-word',
  },
  alignStart: {
    alignItems: 'start',
  },
}));
