import { FC, memo, useCallback, useState } from 'react';

import { Box, IconButton } from '@material-ui/core';

import { NewDropdownMenu } from '../../dropdownMenu/newDropdownMenu/NewDropdownMenu';
import { GeneralTopbarProps } from '../GeneralTopbar.types';
import { colors, PlusIcon } from 'assets';

import { useStyles } from './ActionMenus.styles';

export const Shortcuts: FC<Pick<GeneralTopbarProps, 'shortcuts' | 'handleShortcutMenuMenuItemClick'>> = memo(
  ({ shortcuts, handleShortcutMenuMenuItemClick }) => {
    const styles = useStyles();
    const [shortcutsDropdownMenuRef, setShortcutsDropdownMenuRef] = useState<HTMLButtonElement | null>(null);

    const handleShortcutsDropdownMenuOpen = useCallback(
      (event: { currentTarget: HTMLButtonElement }) => {
        setShortcutsDropdownMenuRef(event?.currentTarget);
      },
      [setShortcutsDropdownMenuRef],
    );

    const handleShortcutsDropdownMenuClose = useCallback(() => {
      setShortcutsDropdownMenuRef(null);
    }, [setShortcutsDropdownMenuRef]);

    const handleShortcutsMenuItemClick = useCallback(
      (event: React.MouseEvent<Element, MouseEvent>) => {
        handleShortcutMenuMenuItemClick && handleShortcutMenuMenuItemClick(event);
        handleShortcutsDropdownMenuClose();
      },
      [handleShortcutsDropdownMenuClose, handleShortcutMenuMenuItemClick],
    );

    return (
      <Box>
        <IconButton className={styles.iconButtonMediumVertMargin} onClick={handleShortcutsDropdownMenuOpen}>
          <PlusIcon className={styles.toggleIcon} color={colors.primary.main} />
        </IconButton>

        <NewDropdownMenu
          open={!!shortcutsDropdownMenuRef}
          anchorEl={shortcutsDropdownMenuRef}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          menuItems={shortcuts}
          onMenuItemClick={handleShortcutsMenuItemClick}
          onClose={handleShortcutsDropdownMenuClose}
        />
      </Box>
    );
  },
);
