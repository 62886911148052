import { createElement, memo } from 'react';

import { Typography, TypographyVariant } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './StatusBadge.styles';
import { StatusBadgeProps } from './StatusBadge.types';

const sizeParams = {
  large: {
    variant: 'body1',
    py: 4,
    px: 16,
  },
  fatMedium: {
    variant: 'body2',
    py: 3,
    px: 8,
  },
  medium: {
    variant: 'body2',
    py: 0.5,
    px: 8,
  },
  small: {
    variant: 'body2',
    py: 0.25,
    px: 8,
  },
};

export const StatusBadge = memo(
  ({ size = 'medium', type = 'success', text, icon, customColor, classes }: StatusBadgeProps) => {
    const styles = useStyles({ color: customColor });

    return (
      <div
        className={clsx(
          styles.root,
          classes?.root,
          customColor && styles.customColor,
          !customColor && styles[type],
          styles[size],
        )}
        style={{ padding: `${sizeParams[size].py}px ${sizeParams[size].px}px` }}
      >
        <div className={styles.content}>
          {icon && createElement(icon)}

          <Typography variant={sizeParams[size].variant as TypographyVariant} component="span">
            {text}
          </Typography>
        </div>
      </div>
    );
  },
);
